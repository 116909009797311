import { useState, useEffect, useRef } from 'react';
//@ts-ignore
import QRCode from 'qrcode';

import SyncVideoSender from '../components/sync-video-sender';

import { getRootUrl } from '../libs/utils/utils';
import { endpoint } from '../config';

import '../App.css';

export default function Sender() {
  const [ _namespace, setNamespace ] = useState<string>('')
  const [ _xrReceiverUrl, setXrReceiverUrl ] = useState<string>('')
  const [ _receiverUrl, setReceiverUrl ] = useState<string>('')

  const _canvasRef = useRef<HTMLCanvasElement>(null)

  /**
   * namespace が変更されたら receiver の URL を更新する
   */
  useEffect(() => {
    if( _namespace === '' ) return

    setXrReceiverUrl(`${getRootUrl()}/xr-receiver?ns=${_namespace}`)
    setReceiverUrl(`${getRootUrl()}/receiver?ns=${_namespace}`)
  }, [ _namespace])

  useEffect(() => {
    QRCode.toCanvas(_canvasRef.current, _xrReceiverUrl, function (error:Error) {
      if (error) console.error(error)
      console.log('success!');
    })
  }, [ _xrReceiverUrl])


  return (
    <div className="App">
      <main>
        <div className='container'>
          <h2>video stream w/ Sync</h2>
          <div className='vertical-wrapper'>
            <div>
                <p>Namespace : {_namespace}</p>
            </div>
            <div className='component'>
              <SyncVideoSender 
                endpoint={endpoint} 
                namespace={_namespace} setNamespace={setNamespace}
                videoTrackName='video'
                audioTrackName='audio'
              />
            </div>
            <div style={{padding: '1em'}}>
              { _receiverUrl && (
                <div>
                  <a href={_receiverUrl} target='_blank' rel="noreferrer">{_receiverUrl}</a>
                </div>
              )}
              { _xrReceiverUrl && (
                <div>
                  <a href={_xrReceiverUrl} target='_blank' rel="noreferrer">{_xrReceiverUrl}</a>
                </div>
              )}
              <canvas ref={_canvasRef} width={120} height={120}></canvas>
            </div>
            {/*
            _receiverUrl && (
                <div>
                    <hr />
                    <h3>Receiver info.</h3>
                    <p><a href={_receiverUrl} target='_blank' rel="noreferrer">{_receiverUrl}</a></p>
                    <p style={{padding: '1em'}}><QRCode size={128} value={_receiverUrl} /></p>
                </div>
            )
            */}
          </div>
        </div>
      </main>
    </div>
  );
}