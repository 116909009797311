import { MessageData } from "../../types/moqt"
import { DataType } from "../sync-jitter-buffer"

export type ReceiverData = {
    seqId: number,
    clkms: number,
    capturedClkms: number,
    data: ArrayBuffer
}

type Props = {
    //@ts-ignore
    moqt:Moqt,
    type:string,
    kind?:string|undefined,
    onData?:Function|undefined,
    onMetrics?:Function|undefined,
    lossRate?:number // 0.0 ~ 1.0 . データロスエミュレーション用
}

export default class MoqtSrc extends ReadableStream<DataType> {
    //@ts-ignore
    moqt:Moqt
    type:string
    kind?:string|undefined
    onData:Function|undefined
    onMetrics:Function|undefined
    buffer:Array<ReceiverData>
    cnt:number
    lastFrameCount: number
    frameCounter: number
    lastSampleCount: number
    sampleCounter: number
    lastByteCount: number
    byteCounter:number

    _lossRate:number

    constructor( props:Props ) {
        super({
            start: (controller) => {
                setTimeout(() => {
                    this.moqt.addListener('data', ( data:MessageData ) => {
                        if( data.type === this.type ) {
                            if( data.metadata?.kind
                                && data.metadata?.seqId
                                && data.metadata?.timestamp
                                && data.payload
                            ) {
                                // issue #11 : NW エミュレーションを入れる
                                if( Math.random() < this._lossRate ) return

                                // data track 用の処理
                                // 現状、Dante src に特化して書かれている
                                if( this.kind && data.metadata.kind === this.kind ) {
                                    this.cnt++
                                    const clkms = Date.now()
                                    controller.enqueue({
                                        metadata: {
                                            clkms,
                                            captureClkms: data.metadata.timestamp,
                                            seqId: data.metadata.seqId
                                        },
                                        payload: data.payload
                                    })

                                    const receiverData = {
                                        seqId: data.metadata.seqId,
                                        clkms,
                                        capturedClkms: data.metadata.timestamp,
                                        data: data.payload.buffer
                                    }

                                    this.sampleCounter++
                                    this.byteCounter+=data.payload.length

                                    // callback のインターバルが短すぎると落ちてしまうため、4回に1回のみコールバックする
                                    if( this.cnt % 4 === 0 ) {
                                        this.buffer.push( receiverData )
                                        if( typeof this.onData === 'function' ) {
                                            this.onData( this.buffer)
                                        }
                                        this.buffer = []
                                    } else {
                                        this.buffer.push( receiverData )
                                    }
                                } 
                            } else if( 
                                !this.kind 
                                && data.type === this.type // temporal condition
                                && data.metadata
                                //@ts-ignore
                                && data.metadata.clkms
                                //@ts-ignore
                                && data.metadata.captureClkms
                                && data.metadata.seqId
                                && data.payload
                                && data.payload.byteLength
                            ) {
                                // issue #11 : NW エミュレーションを入れる
                                if( Math.random() < this._lossRate ) return

                                // media track 用の処理
                                this.frameCounter++
                                this.byteCounter+=data.payload.byteLength

                                controller.enqueue({
                                    metadata: {
                                        //@ts-ignore
                                        metadata: data.metadata.metadata,
                                        //@ts-ignore
                                        clkms: data.metadata.clkms,
                                        //@ts-ignore
                                        captureClkms: data.metadata.captureClkms,
                                        seqId: data.metadata.seqId
                                    },
                                    payload: data.payload
                                })
                            }
                        }
                    })
                    if( this.type === 'videochunk' || this.type === 'audiochunk' ) {
                        setInterval(() => {
                            if( this.onMetrics && typeof this.onMetrics === 'function') {
                                const frameRate = this.frameCounter - this.lastFrameCount
                                const bps = ( this.byteCounter - this.lastByteCount ) * 8
                                this.onMetrics({ frameRate, bps })
                            }
                            this.lastFrameCount = this.frameCounter
                            this.lastByteCount = this.byteCounter
                        }, 1_000 )
                    } else {
                        setInterval(() => {
                            if( this.onMetrics && typeof this.onMetrics === 'function' ) {
                                const sampleRate = this.sampleCounter - this.lastSampleCount
                                const bps = ( this.byteCounter - this.lastByteCount ) * 8
                                this.onMetrics({ sampleRate, bps })
                            }
                            this.lastSampleCount = this.sampleCounter
                            this.lastByteCount = this.byteCounter
                        }, 1_000)
                    }
                }, 1)
            }
        })

        this.cnt = 0
        this.moqt = props.moqt
        this.type = props.type
        this.kind = props.kind
        this.onData = props.onData
        this.onMetrics = props.onMetrics
        this.buffer = []
        this.byteCounter = 0
        this.lastByteCount = 0
        this.frameCounter = 0
        this.lastFrameCount = 0
        this.sampleCounter = 0
        this.lastSampleCount = 0

        // lossRate のセット。0.0 ~ 1.0 の範囲で設定する
        this._lossRate = props.lossRate ? Math.max( 0, Math.min( props.lossRate , 1) ) : 0.0

        console.log( 'MoqtSrc::constructor:%o', props )
    }

    set lossRate( rate:number ) {
        this._lossRate = Math.max( 0, Math.min( rate, 1 ) )
    }
}