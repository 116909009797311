import VDecoder from "../video-decoder"


export default class VideoDecoderElem extends TransformStream {
    decoder:VDecoder
    buffer: Array<any>
    constructor() {
        super( {
            transform: ( chunk, controller ) => {
                this.decoder.decode( chunk )

                while( this.buffer.length > 0 ) {
                    const vFrame = this.buffer.shift()
                    controller.enqueue( vFrame )
                }
            }
        })

        this.decoder = new VDecoder()
        this.buffer = []

        this.decoder.on('vFrame', ({ frameData, metadata }) => {
            this.buffer.push( frameData )
        })

        this.decoder.on('error', (mesg:string) => {
            console.error(`[VDecoder error]:%s`, mesg)
        })
    }
}