import AudioBufferLoader  from './audio-buffer-loader'


export default class Synthesizer {
    _ctx:AudioContext
    _osc:OscillatorNode
    _gain:GainNode
    _timer:number|null

    _cymbal:AudioBufferLoader|null
    _drumroll:AudioBufferLoader|null
    _hat:AudioBufferLoader|null
    _kick:AudioBufferLoader|null
    _snare:AudioBufferLoader|null
    _tom1:AudioBufferLoader|null
    _tom2:AudioBufferLoader|null
    _tom3:AudioBufferLoader|null

    _guitar00:AudioBufferLoader|null
    _guitar07:AudioBufferLoader|null
    _guitar14:AudioBufferLoader|null
    _guitar15:AudioBufferLoader|null

    _bass01:AudioBufferLoader|null
    _bass03:AudioBufferLoader|null
    _bass08:AudioBufferLoader|null
    _bass14:AudioBufferLoader|null

    _piano36:AudioBufferLoader|null
    _piano37:AudioBufferLoader|null
    _piano38:AudioBufferLoader|null
    _piano39:AudioBufferLoader|null
    _piano40:AudioBufferLoader|null
    _piano41:AudioBufferLoader|null
    _piano42:AudioBufferLoader|null
    _piano43:AudioBufferLoader|null
    _piano44:AudioBufferLoader|null
    _piano45:AudioBufferLoader|null
    _piano46:AudioBufferLoader|null
    _piano47:AudioBufferLoader|null

    _piano48:AudioBufferLoader|null
    _piano49:AudioBufferLoader|null
    _piano50:AudioBufferLoader|null
    _piano51:AudioBufferLoader|null
    _piano52:AudioBufferLoader|null
    _piano53:AudioBufferLoader|null
    _piano54:AudioBufferLoader|null
    _piano55:AudioBufferLoader|null
    _piano56:AudioBufferLoader|null
    _piano57:AudioBufferLoader|null
    _piano58:AudioBufferLoader|null
    _piano59:AudioBufferLoader|null

    _piano60:AudioBufferLoader|null

    constructor() {
        this._ctx = new AudioContext()
        this._osc = this._ctx.createOscillator()
        this._gain = this._ctx.createGain()
        this._gain.connect( this._ctx.destination )
        this._osc.connect( this._gain )
        this._timer = null

        this._cymbal = null
        this._drumroll = null
        this._hat = null
        this._kick = null
        this._snare = null
        this._tom1 = null
        this._tom2 = null
        this._tom3 = null

        this._guitar00 = null
        this._guitar07 = null
        this._guitar14 = null
        this._guitar15 = null

        this._bass01 = null
        this._bass03 = null
        this._bass08 = null
        this._bass14 = null

        this._piano36 = null
        this._piano37 = null
        this._piano38 = null
        this._piano39 = null
        this._piano40 = null
        this._piano41 = null
        this._piano42 = null
        this._piano43 = null
        this._piano44 = null
        this._piano45 = null
        this._piano46 = null
        this._piano47 = null

        this._piano48 = null
        this._piano49 = null
        this._piano50 = null
        this._piano51 = null
        this._piano52 = null
        this._piano53 = null
        this._piano54 = null
        this._piano55 = null
        this._piano56 = null
        this._piano57 = null
        this._piano58 = null
        this._piano59 = null

        this._piano60 = null
    }

    start() {
        this._loadInstruments()
        this._osc.start()
        this._gain.gain.setValueAtTime(0, this._ctx.currentTime )
    }

    _loadInstruments() {
        // source - https://maou.audio/category/se/se-inst/page/5/
        this._cymbal = new AudioBufferLoader('cymbal', '/assets/drum2/cymbal.m4a');
        this._cymbal.load()
        this._kick = new AudioBufferLoader('cymbal', '/assets/drum2/kick.m4a');
        this._kick.load()
        this._hat = new AudioBufferLoader('cymbal', '/assets/drum2/hat.m4a');
        this._hat.load()
        this._snare = new AudioBufferLoader('cymbal', '/assets/drum2/snare.m4a');
        this._snare.load()
        this._tom1 = new AudioBufferLoader('cymbal', '/assets/drum2/tom1.m4a');
        this._tom1.load()
        this._tom2 = new AudioBufferLoader('cymbal', '/assets/drum2/tom2.m4a');
        this._tom2.load()
        this._tom3 = new AudioBufferLoader('cymbal', '/assets/drum2/tom3.m4a');
        this._tom3.load()
        this._drumroll = new AudioBufferLoader('cymbal', '/assets/drum2/drumroll.m4a');
        this._drumroll.load()

        this._guitar00 = new AudioBufferLoader('cymbal', '/assets/guitar/guitar01.m4a');
        this._guitar00.load()
        this._guitar07 = new AudioBufferLoader('cymbal', '/assets/guitar/guitar07.m4a');
        this._guitar07.load()
        this._guitar14 = new AudioBufferLoader('cymbal', '/assets/guitar/guitar14.m4a');
        this._guitar14.load()
        this._guitar15 = new AudioBufferLoader('cymbal', '/assets/guitar/guitar15.m4a');
        this._guitar15.load()

        this._bass01 = new AudioBufferLoader('cymbal', '/assets/bass/bass01.m4a');
        this._bass01.load()
        this._bass03 = new AudioBufferLoader('cymbal', '/assets/bass/bass03.m4a');
        this._bass03.load()
        this._bass08 = new AudioBufferLoader('cymbal', '/assets/bass/bass08.m4a');
        this._bass08.load()
        this._bass14 = new AudioBufferLoader('cymbal', '/assets/bass/bass14.m4a');
        this._bass14.load()

        this._piano36 = new AudioBufferLoader('cymbal', '/assets/piano/36.mp3');
        this._piano36.load()
        this._piano37 = new AudioBufferLoader('cymbal', '/assets/piano/37.mp3');
        this._piano37.load()
        this._piano38 = new AudioBufferLoader('cymbal', '/assets/piano/38.mp3');
        this._piano38.load()
        this._piano39 = new AudioBufferLoader('cymbal', '/assets/piano/39.mp3');
        this._piano39.load()
        this._piano40 = new AudioBufferLoader('cymbal', '/assets/piano/40.mp3');
        this._piano40.load()
        this._piano41 = new AudioBufferLoader('cymbal', '/assets/piano/41.mp3');
        this._piano41.load()
        this._piano42 = new AudioBufferLoader('cymbal', '/assets/piano/42.mp3');
        this._piano42.load()
        this._piano43 = new AudioBufferLoader('cymbal', '/assets/piano/43.mp3');
        this._piano43.load()
        this._piano44 = new AudioBufferLoader('cymbal', '/assets/piano/44.mp3');
        this._piano44.load()
        this._piano45 = new AudioBufferLoader('cymbal', '/assets/piano/45.mp3');
        this._piano45.load()
        this._piano46 = new AudioBufferLoader('cymbal', '/assets/piano/46.mp3');
        this._piano46.load()
        this._piano47 = new AudioBufferLoader('cymbal', '/assets/piano/47.mp3');
        this._piano47.load()

        this._piano48 = new AudioBufferLoader('cymbal', '/assets/piano/48.mp3');
        this._piano48.load()
        this._piano49 = new AudioBufferLoader('cymbal', '/assets/piano/49.mp3');
        this._piano49.load()
        this._piano50 = new AudioBufferLoader('cymbal', '/assets/piano/50.mp3');
        this._piano50.load()
        this._piano51 = new AudioBufferLoader('cymbal', '/assets/piano/51.mp3');
        this._piano51.load()
        this._piano52 = new AudioBufferLoader('cymbal', '/assets/piano/52.mp3');
        this._piano52.load()
        this._piano53 = new AudioBufferLoader('cymbal', '/assets/piano/53.mp3');
        this._piano53.load()
        this._piano54 = new AudioBufferLoader('cymbal', '/assets/piano/54.mp3');
        this._piano54.load()
        this._piano55 = new AudioBufferLoader('cymbal', '/assets/piano/55.mp3');
        this._piano55.load()
        this._piano56 = new AudioBufferLoader('cymbal', '/assets/piano/56.mp3');
        this._piano56.load()
        this._piano57 = new AudioBufferLoader('cymbal', '/assets/piano/57.mp3');
        this._piano57.load()
        this._piano58 = new AudioBufferLoader('cymbal', '/assets/piano/58.mp3');
        this._piano58.load()
        this._piano59 = new AudioBufferLoader('cymbal', '/assets/piano/59.mp3');
        this._piano59.load()

        this._piano60 = new AudioBufferLoader('cymbal', '/assets/piano/60.mp3');
        this._piano60.load()
    }

    stop() {
        this._osc.stop()
    }

    setFrequency( freq:number) {
        this._osc.frequency.setValueAtTime( freq, this._ctx.currentTime )
        this._gain.gain.setValueAtTime(1, this._ctx.currentTime )

        if( this._timer ) {
            clearTimeout( this._timer )
            this._timer = null
        }

        // @ts-ignore
        this._timer = setTimeout(() => {
            this._gain.gain.setValueAtTime(0, this._ctx.currentTime )
            this._timer = null
        }, 500)
    }

    setFrequenciesByNote( notes:Array<{note: number, delay: number}>) {
        for( const item of notes ) {
            const note = item.note
            const delay = item.delay
            if( note === 36 && this._kick && this._kick.loaded ) {
                this._kick.play( delay )
            } else if( note === 37 && this._hat && this._hat.loaded ) {
                this._hat.play( delay )
            } else if( note === 38 && this._snare && this._snare.loaded ) {
                this._snare.play( delay )
            } else if( note === 39 && this._tom1 && this._tom1.loaded ) {
                this._tom1.play( delay )
            } else if( note === 40 && this._tom2 && this._tom2.loaded ) {
                this._tom2.play( delay )
            } else if( note === 41 && this._tom3 && this._tom3.loaded ) {
                this._tom3.play( delay )
            } else if( note === 42 && this._cymbal && this._cymbal.loaded ) {
                this._cymbal.play( delay )
            } else if( note === 43 && this._drumroll && this._drumroll.loaded ) {
                this._drumroll.play( delay )
            } else if( note === 44 && this._bass01 && this._bass01.loaded ) {
                this._bass01.play( delay )
            } else if( note === 45 && this._bass03 && this._bass03.loaded ) {
                this._bass03.play( delay )
            } else if( note === 46 && this._bass08 && this._bass08.loaded ) {
                this._bass08.play( delay )
            } else if( note === 47 && this._bass14 && this._bass14.loaded ) {
                this._bass14.play( delay )
            } else if( note === 48 && this._piano36 && this._piano36.loaded ) {
                // C3
                this._piano36.play( delay )
            } else if( note === 49 && this._piano37 && this._piano37.loaded ) {
                this._piano37.play( delay )
            } else if( note === 50 && this._piano38 && this._piano38.loaded ) {
                this._piano38.play( delay )
            } else if( note === 51 && this._piano39 && this._piano39.loaded ) {
                this._piano39.play( delay )
            } else if( note === 52 && this._piano40 && this._piano40.loaded ) {
                this._piano40.play( delay )
            } else if( note === 53 && this._piano41 && this._piano41.loaded ) {
                this._piano41.play( delay )
            } else if( note === 54 && this._piano42 && this._piano42.loaded ) {
                this._piano42.play( delay )
            } else if( note === 55 && this._piano43 && this._piano43.loaded ) {
                this._piano43.play( delay )
            } else if( note === 56 && this._piano44 && this._piano44.loaded ) {
                this._piano44.play( delay )
            } else if( note === 57 && this._piano45 && this._piano45.loaded ) {
                this._piano45.play( delay )
            } else if( note === 58 && this._piano46 && this._piano46.loaded ) {
                this._piano46.play( delay )
            } else if( note === 59 && this._piano47 && this._piano47.loaded ) {
                this._piano47.play( delay )
            } else if( note === 60 && this._piano48 && this._piano48.loaded ) {
                // C4
                this._piano48.play( delay )
            } else if( note === 61 && this._piano49 && this._piano49.loaded ) {
                this._piano49.play( delay )
            } else if( note === 62 && this._piano50 && this._piano50.loaded ) {
                this._piano50.play( delay )
            } else if( note === 63 && this._piano51 && this._piano51.loaded ) {
                this._piano51.play( delay )
            } else if( note === 64 && this._piano52 && this._piano52.loaded ) {
                this._piano52.play( delay )
            } else if( note === 65 && this._piano53 && this._piano53.loaded ) {
                this._piano53.play( delay )
            } else if( note === 66 && this._piano54 && this._piano54.loaded ) {
                this._piano54.play( delay )
            } else if( note === 67 && this._piano55 && this._piano55.loaded ) {
                this._piano55.play( delay )
            } else if( note === 68 && this._piano56 && this._piano56.loaded ) {
                this._piano56.play( delay )
            } else if( note === 69 && this._piano57 && this._piano57.loaded ) {
                this._piano57.play( delay )
            } else if( note === 70 && this._piano58 && this._piano58.loaded ) {
                this._piano58.play( delay )
            } else if( note === 71 && this._piano59 && this._piano59.loaded ) {
                this._piano59.play( delay )
            } else if( note === 72 && this._piano60 && this._piano60.loaded ) {
                // C5
                this._piano60.play( delay )
            } else {
                console.log( "note not found", note )
                //if( typeof note === 'string') {
                //    const freq:number = getFreqByNote( note )
                //    this.setFrequency( freq )
                //}
            }
        }
    }
}
