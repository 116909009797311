type Props = {
    canvas: HTMLCanvasElement,
    onResolutionChanged?:Function
}

export default class VideoRendererSink extends WritableStream {
    canvas: HTMLCanvasElement
    ctx: CanvasRenderingContext2D|null
    onResolutionChanged: Function|undefined
    constructor( props:Props ) {
        super({
            //@ts-ignore
            write: ( frameData:VideoFrame ) => {
                if( this.canvas && this.ctx && frameData ) {
                    const width = frameData.codedWidth, height = frameData.codedHeight

                    if( this.canvas.width !== width || this.canvas.height !== height ) {
                        this.canvas.width = width
                        this.canvas.height = height

                        if( this.onResolutionChanged && typeof this.onResolutionChanged === 'function' ) {
                            this.onResolutionChanged({ width, height })
                        }
                    }

                    this.ctx.drawImage( frameData, 0, 0, width, height )
                    frameData.close()
                }
            }
        })

        this.canvas = props.canvas
        this.ctx = this.canvas.getContext('2d')
        this.onResolutionChanged = props.onResolutionChanged
    }
}