import { useState, useEffect } from 'react';

import { Divider, Input } from 'antd';

import DanteSender from './components/dante-sender';
import DanteReceiver from './components/dante-receiver';

import PcmMonitor from './components/pcm-monitor';

import Randomstring from 'randomstring';

import { endpoint, wsSender, wsReceiver } from './config';

import './App.css';

import { SenderData } from './libs/stream-apis/moqt-data-sink';
import { ReceiverData } from './libs/stream-apis/moqt-src';

const key = 'dante_num_channels'

const isDevelopment = process.env.NODE_ENV === 'development'


function Dante() {
  const [ _namespace, setNamespace ] = useState<string>('null')
  const [ _senderDatas, setSenderDatas ] = useState<Array<SenderData>>()
  const [ _receiverDatas, setReceiverDatas ] = useState<Array<ReceiverData>>()
  const [ _numChannels, setNumChannels ] = useState<number>(2)

  const moqtEp = endpoint
  const apiServer = isDevelopment ? 'http://localhost:3010' : ''

  // namespace 変更の useEffect
  useEffect(() => {
    const num = localStorage.getItem( key )
    if( num ) setNumChannels( Number(num) )

    const namespace = Randomstring.generate(10)
    fetch(`${apiServer}/api/v1/namespace/${namespace}`, {
      method: 'POST'
    }).then( async res => {
      if( res.ok ) {
        console.log('namespace update request succeeded')
      } else {
        console.error('namespace update request failed:s', await res.text() )
      }
    }).catch( err => {
      console.error('namespace update request failed:%o', err)
    })
  }, [ apiServer ])

  // namespace 取得の useEffect( long polling )
  useEffect(() => {
    const request = () => {
      fetch(`${apiServer}/api/v1/namespace/${_namespace}`)
        .then( async res => {
          if( !res.ok ) {
            // エラーが発生した場合は、10秒後に再度リクエストを送る
            console.error('namespace request failed:%s', await res.text() )
            setTimeout( request, 10_000)
            return
          }
          const json = await res.json()

          if( json.status === 'not_changed') {
            // 変更なしの場合は、1秒後に再度リクエストを送る
            setTimeout( request, 1_000 )
          } else if( json.status === 'changed' ) {
            // namespace が変更された場合は、 _namespace を更新する
            // これにより useEffect が再度呼ばれるため、新しい namespace での
            // long polling request が始まる
            console.log('namespace changed:%s', json.namespace)
            setNamespace( json.namespace )
          } else {
            // その他のステータスの場合は、5秒後に再度リクエストを送る
            console.warn('unknown status:%s', json?.status)
            setTimeout( request, 5_000)
          }
        })
        .catch( err => {
          // エラーが発生した場合は、10秒後に再度リクエストを送る
          console.error('namespace request failed:%o', err)
          setTimeout( request, 10_000)
        })
    }
    setTimeout( request, 1_000 )
  }, [ apiServer, _namespace ])


  return (
    <div className="App">
      <main>
        <h1>Dante over MoQ test</h1>

        <div className='container'>
          <div>
            namespace: {_namespace}
          </div>
          <DanteSender 
            endpoint={moqtEp}
            namespace={_namespace}
            bitrate={2_000_000}
            wsUrl={wsSender}
            onData={setSenderDatas}
          />
          <DanteReceiver
            endpoint={moqtEp}
            namespace={_namespace}
            minJitterBufferMs={50}
            wsUrl={wsReceiver}
            numChannels={_numChannels}
            onData={setReceiverDatas}
          />
        </div>

        <Divider />
        { true && (
        <div>
          <h3>Dante monitor</h3>
          <div>
            number of channels : 
            <Input 
              style={{width: '100px'}}
              type="number" 
              value={_numChannels} 
              onChange={(e:object) => {
                //@ts-ignore
                const num = Number(e.target.value)
                localStorage.setItem(key, num.toString())
                setNumChannels( num )
              }} />
          </div>
          <PcmMonitor
            numChannels={_numChannels}
            senderDatas={_senderDatas}
            receiverDatas={_receiverDatas}
          />
        </div>
        )}
      </main>
    </div>
  );
}

export default Dante;
