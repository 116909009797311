/**
 * ref)
 * `PCCSトーン(24色)のカラーコードとRGB値の一覧`
 * https://qiita.com/lookman/items/efae7683703b62ebfe9d
 */

export const paletones = [
    "rgb(238, 175, 206)",
    "rgb(251, 180, 196)",
    "rgb(250, 182, 181)",
    "rgb(253, 205, 183)",
    "rgb(251, 216, 176)",
    "rgb(254, 230, 170)",
    "rgb(252, 241, 175)",
    "rgb(254, 255, 179)",
    "rgb(238, 250, 178)",
    "rgb(230, 245, 176)",
    "rgb(217, 246, 192)",
    "rgb(204, 234, 196)",
    "rgb(192, 235, 205)",
    "rgb(179, 226, 216)",
    "rgb(180, 221, 223)",
    "rgb(180, 215, 221)",
    "rgb(181, 210, 224)",
    "rgb(179, 206, 227)",
    "rgb(180, 194, 221)",
    "rgb(178, 182, 217)",
    "rgb(188, 178, 213)",
    "rgb(202, 178, 214)",
    "rgb(218, 175, 220)",
    "rgb(228, 173, 213)",
]