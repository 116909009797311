type MidiSrcReturnType = {
    timestamp: number,
    pTimestamp: number,
    capturedTimestamp: number,
    data: Uint8Array
}

export default class MidiSrc extends ReadableStream<MidiSrcReturnType> {
    //@ts-ignore
    _midi: MIDIAccess | undefined
    constructor() {
        super({
            start: (controller) => {
                //@ts-ignore
                navigator.requestMIDIAccess().then((midi: MIDIAccess) => {
                    this._midi = midi
                    //@ts-ignore
                    this._midi.inputs.forEach((input:MIDIInput) => {
                        //@ts-ignore
                        input.onmidimessage = (event:MIDIMessageEvent) => {
                            console.log( 'Event:%o', event )
                            const capturedTimestamp = event.timeStamp
                            const timestamp = Date.now()
                            const pTimestamp = performance.now()

                            if( event.data ) {
                                const data = new Uint8Array(event.data)
                                controller.enqueue({ timestamp, pTimestamp, capturedTimestamp, data })
                            }
                        }
                    })
                })
            }
        })
    }
}