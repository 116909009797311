import { forwardRef, useCallback, useImperativeHandle, useEffect, useRef } from 'react';
import { FilesetResolver, FaceLandmarker, PoseLandmarker } from "@mediapipe/tasks-vision";
//@ts-ignore
//import { FilesetResolver, FaceLandmarker, PoseLandmarker } from "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3";

import './face-landmarks-detector.css'

type Props = {
  sendFaceLandmarksResult?: Function
  sendPoseLandmarksResult?: Function
  hidden?: boolean
  disabled?: boolean
}

// 表情検出のパターン定義
export const patterns = [
  { kind: FaceLandmarker.FACE_LANDMARKS_TESSELATION, strokeStyle: '#888', lineWidth: 1 },
  { kind: FaceLandmarker.FACE_LANDMARKS_CONTOURS, strokeStyle: '#000', lineWidth: 1 },
  { kind: FaceLandmarker.FACE_LANDMARKS_FACE_OVAL, strokeStyle: '#000', lineWidth: 3 },
  { kind: FaceLandmarker.FACE_LANDMARKS_LEFT_EYE, strokeStyle: 'blue', lineWidth: 2 },
  { kind: FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW, strokeStyle: 'blue', lineWidth: 2 },
  { kind: FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS, strokeStyle: '#000', lineWidth: 1 },
  { kind: FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE, strokeStyle: 'blue', lineWidth: 2 },
  { kind: FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW, strokeStyle: 'blue', lineWidth: 2 },
  { kind: FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS, strokeStyle: '#000', lineWidth: 1 },
  { kind: FaceLandmarker.FACE_LANDMARKS_LIPS, strokeStyle: '#f00', lineWidth: 2 },
]

// 姿勢推定のパターン定義
export const posePatterns = [
  { kind: PoseLandmarker.POSE_CONNECTIONS, strokeStyle: '#aaa', lineWidth: 4, showIdx: false }
]

const FaceLandmarksDetector = forwardRef( function FaceLandmarksDetector( props:Props, ref ) {
  const _videoEl = useRef<HTMLVideoElement>();
  const _canvasElem = useRef<HTMLCanvasElement>(null);
  const _canvasElem4Pose = useRef<HTMLCanvasElement>(null);
  const _faceLandmarker = useRef<FaceLandmarker>();
  const _poseLandmarker = useRef<PoseLandmarker>();
  const _initialized = useRef<Boolean>(false);
  const _reqId4Face = useRef<number>(-1);
  const _reqId4Pose = useRef<number>(-1);

  const { sendFaceLandmarksResult, sendPoseLandmarksResult, hidden, disabled } = props

  // 検出結果の描画を行う
  const _drawFace = useCallback(( 
    landmarkers:Array<Array<{x:number, y:number}>>, 
    patterns: Array<{start:number, end:number}>, 
    strokeStyle: string,
    lineWidth: number,
    clear:boolean,
    showIdx:boolean=false 
  ) => {
    // hidden が true の場合は描画を行わない
    if( hidden ) return;

    const canvas = _canvasElem.current

    if( canvas ) {
      const ctx = canvas.getContext('2d')
      const w = canvas.width, h = canvas.height

      if( ctx ) {
        if( clear ) { ctx.clearRect( 0, 0, w, h ) }
        ctx.beginPath()
        ctx.strokeStyle = strokeStyle 
        ctx.lineWidth = lineWidth
        const arr = landmarkers[0]

        if( arr instanceof Array ) {
          for( let i = 0; i < patterns.length; i++ ) {
            const { start, end } = patterns[i]
            const { x:x0, y:y0 } = arr[start], {x:x1, y:y1 } = arr[end]
            const _x0 = Math.floor( w * x0 ), _y0 = Math.floor( h * y0 )
            const _x1 = Math.floor( w * x1 ), _y1 = Math.floor( h * y1 )
            ctx.moveTo( _x0, _y0 )
            ctx.lineTo( _x1, _y1 )
            if( showIdx ) {
              ctx.fillStyle = '#f00'
              ctx.fillText(`${start}`, _x0, _y0 )
              ctx.fillText(`${end}`, _x1, _y1 )
            }
          }
        }
        ctx.stroke()
      }
    }
  }, [ hidden ])

    /**
   * pose: 検出結果の描画を行う
   */
    const _drawPose = useCallback((
      // landmarkers:Array<Array<{x:number, y:number, z:number, visibility:number}>>, 
      landmarkers:Array<Array<{x:number, y:number}>>, 
      patterns: Array<{start:number, end:number}>, 
      strokeStyle: string,
      lineWidth: number,
      clear: boolean,
      showIdx: boolean=false
    ) => {
      // hidden が true の場合は描画を行わない
      if( hidden ) return;
  
      const canvas = _canvasElem4Pose.current  //描画API
  
      if( canvas ){
        const ctx = canvas.getContext('2d')
        const w = canvas.width, h = canvas.height
  
        if( ctx ){
          if( clear ) { ctx.clearRect( 0, 0, w, h ) }
  
          //const drawingUtils = new DrawingUtils(ctx);
  
          // for (const landmark of landmarkers) {
          //   drawingUtils.drawLandmarks(landmark, {
          //     radius: (data) => DrawingUtils.lerp(data.from!.z, -0.15, 0.1, 5, 1)
          //   });
          //   drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS);
          // }
  
          ctx.strokeStyle = strokeStyle 
          ctx.lineWidth = lineWidth
          const arr = landmarkers[0]
  
          if( arr instanceof Array ) {
            for( let i = 0; i < patterns.length; i++ ) {
              const { start, end } = patterns[i]
              const { x:x0, y:y0 } = arr[start], {x:x1, y:y1 } = arr[end]
              const _x0 = Math.floor( w * x0 ), _y0 = Math.floor( h * y0 )
              const _x1 = Math.floor( w * x1 ), _y1 = Math.floor( h * y1 )
              ctx.beginPath()
              ctx.moveTo( _x0, _y0 )
              ctx.lineTo( _x1, _y1 )
              ctx.stroke()
              if( showIdx ) {
                ctx.fillStyle = '#f00'
                ctx.font = '0.3px'
                ctx.fillRect(_x0, _y0, 3, 3)
                ctx.fillText(`${start}`, _x0, _y0 )
                //ctx.fillText(`${end}`, _x1, _y1 )
              }
            }
          }
  
        }
      }
  
    }, [ hidden ])


  const _startDetection = useCallback(( videoEl:HTMLVideoElement ) => {
    _videoEl.current = videoEl;

    if( !_canvasElem.current ) return;

    _canvasElem.current.width = videoEl.videoWidth
    _canvasElem.current.height = videoEl.videoHeight


    // let results:{ faceLandmarks:Array<Array<{x:number, y:number}>>, faceBlendshapes:Array<object> }

    const callbackFaceLandmarkDetection = async () => {
      let startTimeMs, results

      // 表情検出処理
      if( _faceLandmarker.current && _faceLandmarker.current instanceof FaceLandmarker && _videoEl.current ) {
        startTimeMs = performance.now()
        results = _faceLandmarker.current.detectForVideo( _videoEl.current, startTimeMs )

        // MoQ で、表情ランドマーク検出結果を送信する
        if( sendFaceLandmarksResult ) {
          sendFaceLandmarksResult( results.faceLandmarks )
        }

        // 表情ランドマーク検出結果を描画する
        for( let i = 0; i < patterns.length; i++ ) {
          const { kind, strokeStyle, lineWidth } = patterns[i]
          // 初回描画時のみ canvas をクリアするため、第5パラメータとして `!i` ( clear = true ) を指定している
          _drawFace( results.faceLandmarks, kind, strokeStyle, lineWidth , !i )
        }
      }
      _reqId4Face.current = requestAnimationFrame( callbackFaceLandmarkDetection )
    }

    const callbackPoseLandmarkDetection = async () => {
      let startTimeMs, results

      if( _poseLandmarker.current && _poseLandmarker.current instanceof PoseLandmarker && _videoEl.current ){
        startTimeMs = performance.now()
        results = _poseLandmarker.current.detectForVideo( _videoEl.current, startTimeMs)

        if( sendPoseLandmarksResult ){
          sendPoseLandmarksResult( results.landmarks )
          // console.log( results.landmarks )  // pose-detection OK
        }

        // Poseランドマーク検出結果を描画する(未完)
        for( let i = 0; i < posePatterns.length; i++){
          const { kind, strokeStyle, lineWidth, showIdx} = posePatterns[i]
          _drawPose( results.landmarks, kind, strokeStyle, lineWidth, !i, showIdx )
        }
      }
      _reqId4Pose.current = requestAnimationFrame( callbackPoseLandmarkDetection )
    }

    callbackFaceLandmarkDetection()
    callbackPoseLandmarkDetection()


  }, [ _drawFace, _drawPose, sendFaceLandmarksResult, sendPoseLandmarksResult ])

  // initialize faceLandmarker 
  useEffect(() => {
    ( async () => {
      if( disabled || _initialized.current ) return;
      _initialized.current = true

      // const filesetResolver = await FilesetResolver.forVisionTasks(
      //     "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm"
      // );

      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );

      //_faceLandmarker.current = await FaceLandmarker.createFromOptions(filesetResolver, {
      //  baseOptions: {
      //    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
      //    delegate: "GPU"
      //  },
      //  outputFaceBlendshapes: true,
      //  runningMode: "VIDEO",
      //  numFaces: 1
      //});
      // 顔検出用データの取得
      const faceTaskPath = process.env.NODE_ENV === 'development' ? 
        '/tasks/face_landmarker.task' : 
        `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`
      _faceLandmarker.current = await FaceLandmarker.createFromOptions(filesetResolver, {
        baseOptions: {
          modelAssetPath: faceTaskPath,
          delegate: "GPU"
        },
        outputFaceBlendshapes: true,
        runningMode: "VIDEO",
        numFaces: 1
      });

       // 姿勢検出用データの取得
      const poseTaskPath = process.env.NODE_ENV === 'development' ? 
        '/tasks/pose_landmarker_lite.task' : 
        `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`
      _poseLandmarker.current = await PoseLandmarker.createFromOptions(filesetResolver, {
        baseOptions: {
            modelAssetPath: poseTaskPath,
            delegate: "GPU"
        },
        runningMode: "VIDEO",
        numPoses: 1
      });
      // FINISH::FaceLandmarkerの初期化

      // BEGIN::pose検出の初期化
      //_poseDetector.current = await PoseLandmarker.createFromOptions(filesetResolver, {
      //  baseOptions: {
      //      modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_heavy/float32/1/pose_landmarker_heavy.task`,
      //      delegate: "GPU"
      //  },
      //  runningMode: "VIDEO",
      //  smoothLandmarks: true
      //});
      // FINISH::pose検出の初期化

      console.log('FaceLandmarker initialized', _faceLandmarker.current)
    })();
  }, [ disabled])


  useImperativeHandle(ref, () => {
    return {
        startDetection: (videoEl:HTMLVideoElement) => {
          if( disabled ) return;

          _startDetection(videoEl)
        },
        pauseDetection: () => {
          if( disabled ) return;

          if( _reqId4Face.current !== -1 ) {
            cancelAnimationFrame( _reqId4Face.current )
            _reqId4Face.current = -1
          }

          if( _reqId4Pose.current !== -1 ) {
            cancelAnimationFrame( _reqId4Pose.current )
            _reqId4Pose.current = -1
          }
        },
        drawFace: ( faceLandmarks:Array<Array<{x:number, y:number, z:number}>> ) => {
          if( disabled ) return;

          // 表情ランドマーク検出結果を描画する
          for( let i = 0; i < patterns.length; i++ ) {
            const { kind, strokeStyle, lineWidth } = patterns[i]
            // 初回描画時のみ canvas をクリアするため、第5パラメータとして `!i` ( clear = true ) を指定している
            _drawFace( faceLandmarks, kind, strokeStyle, lineWidth , !i )
          }
        },
        drawPose: ( poseLandmarks:Array<Array<{x:number, y:number, z:number}>> ) => {
          if( disabled ) return;

          // Poseランドマーク検出結果を描画する
          for( let i = 0; i < posePatterns.length; i++){
            const { kind, strokeStyle, lineWidth, showIdx} = posePatterns[i]
            _drawPose( poseLandmarks, kind, strokeStyle, lineWidth, !i, showIdx )
          }
        }
    }
  }, [ _startDetection, _drawFace, _drawPose, disabled ])

  return (
    <div className="FaceLandmarksDetector">
      <canvas ref={_canvasElem} style={{ width: "100%", aspectRatio: "calc( 16 / 9 )", display: hidden ? 'none' : 'block', position: 'absolute' }} width={640} height={480}></canvas>
      <canvas ref={_canvasElem4Pose} style={{ width: "100%", aspectRatio: "calc( 16 / 9 )", display: hidden ? 'none' : 'block', position: 'absolute' }} width={640} height={480}></canvas>
    </div>
  )
})

export default FaceLandmarksDetector