type Props = {
    wsUrl:string  // URL of WebSocket URL which will push dante payload data 
}

export default class DanteStreamSrc extends ReadableStream<ArrayBuffer> {
    ws:WebSocket|null
    counter:number

    constructor( props:Props ) {
        super({
            start: ( controller ) => {
                setTimeout(() => {
                    this.ws = new WebSocket( props.wsUrl )
                    this.ws.binaryType = 'arraybuffer'

                    this.ws.onopen = () => {
                        console.log('[dante-stream-receiver] WS connection opened.')
                    }

                    this.ws.onmessage = ( ev:MessageEvent ) => {
                        if( false && this.counter++ < 2 ) {
                            console.log( '[dante-src] %o', new Uint8Array( ev.data ) )
                        }
                        controller.enqueue( ev.data )
                    }

                    this.ws.onerror = ( err:Event ) => {
                        console.warn('[dante-stream-receiver] WS error:%o', err )
                        controller.close()
                    }
                }, 1)
            }
        })
        this.ws = null
        this.counter = 0
    }
}