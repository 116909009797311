import VideoCapture from "../video-capture"

type Props = {
    video:HTMLVideoElement,
}

export default class VideoCaptureSrc extends ReadableStream<object> {
    _videoCapture: VideoCapture|undefined
    _debug: boolean
    lastNumCapturedFrames: number


    constructor( props:Props ) {
        super({
            start: (controller) => {
                setTimeout(() => {
                    if( props.video instanceof HTMLVideoElement ) {
                        const videoCapture = new VideoCapture()
                        videoCapture.start( props.video )
                        //@ts-ignore
                        videoCapture.on( 'vFrame', ( vFrame:typeof VideoFrame) => {
                            controller.enqueue( vFrame )
                        })

                        this._videoCapture = videoCapture
                    }
                }, 1)
            }
        })
        this.lastNumCapturedFrames = 0
        this._debug = false

        setInterval(() => {
            if( this._videoCapture ) {
                const numCapturedFrames = this._videoCapture.numCapturedFrames
                const frameCount = numCapturedFrames - this.lastNumCapturedFrames

                if( this._debug ) {
                    console.log( 'VideoCapture: frameCount %d', frameCount )
                }
                this.lastNumCapturedFrames = numCapturedFrames
            }
        }, 1_000)
    }
}