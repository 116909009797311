type Props = {
    wsUrl: string
}

export default class DanteSink extends WritableStream {
    wsUrl:string
    ws:WebSocket|undefined

    constructor( props:Props ) {
        super({
            write: (chunk) => {
                if( this.ws && this.ws.readyState === WebSocket.OPEN && !!chunk ) {
                    if( Array.isArray( chunk ) ) {
                        for( const item of chunk ) {
                            this.ws.send( item.payload.buffer )
                        }
                    } else {
                        this.ws.send( chunk.payload.buffer )
                    }
                }
            }
        })

        console.log( 'dante sink:%s', props.wsUrl)
        this.wsUrl = props.wsUrl

        this.openWs()
    }

    openWs() {
        if( this.ws && this.ws.readyState === WebSocket.OPEN ) {
            return
        }
        this.ws = new WebSocket( this.wsUrl )

        this.ws.onopen = () => {
            console.log('[DanteSink] WebSocket connection is opened')
        }

        this.ws.onerror = ( err:Event ) => {
            console.warn('[DanteSink] Error fired:%o', err )
        }

        this.ws.onclose = () => {
            console.log('[DanteSink] WebSocket connection is closed')
        }
    }

    closeWs() {
        if( this.ws && this.ws.readyState === WebSocket.OPEN ) {
            this.ws.close()
            this.ws = undefined
        }
    }

    reconnectWs() {
        if( this.ws && this.ws.readyState === WebSocket.OPEN ) {
            this.closeWs()

            setTimeout(() => {
                this.openWs()
            }, 500 )
        }
    }
}