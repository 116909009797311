import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Dante from './Dante';
import Sender from './router/sender';
import Receiver from './router/receiver';
import XrReceiver from './router/xr-receiver';
import MainVenue from './router/main-venue';
import SatelliteVenue from './router/satellite-venue';
import VenueTest from './router/venue-test';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/dante",
    element: <Dante />
  },
  {
    path: "/sender",
    element: <Sender />
  },
  {
    path: "/receiver",
    element: <Receiver />
  },
  {
    path: "/xr-receiver",
    element: <XrReceiver />
  },
  {
    path: "/dante/main-venue/:eventId",
    element: <MainVenue />
  },
  {
    path: "/dante/satellite-venue/:eventId",
    element: <SatelliteVenue />
  },
  {
    path: "/dante/venue-test/:eventId",
    element: <VenueTest />
  }
])
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
