import VEncoder from "../video-encoder";
import { TimeBufferChecker } from "../utils/time_buffer_checker";

type Props = {
    config: object,
    timeBufferChecker: TimeBufferChecker
}

export default class VideoEncoderElem extends TransformStream {
    encoder:VEncoder
    timeBufferChecker:TimeBufferChecker
    buffer:Array<any>
    currentVideoTs:number|undefined
    videoOffsetTs:number|undefined
    metadata:ArrayBuffer|undefined

    constructor( props:Props ) {
        super({
            transform: ( vFrame, controller ) => {
                let estimatedDuration = -1

                if( this.currentVideoTs === undefined ) {
                    this.videoOffsetTs = -vFrame.timestamp
                } else {
                    estimatedDuration = vFrame.timestamp - this.currentVideoTs
                }
                this.currentVideoTs = vFrame.timestamp

                if( this.currentVideoTs && this.videoOffsetTs ) {
                    this.timeBufferChecker.AddItem({
                        ts: this.currentVideoTs,
                        compensatedTs: this.currentVideoTs + this.videoOffsetTs,
                        estimatedDuration,
                        clkms: vFrame.timestamp
                    })
                    this.encoder.encode( vFrame )
                } else {
                    vFrame.close()
                }

                while( this.buffer.length > 0 ) {
                    const vchunk = this.buffer.shift() 
                    // AV1 の場合は、最初の keyframe にのみ metadata が付与される
                    // QTが変わった時には、metadata はたぶん、更新される？ので、その時には this.metadata を更新する
                    if( vchunk.metadata ) {
                        this.metadata = vchunk.metadata
                    }

                    if( vchunk.chunk.type === 'key' && !vchunk.metadata ) {
                        vchunk.metadata = this.metadata
                    }

                    controller.enqueue( vchunk )
                }
            }
        })

        // setup properties
        this.encoder = new VEncoder()
        this.timeBufferChecker = props.timeBufferChecker
        this.buffer = []
        
        // start encoder
        //@ts-ignore
        this.encoder.init( props.config )
        this.encoder.on('vchunk', obj => {
            this.buffer.push( obj )
        })
    }
}