import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import MainReceiver from "../components/main-receiver"; 
import SatelliteSender from "../components/satellite-sender";

import { 
    endpoint, 
    wsReceiver, 
    //wsSender, 
} from '../config';



const isDevelopment = process.env.NODE_ENV === 'development'
const apiServer = isDevelopment ? 'http://localhost:3010' : ''

export default function MainVenue() {
    const { eventId } = useParams()

    const [_mainNamespace, setMainNamespace] = useState<string>("null");
    const [_satelliteNamespace, setSatelliteNamespace] = useState<string>("null");

    const _loaded = useRef<boolean>(false);

    useEffect(() => {
        if (!_loaded.current) {
            _loaded.current = true;

            const namespace = uuidv4()
            setSatelliteNamespace(namespace);

            fetch(`${apiServer}/api/v1/satelliteNamespace/${eventId}/${namespace}`, {
                method: 'POST'
            }).then(async res => {
                if (res.ok) {
                    console.log('namespace update request succeeded')
                } else {
                    console.error('namespace update request failed:s', await res.text())
                }
            }).catch(err => {
                console.error('namespace update request failed:%o', err)
            })
        }
    }, [eventId])

    // namespace 取得の useEffect( long polling )
    useEffect(() => {
        let timer:any

        const request = () => {
            fetch(`${apiServer}/api/v1/mainNamespace/${eventId}/${_mainNamespace}`)
                .then( async res => {
                    if( !res.ok ) {
                        // エラーが発生した場合は、10秒後に再度リクエストを送る
                        console.error('namespace request failed:%s', await res.text() )
                        timer = setTimeout( request, 10_000)
                        return
                    }
                    const json = await res.json()

                    if( json.status === 'not_changed') {
                        // 変更なしの場合は、1秒後に再度リクエストを送る
                        timer = setTimeout( request, 1_000 )
                    } else if( json.status === 'changed' ) {
                        // namespace が変更された場合は、 _namespace を更新する
                        // これにより useEffect が再度呼ばれるため、新しい namespace での
                        // long polling request が始まる
                        console.log('namespace changed:%s', json.namespace)
                        setMainNamespace( json.namespace )
                    } else {
                        // その他のステータスの場合は、5秒後に再度リクエストを送る
                        console.warn('unknown status:%s', json?.status)
                        timer = setTimeout( request, 5_000)
                    }
                })
                .catch( err => {
                    // エラーが発生した場合は、10秒後に再度リクエストを送る
                    console.error('namespace request failed:%o', err)
                    timer = setTimeout( request, 10_000)
                })
        }
        timer = setTimeout( request, 1_000 )

        return () => {
            if( timer ) clearTimeout(timer)
        }
    }, [ eventId, _mainNamespace ])


    return(
        <div className="SatelliteVenue">
            <h1>SatelliteVenue</h1>
            <div>
                eventId: {eventId}<br/>
                main namespace: {_mainNamespace}<br/>
                satellite namespace: {_satelliteNamespace}
            </div>
            <MainReceiver 
                namespace={_mainNamespace} 
                endpoint={endpoint}
                wsUrl={wsReceiver}
            />
            <SatelliteSender 
                namespace={_satelliteNamespace} 
                endpoint={endpoint}
            />
        </div>
    )
}