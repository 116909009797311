import { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'antd'
import PcmGraph from './pcm-graph'

import { SenderData } from '../libs/stream-apis/moqt-data-sink'
import { ReceiverData } from '../libs/stream-apis/moqt-src'

import { signed24BitFromBytes } from '../libs/utils/utils'
import './pcm-monitor.css'

type Props = {
    numChannels: number,
    senderDatas: Array<SenderData>|undefined,
    receiverDatas: Array<ReceiverData>|undefined
}

const CHUNK_SIZE = 256
const NUM_CHUNKS = 16


export default function PcmMonitor(props:Props) {
    const { senderDatas, receiverDatas, numChannels } = props

    const [ _sumSenders, setSumSenders ] = useState<Array<number>>([])
    const [ _avgSenders, setAvgSenders ] = useState<Array<number>>([])

    const [ _sumReceivers, setSumReceivers ] = useState<Array<number>>([])
    const [ _avgReceivers, setAvgReceivers ] = useState<Array<number>>([])

    const [ _sumBitPerfects, setSumBitPerfects ] = useState<Array<number>>([])
    const [ _avgBitPerfects, setAvgBitPerfects ] = useState<Array<number>>([])

    const _senderGraphs = useRef<Array<typeof PcmGraph>>([])
    const _receiverGraphs = useRef<Array<typeof PcmGraph>>([])
    const _checkerGraphs = useRef<Array<typeof PcmGraph>>([])

    const _senderDatas = useRef<Array<{seqId:number, data:ArrayBuffer}>>([])
    const _senderDatasForDraw = useRef<Array<Int32Array>>([])
    const _receiverDatasForDraw = useRef<Array<Int32Array>>([])


    useEffect(() => {
      _senderDatasForDraw.current = new Array(numChannels).fill(0).map( () => new Int32Array(CHUNK_SIZE * NUM_CHUNKS).map(() => 0))
      _receiverDatasForDraw.current = new Array(numChannels).fill(0).map( () => new Int32Array(CHUNK_SIZE * NUM_CHUNKS).map(() => 0))
    }, [ numChannels ])

    useEffect(() => {
        if (senderDatas) {
            _senderDatas.current.push( ...senderDatas.map( ( item:SenderData) => ({
              seqId: item.seqId, data: item.data
            }) ))

            while (_senderDatas.current.length > NUM_CHUNKS * 2) {
                _senderDatas.current.shift()
            }
        }
    }, [senderDatas])

    useEffect(() => {
      if( !senderDatas ) return

      const sums = Array(numChannels).fill(0)
      const avgs = Array(numChannels).fill(0)
      const datas = new Array(numChannels).fill(0).map((_, i) => new Int32Array(CHUNK_SIZE * senderDatas.length))

      for( let n = 0; n < senderDatas.length; n++ ) {
        const senderData = senderDatas[n]
        const filled = n * CHUNK_SIZE
        const ui8arr = new Uint8Array(senderData.data)

        for( let i = 0; i < CHUNK_SIZE * numChannels; i++ ) {
          const j = i % numChannels
          const d = signed24BitFromBytes(ui8arr[i * 3 + 2], ui8arr[i * 3 + 1], ui8arr[i * 3])
          datas[j][Math.floor(i / numChannels) + filled] = d
          sums[j] += d
          avgs[j] += d
        }
      }

      for (let i = 0; i < numChannels; i++) {
        // 2 つの Int16Array をつなげる
        _senderDatasForDraw.current[i].set(_senderDatasForDraw.current[i].slice(CHUNK_SIZE * 4), 0)
        _senderDatasForDraw.current[i].set(datas[i], CHUNK_SIZE * 12)

        //@ts-ignore
        //_senderGraphs.current[i].draw(datas[i])
        _senderGraphs.current[i].draw(_senderDatasForDraw.current[i])

        avgs[i] = avgs[i] / datas[i].length
      }

      setSumSenders(sums)
      setAvgSenders(avgs)
    }, [numChannels, senderDatas])

    useEffect(() => {
      if( !receiverDatas ) return

      const sums = Array(numChannels).fill(0)
      const avgs = Array(numChannels).fill(0)
      const datas = new Array(numChannels).fill(0).map((_, i) => new Int32Array(CHUNK_SIZE * receiverDatas.length))

      for( let n = 0; n < receiverDatas.length; n++ ) {
        const senderData = receiverDatas[n]
        const filled = n * CHUNK_SIZE
        const ui8arr = new Uint8Array(senderData.data)

        for( let i = 0; i < CHUNK_SIZE * numChannels; i++ ) {
          const j = i % numChannels
          const d = signed24BitFromBytes(ui8arr[i * 3 + 2], ui8arr[i * 3 + 1], ui8arr[i * 3])
          datas[j][Math.floor(i / numChannels) + filled] = d
          sums[j] += d
          avgs[j] += d
        }
      }

      for (let i = 0; i < numChannels; i++) {
        // 2 つの Int16Array をつなげる
        _receiverDatasForDraw.current[i].set(_receiverDatasForDraw.current[i].slice(CHUNK_SIZE * 4), 0)
        _receiverDatasForDraw.current[i].set(datas[i], CHUNK_SIZE * 12)

        //@ts-ignore
        //_senderGraphs.current[i].draw(datas[i])
        _receiverGraphs.current[i].draw(_receiverDatasForDraw.current[i])

        avgs[i] = avgs[i] / datas[i].length
      }

      setSumReceivers(sums)
      setAvgReceivers(avgs)
 

      // draw bitperfect checker graph
      const seqId = receiverDatas[ receiverDatas.length - 1].seqId
      const senderData = _senderDatas.current.find( item => item.seqId === seqId )

      if (senderData) {
        const senderArr = new Int32Array(senderData.data)
        const checkDatas = new Array(numChannels).fill(0).map((_, i) => new Int32Array(CHUNK_SIZE))
        const numSamples: number = Math.floor(senderArr.length / numChannels)
        const arr = new Int32Array(receiverDatas[ receiverDatas.length - 1].data)

        const sums = Array(numChannels).fill(0)
        const avgs = Array(numChannels).fill(0)
        for (let i = 0; i < numSamples; i++) {
          for (let j = 0; j < numChannels; j++) {
            const data = arr[i * numChannels + j]
              - senderArr[i * numChannels + j]
            checkDatas[j][i] = data

            sums[j] += data
            avgs[j] += Math.abs(data)
          }
        }
        for (let i = 0; i < numChannels; i++) {
          //@ts-ignore
          _checkerGraphs.current[i].draw(checkDatas[i])
          avgs[i] = avgs[i] / numSamples
        }
        setSumBitPerfects(sums)
        setAvgBitPerfects(avgs)
      }
    }, [numChannels, receiverDatas])

    return (
        <div className="PcmMonitor">
          <h4>Sender</h4>
          <div className="graphArea">
            { numChannels > 0 && new Array(numChannels).fill(0).map( (_, i) => (
              <Row gutter={4} key={i}>
                <Col span={4}>
                  <div className='metrics'>
                    channel: {i}<br/>
                    sum: {_sumSenders[i]}<br/> 
                    avg: {Math.floor(_avgSenders[i] * 100) / 100}
                  </div>
                </Col>
                <Col span={20}>
                  <PcmGraph width='100%' height={120} debug={false} ref={ ref => {
                    //@ts-ignore
                    _senderGraphs.current[i] = ref
                  }} />
                </Col>
              </Row>
            ))}
          </div>

          <h4>Receiver</h4>
          <div className="graphArea">
            { numChannels > 0 && new Array(numChannels).fill(0).map( (_, i) => (
              <Row gutter={4} key={i}>
                <Col span={4}>
                  <div className='metrics'>
                    channel: {i}<br />
                    sum: {_sumReceivers[i]}<br/> 
                    avg: {Math.floor(_avgReceivers[i] *100) / 100}
                  </div>
                </Col>
                <Col span={20}>
                  <PcmGraph width='100%' height={120} debug={false} ref={ ref => {
                    //@ts-ignore
                    _receiverGraphs.current[i] = ref
                  }} />
                </Col>
              </Row>
            ))}
          </div>
          <h4>Bit Perfect checker</h4>
          <div className="graphArea">
            <Row gutter={4}>
            { numChannels > 0 && new Array(numChannels).fill(0).map( (_, i) => (
              <Col span={6} key={i}>
                <div className="metrics">
                    sum: {_sumBitPerfects[i]}, avg: {Math.floor(_avgBitPerfects[i] * 100) / 100}
                </div>
                <PcmGraph width='100%' height={100} debug={false} ref={ ref => {
                  //@ts-ignore
                  _checkerGraphs.current[i] = ref
                }} />
              </Col>
            ))}
            </Row>
          </div>
        </div>
    )
}