import { useRef, forwardRef, useImperativeHandle, useMemo, useCallback, useState } from 'react'
import { Button, Switch } from 'antd'

import DeviceSelector from './device-selector'

import './video-viewer.css'

type Props = {
    idealWidth?: number,
    idealHeight?: number,
    onStarted?: Function,
    disableAudioInput?: boolean 
}

const VideoViewer = forwardRef( function ( props:Props, ref ) {
    const { onStarted, idealWidth, idealHeight, disableAudioInput } = props

    const _video = useRef<HTMLVideoElement>(null)
    const [ _played, setPlayed ] = useState<boolean>( false )
    const [ _videoWidth, setVideoWidth ] = useState<number>( 0 )
    const [ _videoHeight, setVideoHeight ] = useState<number>( 0 )
    const [ _videoSinkId, setVideoSinkId ] = useState<string>('')
    const [ _audioSinkId, setAudioSinkId ] = useState<string>('')

    const [ _echoCancellation, setEchoCancellation ] = useState<boolean>(true)
    const [ _noiseSuppression, setNoiseSuppression ] = useState<boolean>(true)

    const audioOption = useMemo(() => {
        return {
            deviceId: _audioSinkId,
            echoCancellation: _echoCancellation,
            noiseSuppression: _noiseSuppression
        }
    }, [ _audioSinkId, _echoCancellation, _noiseSuppression])


    useImperativeHandle( ref, () => ({
        stop() {
            if( _video.current ) {
                _video.current?.pause()
            }
        },
        play() {
            if( _video.current ) {
                _video.current?.play()
            }
        }
    }))

    const _start = useCallback( async () => {
        if( _video.current ) {
            const width = 1920, height = 1080 // 2K
            const stream = await navigator.mediaDevices.getUserMedia({ 
                video: {
                    deviceId: _videoSinkId,
                    echoCancellation: true,
                    noiseSuppression: true,
                    width: { ideal: idealWidth || width },
                    height: { ideal: idealHeight || height }
                }, 
                audio: disableAudioInput !== undefined ? disableAudioInput : audioOption
            })
            _video.current.srcObject = stream
            _video.current.onloadedmetadata = () => {
                _video.current?.play()
                setPlayed( true )
                setVideoWidth( _video.current?.videoWidth ? _video.current?.videoWidth : 0 )
                setVideoHeight( _video.current?.videoHeight ? _video.current?.videoHeight : 0 )

                if( onStarted && typeof onStarted === 'function' ) {
                    onStarted( _video.current)
                }
            }
        }
    }, [ onStarted, idealWidth, idealHeight, _videoSinkId, audioOption, disableAudioInput ])


    return (
        <div className="VideoViewer">
                <div className="wrapper">
                    <video ref={_video} playsInline muted />
                </div>
                <div className="wrapper" style={{ visibility: _played ? 'hidden': 'visible'}}>
                    <DeviceSelector 
                        kind="videoinput"
                        onChangeDeviceId={setVideoSinkId}
                    />
                    { !disableAudioInput && (
                    <DeviceSelector 
                        kind="audioinput"
                        onChangeDeviceId={setAudioSinkId}
                    />
                    )}
                    { !disableAudioInput && (
                    <div>
                        <Switch onChange={ setEchoCancellation } value={_echoCancellation} /> : Echo Cancellation
                    </div>
                    )}
                    { !disableAudioInput && (
                    <div>
                        <Switch onChange={ setNoiseSuppression } value={_noiseSuppression} /> : Noise Suppression
                    </div>
                    )}
                    <div>
                        <Button type="primary" danger onClick={_start}>Start</Button>
                    </div>
                </div>
                <div className="meta-wrapper" style={{ visibility: _played ? 'visible': 'hidden'}}>
                    {_videoWidth} x {_videoHeight}
                </div>
        </div>
    )
})

export default VideoViewer