export default class AudioBufferLoader {
    _label:string
    _url:string
    _ctx:AudioContext
    _buffer:AudioBuffer|null
    _loaded: boolean
    
    constructor( label:string, url:string ) {
        this._label = label
        this._url = url

        // @ts-ignore
        window.AudioContext = window.AudioContext || window.webkitAudioContext

        this._ctx = new AudioContext()

        this._buffer = null
        this._loaded = false
    }

    get loaded() {
        return this._loaded
    }

    async load():Promise<void> {
        await this._loadBuffer();
    }

    _loadBuffer():Promise<void> {
        return new Promise( ( resolve, reject ) => {
            const request = new XMLHttpRequest();
            request.open('get', this._url, true);
            // arraybuffer は  XMLHttpRequest Level 2 の仕様です｡音声などのバイナリデータを処理します
            request.responseType = 'arraybuffer';
            request.onload = () => {
                this._ctx.decodeAudioData(
                    request.response,
                    (buffer) => {
                        if (! buffer) {
                            console.error('error decodeing file data: %s', this._url);
                            return;
                        }
                        this._buffer = buffer;
                        this._loaded = true
                        resolve()
                    },
                    function(error) {
                        reject( error )
                    }
                );
            };
            request.onerror = function() {
                reject( new Error("AudioBuffeerLoader: XHR error"))
            };
            request.send();
        })
    }
    play( delay:number) {
        const source = this._ctx.createBufferSource();
        source.buffer = this._buffer;
        source.connect(this._ctx.destination);
        source.start( this._ctx.currentTime + ( delay / 1000 ));
    }
}