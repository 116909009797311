import { useRef, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react'

type Props = {
    width?: number|string,
    height?: number|string,
    debug?: boolean
}
const PcmGraph = forwardRef(( props:Props, ref ) => {
    useImperativeHandle(ref, () => ({
        draw: _draw
    }))

    const { width, height, debug } = props
    const _canvas = useRef<HTMLCanvasElement>(null)
    const _cvs = useRef<CanvasRenderingContext2D|null>(null)

    // canvas の初期化
    useEffect(() => {
        if( _canvas.current ) {
            _cvs.current = _canvas.current.getContext('2d')
            _canvas.current.width = _canvas.current.clientWidth
            _canvas.current.height = _canvas.current.clientHeight
        }
    },[])


    const _draw = useCallback(( data:Int16Array ) => {
        if( _canvas.current && _cvs.current ) {
            if( debug ) console.log( 'draw:%o', data )
            const w = _canvas.current.width
            const h = _canvas.current.height
            const pad = 10
            const _w = _canvas.current.width - ( pad << 1 )
            const _h = _canvas.current.height - ( pad << 1 )

            _cvs.current.fillStyle = 'black'
            _cvs.current.fillRect(0, 0, w, h)

            _cvs.current.strokeStyle = 'pink'



            if (data && data.length > 0) {
                const len = data.length
                const step = _w / len
                //@ts-ignore
                const max = Math.abs( Math.max(...data) )
                //@ts-ignore
                const min = Math.abs( Math.min(...data) )

                // 描画のためにabs y の最大値を求める（0 の場合は 1 ）
                const Y = Math.max( min, max ) || 1

                _cvs.current.beginPath()
                _cvs.current.moveTo(pad, pad + (_h / 2))

                for (let i = 0; i < len; i++) {
                    const x = Math.floor( pad + i * step )
                    const _y = Math.floor(data[i] / Y * (_h / 2))
                    const y = Math.floor( pad + (_h / 2) + _y )
                    if( i < 2 ) {
                        if( debug ) console.log( 'x:%d, y:%d', x, y )
                    }
                    if( i === 0 ) {
                        _cvs.current.moveTo(x, y)
                    } else {
                        _cvs.current.lineTo(x, y)
                    }
                }

                _cvs.current.stroke()
            }
        }
    }, [ debug ])

    return (
        <div className="PcmGraph"> 
            <canvas ref={_canvas} style={{ 'width': width, 'height': height}}/>
        </div>
    );
})

export default PcmGraph