export class TrafficCalcurator {
    _label: string = '';
    _total: number = 0;
    _lastTotal: number = 0;
    _count: number = 0;
    _lastCount: number = 0;
    _status: string = 'IDLE';
    _timer: number|null = null;
    _interval: number = 1_000;

    constructor( { label }:{ label: string } ) {
        this._label = label;
    }

    start() {
        this._status = 'RUNNING';

        this._startLoop();
    }

    stop() {
        if( this._timer ) {
            clearInterval( this._timer );
        }
        this._status = 'IDLE';
    }

    add( len: number ) {
        if( this._status === 'RUNNING' ) {
            this._total += len;
            this._count++;
        }
    }

    _startLoop() {
        this._timer = window.setInterval(() => {
            const diffTraffic = this._total - this._lastTotal;
            this._lastTotal = this._total;
            const traffic = diffTraffic * 8 / ( this._interval / 1_000 );

            const diffCount = this._count - this._lastCount;
            this._lastCount = this._count;
            console.log( '[%s]traffic:%d, count:%d', this._label, traffic, diffCount );
        }, this._interval )
    }
}