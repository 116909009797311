import { useParams } from "react-router-dom";
import { Col, Row } from 'antd'

export default function VenueTest() {
    const { eventId } = useParams()

    return (
        <div className="VenueTest">
            <h1>VenueTest</h1>
            <div>
                eventId: {eventId}
            </div>
            <Row gutter={16}>
                <Col span={12}>
                    <iframe title="main-venue" src={ `/dante/main-venue/${eventId}` } style={{ width: "100%", border: 0}} />
                </Col>
                <Col span={12}>
                    <iframe title="satellite-venue" src={ `/dante/satellite-venue/${eventId}` } style={{ width: "100%", border: 0}} />
                </Col>
            </Row>
        </div>
    )
}