type Props = {
    minJitterBufferMs?:number
    concealment?:boolean
}

type MetaData = {
    clkms: number,
    seqId: number,
    captureClkms: number,
}

export type DataType = {
    metadata:MetaData|undefined,
    payload:Int8Array|undefined
}

const DEFAULT_MIN_JITTER_BUFFER_MS = 250

export default class SyncJitterBuffer {
    _buffer: Array<any>
    _maxLen: number
    _lastSeqId: number
    _minJitterBufferMs: number
    _concealment: boolean

    constructor( props:Props = {} ) {
        this._buffer = [] 
        this._maxLen = 2000
        this._lastSeqId = -1
        this._minJitterBufferMs = props.minJitterBufferMs ? props.minJitterBufferMs : DEFAULT_MIN_JITTER_BUFFER_MS
        this._concealment = props.concealment ? props.concealment : false
    }

    get minJitterBufferMs():number {
        return this._minJitterBufferMs
    }

    set minJitterBufferMs( val:number ) {
        if( val > 0 ) {
            this._minJitterBufferMs = val
        }
    }

    get concealment():boolean {
        return this._concealment
    }

    set concealment( val: boolean ) {
        this._concealment = val
    }

    get length():number {
        return this._buffer.length
    }

    get lastSeqId():number {
        return this._lastSeqId 
    }

    /**
     * バッファにデータを追加する
     * 
     * @param data {DataType}
     */
    addItem( data: DataType ) {
        if( data.metadata?.clkms ) {
            this._buffer.push( data )

            // buffer が maxLen を超えたら先頭を削除 (擬似的 ring buffer として動作)
            if( this._buffer.length > this._maxLen ) {
                this._buffer = this._buffer.slice( 1 )
            }
        }
    }

    /**
     * バッファからデータを Array として取り出す
     * 
     * @returns {Array<DataType>}
     */
    getItem() {
        const now = Date.now()
        const ret = []

        while (
            this._buffer.length > 0  // buffer にデータがある
            && this._buffer[0].metadata  // metadata がある
            && (now - this._buffer[0].metadata.clkms) >= this._minJitterBufferMs
            // data が到達した時間から minJitterBufferMs 以上経過している
        ) {
            const data = this._buffer.shift()
            if( data.metadata.seqId < this._lastSeqId ) {
                // すでに処理済みのデータは無視
                continue
            } else {
                ret.push( data )
            }
        }

        const maxSeqId = ret.reduce(( acc, cur ) => {
            return Math.max( acc, cur.metadata.seqId )
        }, -1 )

        for( const data of this._buffer ) {
            if( data.metadata && data.metadata.seqId < maxSeqId ) {
                ret.push( data )
            }
        }

        const sorted = this._sortBySeqId( ret )

        if( !this._concealment ) {
            this._lastSeqId = maxSeqId
            return sorted
        } else {
            //@ts-ignore
            const concealed = sorted.reduce(( acc, curr ) => {
                if( acc.length === 0 ) {
                    let ret = [ curr ]
                    if( this._lastSeqId !== -1 ) {
                        //@ts-ignore
                        while( ++this._lastSeqId < curr.metadata.seqId ) {
                            //@ts-ignore
                            ret = [ { ...curr, metadata: { ...curr.metadata, seqId: this._lastSeqId } }, ...ret ]
                        }
                    }
                    return ret
                } else {
                    //@ts-ignore
                    const last = acc[acc.length - 1]
                    //@ts-ignore
                    let lastSeqId = last.metadata.seqId

                    let ret = [ ...acc ]

                    //@ts-ignore
                    while( ++lastSeqId < curr.metadata.seqId ) {
                        //concealement
                        //@ts-ignore
                        ret = [ ...ret, { ...last, metadata: { ...last.metadata, seqId: lastSeqId } } ]
                    }

                    //@ts-ignore
                    return  [...ret, curr]
                }
            }, [])

            this._lastSeqId = maxSeqId
            //@ts-ignore
            return this._sortBySeqId( concealed )
        }
    }

    /**
     * バッファからデータを全て消去する
     */
    flush() {
        this._buffer.length = 0
    }

    _sortBySeqId( buffer:Array<DataType> ) {
        //@ts-ignore
        buffer.sort( ( a, b ) => ( a.metadata.seqId < b.metadata.seqId ? -1 : 1 ))
        return buffer
    }
}