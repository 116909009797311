import AudioPlayer from '../audio-player'

type Props = {
    numChannels: number,
    channels: Array<number>,
    sinkId?: string
}

export default class DantePlayerSink extends WritableStream {
    numChannels:number // Dante のチャネル数。例 this.numChannels = 4
    channels:Array<number> // 再生するチャネル。例 channel 0, 2 を再生する場合
                           // this.channels = [0, 2]
    audioPlayer: AudioPlayer|null
    sinkId: string

    constructor( props:Props ) {
        super({
            write: ( obj:{payload:Uint8Array} ) => {
                const int16:Int16Array = new Int16Array( obj.payload.buffer )
                const len = int16.length / this.numChannels
                const f32channels:Array<Float32Array> 
                    = new Array( this.channels.length).fill( new Float32Array(len) )
                
                for( let j = 0; j < this.channels.length; j++ ) {
                    for( let i = 0; i < len; i++ ) {
                        const idx = this.channels[j]
                        const data = int16[ i * this.numChannels + idx]
                        const _data = data / 32768
                        f32channels[j][i] = Math.max( -1, Math.min( 1, _data ))
                    }
                    if( this.audioPlayer) {
                        this.audioPlayer.add( j, f32channels[j] )
                    }
                } 
            }
        })
        this.numChannels = props.numChannels
        this.channels = props.channels
        this.sinkId = props.sinkId || 'default'

        this.audioPlayer = new AudioPlayer({ sinkId: this.sinkId })
        this.audioPlayer.start()
    }

    restart() {
        if( this.audioPlayer ) {
            this.audioPlayer.stop()
            this.audioPlayer = null

            setTimeout(() => {
                this.audioPlayer = new AudioPlayer({ sinkId: this.sinkId })
                this.audioPlayer.start()
            }, 500)
        }
    }

}