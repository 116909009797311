import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Moqt from '../libs/moqt'
import randomstring from 'randomstring'

import FaceLandmarksDetector from './face-landmarks-detector'

import VideoCapture from '../libs/video-capture'
import VEncoder from '../libs/video-encoder'

import AudioCapture from '../libs/audio-capture'
import AEncoder from '../libs/wav-encoder'

import { TimeBufferChecker } from '../libs/utils/time_buffer_checker'
import { TrafficCalcurator } from '../libs/utils/traffic-calcurator'

//import { midiNotes } from '../libs/midi-notes'

import { MoqtTracks } from '../types/moqt'
import './sync-video-sender.css'

const moqTracks: MoqtTracks = {
    video: {
        id: 0,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "vc",
        name: "",
        mediaType: "video",
        packagerType: 'loc',
        maxInFlightRequests: 50,
        isHipri: false,
        authInfo: "secret"
    },
    audio: {
        id: 1,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "vc",
        name: "",
        mediaType: "audio",
        packagerType: 'loc',
        maxInFlightRequests: 50,
        isHipri: true,
        authInfo: "secret"
    },
    data: {
        id: 2,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "vc",
        mediaType: "data",
        name: "foo",
        packagerType: 'raw',
        maxInFlightRequests: 5,
        isHipri: false,
        authInfo: "secret"
    }
}

interface Props {
    endpoint:string,
    namespace: string,
    videoTrackName:string,
    setVideoTrackName?:Function
    audioTrackName:string,
    setAudioTrackName?:Function,
    setNamespace:Function,
    wsSender?:string,
    sendFaceLandmarks?:boolean,
    sendMidi?: boolean
}

type Resolution = {
    width: number,
    height: number
}

type MIDIInput = {
    name: string,
    manufacturer: string
}

const VIDEO = {
    WIDTH: 640, 
    HEIGHT: 360, 
    BITRATE: 1_000_000
}


export default function SyncVideoSender(props:Props) {
    const { endpoint, videoTrackName, setVideoTrackName, audioTrackName, setAudioTrackName, setNamespace, wsSender, sendFaceLandmarks, sendMidi } = props
    const [ _connected, setConnected ] = useState<boolean>( false )
    const [ _captureStarted, setCaptureStarted ] = useState<boolean>(false)
    const [ _resolution, setResolution ] = useState<Resolution>({ width: 0, height: 0 })
    const [ _textMessage, setTextMessage ] = useState<string>('hi')
    const [ _errMessage, setErrorMessage ] = useState<string>('')
    const [ _videoDevices, setVideoDevices ] = useState<Array<{ deviceId:string, label:string }>>([])
    const [ _audioDevices, setAudioDevices ] = useState<Array<{ deviceId:string, label:string }>>([])
    const [ _videoDeviceId, setVideoDeviceId ] = useState<string>('')
    const [ _audioDeviceId, setAudioDeviceId ] = useState<string>('')
    const [ _sendAudio, setSendAudio ] = useState<boolean>(true)
    const [ _sendFaceLandmarks, setSendFaceLandmarks ] = useState<boolean>(sendFaceLandmarks !== undefined ? sendFaceLandmarks : true)
    const [ _sendMidi, setSendMidi ] = useState<boolean>( sendMidi !== undefined ? sendMidi : true )
    const [ _detectedMidiMessages, setDetectedMidiMessages ] = useState<Array<String>>([])
    const [ _midiInputs, setMidiInputs ] = useState<Array<MIDIInput>>([])

    const _moqt = useRef<Moqt>()
    const _videoCapture = useRef<VideoCapture>()
    const _audioCapture = useRef<AudioCapture>()
    const _vEncoder = useRef<VEncoder>()
    const _aEncoder = useRef<AEncoder>()
    const _videoWrapperEl = useRef<HTMLDivElement>(null)
    const _videoStream = useRef<MediaStream>()
    const _faceLandmarksDetector = useRef<typeof FaceLandmarksDetector>()
    const _disabledMidi = useRef<boolean>(false)
    const _midiAccessed = useRef<boolean>(false)
    const _faceLandmarksTrafficCalcurator = useRef<TrafficCalcurator>()
    const _poseLandmarksTrafficCalcurator = useRef<TrafficCalcurator>()

    const _currentAudioTs = useRef<number>()
    const _currentVideoTs = useRef<number>()
    const _audioOffsetTs = useRef<number>()
    const _videoOffsetTs = useRef<number>()

    const _midiSeqId = useRef<number>(0)
    const _faceLandmarksSeqId = useRef<number>(0)
    const _poseLandmarksSeqId = useRef<number>(0)

    const videoEncoderConfig = useMemo(() => ({
        encoderConfig: {
            // codec: 'avc1.42001e', // Baseline = 66, level 30 (see: https://en.wikipedia.org/wiki/Advanced_Video_Coding)
            codec: 'avc1.640028',  // High profile, level 40
            width: VIDEO.WIDTH,
            height: VIDEO.HEIGHT,
            bitrate: VIDEO.BITRATE, // 1 Mbps
            framerate: 30,
            latencyMode: 'realtime', // Sends 1 chunk per frame
        },
        encoderMaxQueueSize: 2,
        keyframeEvery: 60,
    }), []);

    useEffect(() => {
        return function() {
            if( _moqt.current ) {
                _moqt.current.disconnect()
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }
    }, [])

    useEffect(() => {
        if( _sendFaceLandmarks ) {
            _faceLandmarksTrafficCalcurator.current = new TrafficCalcurator({ label: 'face-landmarks' })
            _faceLandmarksTrafficCalcurator.current.start()
            _poseLandmarksTrafficCalcurator.current = new TrafficCalcurator({ label: 'pose-landmarks' })
            _poseLandmarksTrafficCalcurator.current.start()
        }
    }, [ _sendFaceLandmarks])

    const _getVideoDevices = useCallback( async () => {
        const devices = await navigator.mediaDevices.enumerateDevices()

        const videoDevices = devices.filter( item => item.kind === 'videoinput' )
                .map( item => ({ deviceId: item.deviceId, label: item.label })) 
        const audioDevices = devices.filter( item => item.kind === 'audioinput' )
                .map( item => ({ deviceId: item.deviceId, label: item.label })) 

        setVideoDeviceId( videoDevices[0].deviceId )
        setAudioDeviceId( audioDevices[0].deviceId )
        setVideoDevices( videoDevices )
        setAudioDevices( audioDevices )
    }, [])

    ////////////////////////////////////////////////////////////
    // MIDI 処理
    ////////////////////////////////////////////////////////////

    /**
     * MIDI メッセージを送信する
     * 
     */
    const _sendMidiMessage = useCallback( ( midi:Array<number> ) => {
        if( _moqt.current && midi.length === 3 ) {
            // const chunk = JSON.stringify({
            //     type: 'midi-data',
            //     meta: {
            //         timestamp: Date.now(),
            //     },
            //     payload: JSON.stringify( midi )
            // })
            // _moqt.current.send({
            //     type: "data",
            //     mediaType: "data",
            //     chunk,
            //     seqId: _midiSeqId.current++
            // })

            const buff = new ArrayBuffer(midi.length)
            const chunkArray = new Uint8Array(buff)
            for( let i = 0; i < midi.length; i++ ) {
                chunkArray[i] = midi[i]
            }

            _moqt.current.send({
                type: "data",
                mediaType: "data",
                metadata: { kind: 'midi-message', timestamp: Date.now() },
                chunk: chunkArray,
                seqId: _midiSeqId.current++
            })
 
        }
    }, [])


    /**
     * MIDI デバイスをセットアップする
     */
    const _setupMidi = useCallback(() => {
        if( !_sendMidi ) return
        if( _midiAccessed.current ) return
        _midiAccessed.current = true
        // @ts-ignore
        navigator.requestMIDIAccess().then( midi => {
            // @ts-ignore
            for( const input of midi.inputs.values() ) {
                setMidiInputs( prev => [...prev, input] )
                // @ts-ignore
                input.onmidimessage = mesg => {
                    if( _disabledMidi.current ) return

                    // @ts-ignore
                    const view = new DataView( mesg.data.buffer )
                    const str = `[${Date.now()}] ${view.getUint8(0)}, ${view.getUint8(1)}, ${view.getUint8(2)}`
                    setDetectedMidiMessages( prev => [str, ...prev].slice( 0, 10 ))
                    _sendMidiMessage( [ view.getUint8(0), view.getUint8(1), view.getUint8(2)] )
                }      
            }
        }, ( err:Error ) => {
            console.error("MIDI error: %o", err )
        } )
    }, [ _sendMidi, _sendMidiMessage ])

    /**
     * MOQT に接続する
     */
    const _connect = useCallback( (moqTracks:MoqtTracks) => {
        if(_moqt.current) return

        setErrorMessage('')

        _moqt.current = new Moqt()
        _moqt.current.connect({ endpoint })
            .then( async mesg => {
                // to avoid LINT error.
                if( !_moqt.current ) return

                console.log('[moqt] connected to %s: %o', endpoint, mesg)

                const namespace = randomstring.generate(8)
                const videoName = videoTrackName
                const audioName = audioTrackName

                moqTracks.video.namespace = namespace
                moqTracks.audio.namespace = namespace
                moqTracks.data.namespace = namespace
                setNamespace( namespace )

                moqTracks.video.name = videoName
                moqTracks.audio.name = audioName

                if( setVideoTrackName ) setVideoTrackName( videoName )
                if( setAudioTrackName ) setAudioTrackName( audioName )

                const ret = await _moqt.current.createPublisher( moqTracks )
                console.log( 'createPublisher response:%o', ret )

                _getVideoDevices()
                _setupMidi()

                setConnected( true )
            })
            .catch( (err:Error) => {
                console.error( err )
                setErrorMessage( err.message )
                setConnected( false )
            })

        _moqt.current.addListener('error', (mesg:string) => {
            setErrorMessage( mesg )
        })

        _moqt.current.addListener('closed', () => {
            setConnected( false )
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        })
    }, [ endpoint, videoTrackName, audioTrackName, setVideoTrackName, setAudioTrackName, setNamespace, _setupMidi, _getVideoDevices ])

    const _sendMediaData = useCallback( ( obj:{ type:string, mediaType:string, firstFrameClkms?:number, compensatedTs?:number, estimatedDuration?:number, seqId:number, metadata?:object, chunk:any } ) => {
        if( _moqt.current ) {
            _moqt.current.send( obj )
        }
    }, [])

    const _sendFaceLandmarksResult = useCallback( async ( landmarks:Array<Array<{visibility: number, x:number, y:number, z:number }>> ) => {
        if( _moqt.current && landmarks.length > 0 ) {
            // @ts-ignore

            // encode landmarks
            // x, y については、解像度が 4K でも 3820 px あれば良いので、16 bit で送る
            // z については、よく分からないので、とりあえず 8 bit で送る
            // visibility については、0 か 1 なので、8 bit で送る(ちょっと勿体無いが)
            // 
            // 以上により、1 つのランドマークは 6 byte で送ることができ、これにより
            // トラフィックが 20Mbps 程度であったのが、 1.3Mbps 程度に激減した
            const payload = new ArrayBuffer( landmarks[0].length * 6 )
            const payloadArray = new Uint8Array( payload )
            {
                let offset = 0
                for( const item of landmarks[0] ) {
                    const x = Math.min( item.x * 65535, 65535 )
                    const y = Math.min( item.y * 65535, 65535 )
                    const z = Math.max( Math.min( item.z * 128, 128 ), -127 )
                    const visibility = item.visibility

                    const xy = new Uint16Array([ x, y ])
                    const zv = new Int8Array([ z, visibility ])
                    payloadArray.set( new Uint8Array( xy.buffer ), offset )
                    payloadArray.set( new Uint8Array( zv.buffer ), offset + xy.byteLength )
                    offset += ( xy.byteLength + zv.byteLength )
                }
            }

            // データ圧縮する場合は、以下のコメントアウトを外す
            // これにより、トラフィックは 1.3 Mbps から 1.1 Mbps に減少する
            // 減少幅が 10 % 程度のため、取りあえず現状はコメントアウトしている
            //
            // @ts-ignore
            // const stream = new Response( payload ).body!.pipeThrough( new CompressionStream('gzip'))
            // const _payload = await new Response( stream ).arrayBuffer()

            // 以前のコード。単純に JSON.stringify していた
            // 大体 20 Mbps ぐらいのトラフィックが発生していた
            //
            // const chunk = JSON.stringify({
            //     type: 'face-landmarks',
            //     meta: {
            //         timestamp: Date.now()
            //     },
            //     payload: landmarks
            // })
            
            // トラフィック算出（ console.log で表示される）のための処理
            if( _faceLandmarksTrafficCalcurator.current ) {
                _faceLandmarksTrafficCalcurator.current.add( payload.byteLength )
            }

            _moqt.current.send({
                type: "data",
                mediaType: "data",
                metadata: { kind: 'face-landmarks', timestamp: Date.now() },
                chunk: payloadArray,
                seqId: _faceLandmarksSeqId.current++
            })
        }
    }, [] )

    const _sendPoseLandmarksResult = useCallback( async ( landmarks:Array<Array<{visibility: number, x:number, y:number, z:number }>> ) => {
        if( _moqt.current && landmarks.length > 0 ) {
            // @ts-ignore

            // encode landmarks
            // x, y については、解像度が 4K でも 3820 px あれば良いので、16 bit で送る
            // z については、よく分からないので、とりあえず 8 bit で送る
            // visibility については、0 か 1 なので、8 bit で送る(ちょっと勿体無いが)
            // 
            // 以上により、1 つのランドマークは 6 byte で送ることができ、これにより
            // トラフィックが 20Mbps 程度であったのが、 1.3Mbps 程度に激減した
            const payload = new ArrayBuffer( landmarks[0].length * 6 )
            const payloadArray = new Uint8Array( payload )
            {
                let offset = 0
                for( const item of landmarks[0] ) {
                    const x = Math.min( item.x * 65535, 65535 )
                    const y = Math.max( 0, Math.min( item.y * 65535, 65535 ) )
                    const z = Math.max( Math.min( item.z * 128, 128 ), -127 )
                    const visibility = item.visibility

                    const xy = new Uint16Array([ x, y ])
                    const zv = new Int8Array([ z, visibility ])
                    payloadArray.set( new Uint8Array( xy.buffer ), offset )
                    payloadArray.set( new Uint8Array( zv.buffer ), offset + xy.byteLength )
                    offset += ( xy.byteLength + zv.byteLength )
                }
            }

            // データ圧縮する場合は、以下のコメントアウトを外す
            // これにより、トラフィックは 1.3 Mbps から 1.1 Mbps に減少する
            // 減少幅が 10 % 程度のため、取りあえず現状はコメントアウトしている
            //
            // @ts-ignore
            // const stream = new Response( payload ).body!.pipeThrough( new CompressionStream('gzip'))
            // const _payload = await new Response( stream ).arrayBuffer()

            // 以前のコード。単純に JSON.stringify していた
            // 大体 20 Mbps ぐらいのトラフィックが発生していた
            //
            // const chunk = JSON.stringify({
            //     type: 'face-landmarks',
            //     meta: {
            //         timestamp: Date.now()
            //     },
            //     payload: landmarks
            // })
            
            // トラフィック算出（ console.log で表示される）のための処理
            if( _poseLandmarksTrafficCalcurator.current ) {
                _poseLandmarksTrafficCalcurator.current.add( payload.byteLength )
            }

            _moqt.current.send({
                type: "data",
                mediaType: "data",
                metadata: { kind: 'pose-landmarks', timestamp: Date.now() },
                chunk: payloadArray,
                seqId: _poseLandmarksSeqId.current++
            })
        }
    }, [] )



    const _disconnect = useCallback( async () => {
        if( _moqt.current ) {
            await _moqt.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }
        setConnected( false )
    }, [])

    const _startCapture = useCallback( async () => {
        if( !_moqt.current ) return
        if( _videoStream.current ) return

        const videoConstraints:MediaTrackConstraints = { 
            width: { ideal: VIDEO.WIDTH }, 
            height: { ideal: VIDEO.HEIGHT }, 
            deviceId: _videoDeviceId 
        }
        const audioConstraints:MediaTrackConstraints = {
            echoCancellation: false,
            autoGainControl: false,
            noiseSuppression: false,
            deviceId: _audioDeviceId
        }
        const stream:MediaStream = await navigator.mediaDevices.getUserMedia({video:videoConstraints, audio: audioConstraints })
        const videoEl:HTMLVideoElement = document.createElement('video')
        videoEl.srcObject = stream
        videoEl.muted = true
        _videoStream.current = stream
        

        videoEl.onloadedmetadata = async () => {
            await videoEl.play()
            if( _videoWrapperEl.current ) {
                _videoWrapperEl.current.appendChild( videoEl )
                setResolution({ width: videoEl.videoWidth, height: videoEl.videoHeight })
            }

            setCaptureStarted( true )

            _videoCapture.current = new VideoCapture() // single
            _vEncoder.current = new VEncoder() // each track

            console.log('send audio: %s', wsSender)
            _audioCapture.current = new AudioCapture({})
            _aEncoder.current = new AEncoder()

            const vTimeBufferChecker = new TimeBufferChecker("video") // each track
            const aTimeBufferChecker = new TimeBufferChecker("audio")

            //////////////////////////////////////////////
            // capture part
            //////////////////////////////////////////////

            _videoCapture.current.start( videoEl ) // single
            if( _faceLandmarksDetector.current ) {
                //@ts-ignore
                _faceLandmarksDetector.current.startDetection( videoEl )
            }

            // disabled for the talk at techfeed night
            if( _sendAudio ) {
                //@ts-ignore
                _audioCapture.current.start( stream )
            }

            //////////////////////////////////////////////
            // encoder part
            //////////////////////////////////////////////
            const applyingVec = {
                ...videoEncoderConfig,
                encoderConfig: {
                    ...videoEncoderConfig.encoderConfig,
                    width: videoEl.videoWidth,
                    height: videoEl.videoHeight
                }
            }
            _vEncoder.current.init( applyingVec )  // each track


            // each track
            _videoCapture.current.addListener('vFrame', ({ vFrame, clkms /*, TODO - label */ }) => {
                let estimatedDuration = -1
                if( _currentVideoTs.current === undefined ) {
                    if( _audioOffsetTs.current === undefined || _currentAudioTs.current === undefined ) {
                        _videoOffsetTs.current = -vFrame.timestamp
                    } else {
                        _videoOffsetTs.current = -vFrame.timestamp + _currentAudioTs.current + _audioOffsetTs.current
                    }
                } else {
                    estimatedDuration = vFrame.timestamp - _currentVideoTs.current
                }
                _currentVideoTs.current = vFrame.timestamp

                if( _vEncoder.current && _currentVideoTs.current && _videoOffsetTs.current ) {
                    vTimeBufferChecker.AddItem({ 
                        ts: _currentVideoTs.current, 
                        compensatedTs: _currentVideoTs.current + _videoOffsetTs.current,
                        estimatedDuration,
                        clkms: clkms
                    })
                    _vEncoder.current.encode( vFrame )
                } else {
                    vFrame.close()
                }
            })

            _audioCapture.current.addListener('aFrame', ({ metadata, payload, clkms }:{ metadata:Object, payload:Int16Array, clkms:number }) => {
                let estimatedDuration = -1
                //@ts-ignore
                if( _currentAudioTs.current === undefined ) {
                    //@ts-ignore
                    _audioOffsetTs.current = -metadata.timestamp
                } else {
                    //@ts-ignore
                    estimatedDuration = metadata.timestamp - _currentAudioTs.current
                }
                //@ts-ignore
                _currentAudioTs.current = metadata.timestamp

                if( _aEncoder.current && _currentAudioTs.current && _audioOffsetTs.current ) {
                    aTimeBufferChecker.AddItem({
                        ts: _currentAudioTs.current,
                        compensatedTs: _currentAudioTs.current + _audioOffsetTs.current,
                        estimatedDuration,
                        clkms
                    })
                    _aEncoder.current.encode( { metadata, payload })
                }
            })

            // single
            _videoCapture.current.addListener('error', (mesg:string) => console.error( mesg ))


            // each track
            _vEncoder.current.addListener('vchunk', ( { seqId, metadata, chunk }:{ seqId: number, metadata:any, chunk:object }) => {
                //@ts-ignore
                const itemTsClk = vTimeBufferChecker.GetItemByTs( chunk.timestamp )

                const obj = {
                    type: "video",
                    mediaType: "video",
                    firstFrameClkms: itemTsClk?.ts,
                    compensatedTs: itemTsClk?.compensatedTs,
                    estimatedDuration: itemTsClk?.estimatedDuration,
                    seqId,
                    chunk,
                    metadata
                }
                _sendMediaData(obj)
            })

            //@ts-ignore
            _aEncoder.current.addListener("achunk", ( { seqId, metadata, chunk } ) => {
                const itemTsClk = aTimeBufferChecker.GetItemByTs( chunk.timestamp )

                const firstFrameClkms = ( itemTsClk?.ts === undefined || itemTsClk?.ts <= 0 ) ? chunk.timestamp : itemTsClk?.ts

                const obj = {
                    type: "audio",
                    mediaType: "audio",
                    firstFrameClkms,
                    compensatedTs: itemTsClk?.compensatedTs,
                    estimatedDuration: itemTsClk?.estimatedDuration,
                    seqId,
                    chunk,
                    metadata
                }

                _sendMediaData(obj)
            })
        }
    }, [ _sendMediaData, videoEncoderConfig, _videoDeviceId, _audioDeviceId, _sendAudio, wsSender ])

    const _stopCapture = useCallback( async () => {
        if( !_moqt.current ) return
        if( !_videoWrapperEl.current || !_videoStream.current ) return

        _audioOffsetTs.current = undefined
        _currentAudioTs.current = undefined
        _videoOffsetTs.current = undefined
        _currentVideoTs.current = undefined
        
        // stop encoder
        if( _vEncoder.current ) {
            _vEncoder.current.stop()
            _vEncoder.current.destroy()
            _vEncoder.current = undefined
        }

        // stop capture
        if( _videoCapture.current ) {
            _videoCapture.current.stop()
            _videoCapture.current = undefined
        }

        if( _audioCapture.current ) {
            _audioCapture.current.stop()
            _audioCapture.current = undefined
        }

        // stop video stream
        for( const t of _videoStream.current.getTracks() ) {
            t.stop()
        }
        _videoStream.current = undefined

        // remove all children
        let child = _videoWrapperEl.current.lastElementChild

        while( child ) {
            _videoWrapperEl.current.removeChild(child)
            child = _videoWrapperEl.current.lastElementChild
        }

        if( _faceLandmarksDetector.current ) {
            //@ts-ignore
            _faceLandmarksDetector.current.pauseDetection()
        }

        setCaptureStarted( false )
    }, [])

    return (
        <div className="VideoSender">
            <h3>Video Sender</h3>
            <div>
                state: {_connected ? 'connected' : 'disconnected'}
            </div>
            <div>
                <label>
                    send audio : 
                    <input 
                        type="checkbox" 
                        disabled={_connected} 
                        checked={_sendAudio} 
                        onChange={ e => setSendAudio( e.target.checked )} 
                    />
                </label>
                <br />
                <label>
                    Detect face landmarks :
                    <input
                        type="checkbox"
                        disabled={_connected}
                        checked={_sendFaceLandmarks}
                        onChange={ e => setSendFaceLandmarks( e.target.checked )}
                    />
                </label>
                <br />
                <label>
                    Send midi :
                    <input
                        type="checkbox"
                        disabled={_connected}
                        checked={_sendMidi}
                        onChange={ e => {
                            setSendMidi( e.target.checked )
                            _disabledMidi.current = !e.target.checked 
                        }}
                    />
                </label>
                <br />
                <button onClick={() => {
                    if( _connected ) {
                        _disconnect()
                    } else {
                        _connect( moqTracks )
                    }
                }}>{_connected ? 'disconnect' : 'connect' }</button>
                { _connected && (
                    <div>
                        <label>Choose videoDevice :
                            <select value={_videoDeviceId}  onChange={ e => setVideoDeviceId(e.target.value)} disabled={_captureStarted}>
                                { _videoDevices.map(( item, idx ) => (
                                    <option value={item.deviceId} key={idx}>{item.label}</option>
                                ))}
                            </select>
                        </label>
                        <br />
                        <label>Choose audioDevice :
                            <select value={_audioDeviceId}  onChange={ e => setAudioDeviceId(e.target.value)} disabled={_captureStarted}>
                                { _audioDevices.map(( item, idx ) => (
                                    <option value={item.deviceId} key={idx}>{item.label}</option>
                                ))}
                            </select>
                        </label>
                        <button onClick={() => {
                            if( !_captureStarted ) {
                                _startCapture()
                            } else {
                                _stopCapture()
                            }
                        }}>{ !_captureStarted ? 'start' : 'stop' }</button><br />
                        width: {_resolution.width}, height: {_resolution.height}<br/>
                        video track: {videoTrackName}, audio track: {audioTrackName}
                        <hr />
                        <ul>
                        { _midiInputs.map( ( item, idx ) => (
                            <li key={idx}>{item.name} - {item.manufacturer}</li>
                        ))}
                        </ul>
                        <hr />
                        <input type="text" value={_textMessage} onChange={ ( e ) => setTextMessage( e.target.value )} />
                        <button onClick={ () => _sendMidiMessage([]) } >send data</button>
                    </div>
                ) }
            </div>
            <div className='video-wrapper' ref={_videoWrapperEl}>
            </div>
            <div>
                <ul>
                {false && _detectedMidiMessages.map( ( mesg, idx ) => (
                    <li key={idx}>{mesg}</li>
                ))}
                </ul>
            </div>
            <FaceLandmarksDetector 
                disabled={!_sendFaceLandmarks} 
                hidden={true} 
                ref={_faceLandmarksDetector} 
                sendFaceLandmarksResult={_sendFaceLandmarksResult} 
                sendPoseLandmarksResult={_sendPoseLandmarksResult} 
            />
            <div>
                {!!_errMessage ? `Error::${_errMessage}` : '' }
            </div>
        </div>
    )
}