import EventEmitter from "events"

type Props = {
    sampleRate?: number,    // default 48000
    isRawAudio?: boolean
}

export default class AudioCapture extends EventEmitter {
    _ctx:AudioContext|null
    _isRawAudio:boolean


    constructor( props:Props) {
        super()

        const sampleRate = props.sampleRate || 48000
        const options = { sampleRate }

        this._isRawAudio = props.isRawAudio || false

        this._ctx = new AudioContext( options )
    }

    start( stream:MediaStream  ) {
        if( !this._ctx ) return 

        this._ctx.audioWorklet.addModule( new URL( './chunk-generator', import.meta.url ) )
            .then( () => {
                if( !this._ctx ) return 
                const source = this._ctx.createMediaStreamSource( stream )
                const chunkGenerator = new AudioWorkletNode( this._ctx, 'chunk-generator')
                source.connect( chunkGenerator ).connect( this._ctx.destination )

                chunkGenerator.port.onmessage = e => {
                    if( !this._isRawAudio ) {
                        if( e.data.type === "data" && this._ctx ) {
                            const sampleRate = this._ctx.sampleRate
                            const sampleLen = e.data.payload.length
                            const numberOfChannels = e.data.metadata.numberOfChannels
                            const numberOfFrames = sampleLen / numberOfChannels
                            const duration = numberOfFrames / sampleRate * 1_000
                            const timestamp = this._ctx.currentTime
                            
                            e.data.metadata = { ...e.data.metadata, sampleRate, numberOfFrames, duration, timestamp }
                            this.emit( "aFrame", { ...e.data, clkms: Date.now() } )
                        }
                    } else {
                        // f32 to i16
                        const i16arr = new Int16Array( e.data.payload.length )

                        for( let i = 0; i < e.data.payload.length; i++ ) {
                            i16arr[i] = Math.max( -32767, Math.min( Math.floor( e.data.payload[i] * 32767 ), 32767 ) )
                        }
                        this.emit( "aFrame", i16arr.buffer )
                    }
                }
            })
    }

    stop() {
        if( !this._ctx ) return

        this._ctx.close()
    }
}