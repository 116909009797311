import EventEmitter from "events"

type Props = {
    wsReceiver?: string
}

export default class ADecoder extends EventEmitter {
    _worker: Worker
    _wsReceiver: string

    constructor( props: Props ) {
        super()

        this._worker = new Worker( new URL('./worker', import.meta.url))
        this._wsReceiver = props.wsReceiver || ''
        this._addListeners()
    }

    destroy() {
        this._worker.terminate()
    }

    stop() {
        this._worker.postMessage({
                type: 'stop'
        })
    }

    decode( aChunk:any ) {
        this._worker.postMessage({
            type: 'decode',
            metadata: aChunk.metadata,
            payload: aChunk.payload,
            format: this._wsReceiver ? 's16le' : 'u32float'
        })
    }
    _addListeners = () => {
        this._worker.addEventListener('message', ({ data }:{data:any}) => {
            switch( data.type ) {
            case 'aFrame':
                this.emit(`aFrame`, { frameData: data.payload, metadata: data.metadata } )
                break
            case 'error':
                if( data.meta?.kind ) {
                    console.error(`${data.meta.kind}::${data.payload}`)
                    this.emit('error', `${data.meta.kind}::${data.payload}` )
                }
                break
            default:
                /* noop */
                break
            }
        })
    }
}