import SyncJitterBuffer from "../sync-jitter-buffer"
import { DataType } from "../sync-jitter-buffer"
import { v4 as uuidv4 } from 'uuid'

type Props = {
    isVideo?:boolean
    onMetricsReport?:Function
    concealment?:boolean
}

export default class JitterbufferElem extends TransformStream{
    jitterBuffer:SyncJitterBuffer
    keyframeDetected:boolean
    isVideo:boolean
    concealment:boolean
    seqIds:Array<number>
    lastSeqId: number
    lostNumSeqs: number
    onMetricsReport: Function

    id: string

    constructor( props:Props ) {
        super({
            transform: ( chunk:DataType, controller ) => {
                this.jitterBuffer.addItem( chunk )

                const items:Array<DataType> = this.jitterBuffer.getItem()
                if( items ) {
                    while( items.length > 0 ) {
                        const item = items.shift()

                        const seqId = item?.metadata?.seqId 
                        if( seqId && this.lastSeqId > 0 ) {
                            if( seqId - this.lastSeqId > 1 ) {
                                this.lostNumSeqs++
                            }
                        }
                        if( seqId ) {
                            this.lastSeqId = seqId
                        }

                        // data が video のときは、keyframeDetected の状態を見て
                        // enqueue の判断をする
                        if( this.isVideo ) {
                            // key frame のチェックを行う
                            if( !this.keyframeDetected ) {
                                //@ts-ignore
                                if( item.payload.type === 'key' ) {
                                    this.keyframeDetected = true
                                }
                            }

                            // keyframe detected であれば、 enqueue する
                            if( this.keyframeDetected ) {
                                controller.enqueue( item )
                            }
                        } else {
                            // data が video でなければ、単純に enqueue
                            controller.enqueue( item )
                        }
                    }
                }
            }
        })
        this.keyframeDetected = false
        this.isVideo = !!props.isVideo
        this.concealment = !!props.concealment
        this.jitterBuffer = new SyncJitterBuffer({ concealment: this.concealment })
        this.seqIds = []
        this.lastSeqId = -1
        this.lostNumSeqs = 0
        this.id = uuidv4()

        this.onMetricsReport = props.onMetricsReport || function() {}

        setInterval( () => {
            if( this.onMetricsReport && typeof this.onMetricsReport === 'function' ) {
                this.onMetricsReport({ lostNumSeqs: this.lostNumSeqs})
            }
        }, 1_000)
    }

    get minJitterBufferMs() {
        return this.jitterBuffer.minJitterBufferMs
    }

    set minJitterBufferMs( msec:number ) {
        this.jitterBuffer.minJitterBufferMs = msec
        this.keyframeDetected = false
        this.reset()
        this.jitterBuffer.flush()
    }

    flush() {
        this.reset()
        this.jitterBuffer.flush()
    }

    reset() {
        this.lostNumSeqs = 0
        this.lastSeqId = -1
    }
}