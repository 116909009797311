import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Row } from 'antd'
import Moqt from '../libs/moqt'
// import DanteSrc from '../libs/stream-apis/dante-src'
import MoqtDataSink from '../libs/stream-apis/moqt-data-sink'
import MoqtMediaSink from '../libs/stream-apis/moqt-media-sink'
import VideoViewer from './video-viewer'
import VideoCaptureSrc from '../libs/stream-apis/video-capture-src'
import VideoEncoderElem from '../libs/stream-apis/video-encoder-elem'
import AudioCaptureSrc from '../libs/stream-apis/audio-capture-src'
import LogSink from '../libs/stream-apis/log-sink'

import { TimeBufferChecker } from '../libs/utils/time_buffer_checker'

import { MoqtTracks } from '../types/moqt'

const moqVideoTracksTmpl: MoqtTracks = {
    video: {
        id: 0,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        name: "video",
        mediaType: "video",
        packagerType: 'loc',
        maxInFlightRequests: 200,
        isHipri: false,
        authInfo: "secret"
    },
}
const moqAudioTracksTmpl: MoqtTracks = {
    data: {
        id: 0,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        name: "audio",
        mediaType: "data",
        packagerType: 'raw',
        maxInFlightRequests: 200,
        isHipri: true,
        authInfo: "secret"
    },
}



const videoEncoderConfig = {
    encoderConfig: {
        // codec: 'avc1.42001e', // Baseline = 66, level 30 (see: https://en.wikipedia.org/wiki/Advanced_Video_Coding)
        //codec: 'avc1.640028',  // High profile, level 40
        codec: 'av01.0.12M.08',  // Main Profile, level 5.0, 8bits
                                 // see https://cconcolato.github.io/media-mime-support/
                                 //  https://en.wikipedia.org/wiki/AV1
        width: 1920,
        height: 1080,
        framerate: 30,
        bitrateMode: 'constant', // 'constant', 'variable' or 'quantizer'
        //bitrateMode: 'quantizer', // 'constant', 'variable' or 'quantizer'
                                  // see https://zenn.dev/tetter/articles/webcodecs-qp-encoding#webcodecs-%E3%81%AE%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%81%94%E3%81%A8%E3%81%AE-qp-%E8%A8%AD%E5%AE%9A%E6%A9%9F%E8%83%BD
        bitrate: 1_500_000, // 5Mbps
        latencyMode: 'realtime', // Sends 1 chunk per frame
    },
    encoderMaxQueueSize: 64,
    keyframeEvery: 60, // every 2 sec in 30fps video
};


interface Props {
    endpoint:string,
    namespace:string,
    onData?:Function
}

export default function SatelliteSender(props:Props) {
    const { endpoint, namespace } = props

    const [ _connected, setConnected ] = useState<boolean>( false )
    const [ _videoStarted, setVideoStarted ] = useState<boolean>( false )
    const [ _moqVideoTracks, setMoqVideoTracks ] = useState<MoqtTracks>( moqVideoTracksTmpl )
    const [ _moqAudioTracks, setMoqAudioTracks ] = useState<MoqtTracks>( moqAudioTracksTmpl )
    const [ _errMessage, setErrorMessage ] = useState<string>('')

    const [ _videoMetrics, setVideoMetrics ] = useState<{ bps:number, frameRate: number}>({
        bps: 0, frameRate: 0
    })
    const [ _audioMetrics, setAudioMetrics ] = useState<{ bps:number, sampleRate: number}>({
        bps: 0, sampleRate: 0
    })

    const _moqtVideo = useRef<Moqt>()
    const _moqtAudio = useRef<Moqt>()
    const _video = useRef<HTMLVideoElement|null>(null)

    useEffect(() => {
        setMoqVideoTracks(
            Object.entries(moqVideoTracksTmpl).map( ( [ name, moqTrack ] ) => (
                [ name, { ...moqTrack, namespace: `${namespace}-video` } ]
            )).reduce( (acc, [ name, moqTrack ]) => (
                //@ts-ignore
                { ...acc, [name]: moqTrack }
            ), {})
        )
        setMoqAudioTracks(
            Object.entries(moqAudioTracksTmpl).map( ( [ name, moqTrack ] ) => (
                [ name, { ...moqTrack, namespace: `${namespace}-audio` } ]
            )).reduce( (acc, [ name, moqTrack ]) => (
                //@ts-ignore
                { ...acc, [name]: moqTrack }
            ), {})
        )


        return function() {
            if( _moqtVideo.current ) {
                _moqtVideo.current.disconnect()
                _moqtVideo.current.destroy()
                _moqtVideo.current = undefined
            }
            if( _moqtAudio.current ) {
                _moqtAudio.current.disconnect()
                _moqtAudio.current.destroy()
                _moqtAudio.current = undefined
            }
        }
    }, [ namespace ])

    const _onVideoStarted = useCallback( ( video:HTMLVideoElement ) => {
        _video.current = video
        setVideoStarted( true )
        console.log('video started')
    }, [])

    //////////////////////////////////////////
    // start media pipeline for Dante
    //////////////////////////////////////////
    //const _startDantePipeline = useCallback( async () => {
    //    const danteSrc = new DanteSrc({
    //        wsUrl: wsUrl
    //    })
    //    const moqtDataSink = new MoqtDataSink({
    //        type: 'data',
    //        kind: 'dante',
    //        moqTrack: _moqTracks.data,
    //        moqt: _moqt.current,
    //        onData: onData,
    //        onMetrics: setDanteMetrics
    //    })
    //    danteSrc.pipeTo(moqtDataSink)
    //}, [ wsUrl, _moqTracks.data, onData ])

    //////////////////////////////////////////
    // start video pipeline
    //////////////////////////////////////////
    const _startVideoPipeline = useCallback( async () => {
        if( !_video.current ) {
            alert('video is not started')
        } else {
            const vTimeBufferChecker = new TimeBufferChecker("video")
            const config = { ...videoEncoderConfig, encoderConfig: { 
                ...videoEncoderConfig.encoderConfig, 
                width: _video.current.videoWidth, 
                height: _video.current.videoHeight 
            } }

            // @ts-ignore
            const result = await VideoEncoder.isConfigSupported( config.encoderConfig )

            if( result.supported ) {
                const videoCaptureSrc = new VideoCaptureSrc({ video: _video.current })
                const videoEncoderElem = new VideoEncoderElem({ config, timeBufferChecker: vTimeBufferChecker })
                const logSink = new LogSink({ name: 'startVideoPipeline'})

                const moqtSink = new MoqtMediaSink({
                    type: 'video',
                    moqTrack: _moqVideoTracks.video,
                    moqt: _moqtVideo.current,
                    timeBufferChecker: vTimeBufferChecker,
                    onMetrics: setVideoMetrics
                })
                const tee = videoCaptureSrc.pipeThrough( videoEncoderElem ).tee()
                
                tee[0].pipeTo( moqtSink )
                if( false ) { tee[1].pipeTo( logSink ) }
            }
        }
    }, [ _moqVideoTracks.video ])

    /////////////////////////////////////////////////////////////////////
    // start MIDI pipeline
    /////////////////////////////////////////////////////////////////////
    //const _startMidiPipeline = useCallback( async () => {
    //    const midiSrc = new MidiSrc()
    //    const logSink = new LogSink({ name: 'sender-midi'})

    //    midiSrc.pipeTo( logSink )
    //}, [])


    /////////////////////////////////////////////////////////////////////
    // start Audio Capture pipeline
    /////////////////////////////////////////////////////////////////////
    const _startAudioCapturePipeline = useCallback( async () => {
        if( !_video.current || !_video.current.srcObject ) return;

        //@ts-ignore
        const audioCaptureSrc = new AudioCaptureSrc({ stream: _video.current.srcObject, isRawAudio: true })    
        const moqtDataSink = new MoqtDataSink({
            type: 'data',
            kind: 'audio',
            moqTrack: _moqAudioTracks.data,
            moqt: _moqtAudio.current,
            onData: () => {},
            onMetrics: setAudioMetrics
        })
 

        const logSink = new LogSink({ name: 'sender-audio'})

        const tee = audioCaptureSrc.tee()
        tee[0].pipeTo( moqtDataSink)
        if( false ) tee[1].pipeTo( logSink)
    }, [ _moqAudioTracks.data ])

    /**
     * MOQT に接続する
     */
    const _connect = useCallback( () => {
        if( !_video.current ) {
            alert('video is not started')
            return
        }
        if(_moqtVideo.current || _moqtAudio.current) return

        setErrorMessage('')

        _moqtVideo.current = new Moqt()
        _moqtVideo.current.connect({ endpoint })
            .then( async mesg => {
                // to avoid LINT error.
                if( !_moqtVideo.current ) return

                console.log('[dante-moqt] connected to %s: %o', endpoint, mesg)

                const ret = await _moqtVideo.current.createPublisher( _moqVideoTracks )
                console.log( 'createPublisher response:%o', ret )

                setConnected( true )
                if( true ) _startVideoPipeline()
            })
            .catch( (err:Error) => {
                console.error( err )
                setErrorMessage( err.message )
                setConnected( false )
            })

        _moqtVideo.current.addListener('error', (mesg:string) => {
            setErrorMessage( mesg )
        })

        _moqtVideo.current.addListener('closed', () => {
            setConnected( false )
            if( _moqtVideo.current ) {
                _moqtVideo.current.destroy()
                _moqtVideo.current = undefined
            }
        })

        _moqtAudio.current = new Moqt()
        _moqtAudio.current.connect({ endpoint })
            .then( async mesg => {
                // to avoid LINT error.
                if( !_moqtAudio.current ) return

                console.log('[dante-moqt] connected to %s: %o', endpoint, mesg)

                const ret = await _moqtAudio.current.createPublisher( _moqAudioTracks )
                console.log( 'createPublisher response:%o', ret )

                setConnected( true )
                if( true ) _startAudioCapturePipeline()
            })
            .catch( (err:Error) => {
                console.error( err )
                setErrorMessage( err.message )
                setConnected( false )
            })

        _moqtAudio.current.addListener('error', (mesg:string) => {
            setErrorMessage( mesg )
        })

        _moqtAudio.current.addListener('closed', () => {
            setConnected( false )
            if( _moqtAudio.current ) {
                _moqtAudio.current.destroy()
                _moqtAudio.current = undefined
            }
        })
 
    }, [ endpoint, _moqVideoTracks, _moqAudioTracks, _startVideoPipeline, _startAudioCapturePipeline ])




    const _disconnect = useCallback( async () => {
        if( _moqtVideo.current ) {
            await _moqtVideo.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqtVideo.current ) {
                _moqtVideo.current.destroy()
                _moqtVideo.current = undefined
            }
        }
        if( _moqtAudio.current ) {
            await _moqtAudio.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqtAudio.current ) {
                _moqtAudio.current.destroy()
                _moqtAudio.current = undefined
            }
        }
 
        setConnected( false )
    }, [])

    return (
        <div className="SatelliteSender">
            <div>
                NODE_ENV: {process.env.NODE_ENV}, 
                state: {_connected ? 'connected' : 'disconnected'}
            </div>
            <Row gutter={16}>
                <Col span={12}>
                    <div>
                        <h3>Satellite Sender</h3>
                        <VideoViewer 
                            idealWidth={videoEncoderConfig.encoderConfig.width} 
                            idealHeight={videoEncoderConfig.encoderConfig.height} 
                            onStarted={_onVideoStarted} 
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <h3>Dante sender</h3>
                        { _videoStarted && (
                        <Button 
                            type='primary'
                            onClick={ !_connected ? _connect : _disconnect }
                            danger={ _connected}
                            disabled={ _connected }
                        >
                            { !_connected ? 'connect' : 'disconnect' }
                        </Button>
                        )}
                        { _connected && (
                        <Card title="Metrics" style={{width: "100%"}}>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <h5>Video</h5>
                                    <div>traffic: {Math.floor(_videoMetrics.bps / 10_000) / 100} Mbps </div>
                                    <div>frame rate: {_videoMetrics.frameRate} fps</div>
                                </Col>
                                <Col span={8}>
                                    <h5>Audio</h5>
                                    <div>traffic: {Math.floor(_audioMetrics.bps / 10_000) / 100} Mbps </div>
                                    <div>chunk rate: {_audioMetrics.sampleRate} cps</div>
                                </Col>
                                {/*
                                <Col span={8}>
                                    <h5>Dante</h5>
                                    <div>traffic: {Math.floor(_danteMetrics.bps / 10_000) / 100} Mbps </div>
                                    <div>sample rate: {_danteMetrics.sampleRate}</div>
                                </Col>
                                */}
                            </Row>
                        </Card>
                        )}
                        <Card title="Log" style={{width: "100%"}}>
                            <div>
                                {!!_errMessage ? `Error::${_errMessage}` : ''}
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    )
}