import { TrackData } from "../../types/moqt"

type Props = {
    type:string,
    kind:string,
    moqTrack:TrackData,
    //@ts-ignore
    moqt:Moqt,
    onData?:Function|undefined
    onMetrics?:Function|undefined
}

export type SenderData = {
    seqId: number,
    timestamp: number,
    data: ArrayBuffer
}

export default class MoqtDataSink extends WritableStream {
    moqTrack:TrackData
    type:string
    kind:string
    seqId:number
    //@ts-ignore
    moqt:Moqt
    buffer:Array<SenderData>
    onData:Function|undefined
    onMetrics:Function|undefined

    sampleCounter: number
    lastSampleCount: number
    byteCounter: number
    lastByteCount: number

    constructor( props:Props ) {
        super({
            write: ( chunk:ArrayBuffer ) => {
                if( chunk.byteLength > 0 ) {
                    const timestamp = Date.now()
                    const seqId = this.seqId++

                    const type = this.type
                    const mediaType = this.moqTrack.mediaType
                    const kind = this.kind
                    const data = new Uint8Array(chunk)

                    this.moqt.send({
                        type,
                        mediaType,
                        metadata: { kind, timestamp, seqId },
                        chunk: data,
                        seqId
                    })

                    this.sampleCounter++
                    this.byteCounter += data.length

                    this.buffer.push({ seqId, timestamp, data: chunk })
                    // callback のインターバルが短すぎると落ちてしまうため、4回に1回のみコールバックする
                    if( seqId % 4 === 0 ) {
                        if( typeof this.onData === 'function' ) {
                            this.onData( this.buffer )
                        }
                        // this.buffer.length = 0 だと、参照が残ってしまい、pcmMonitor での useEffect が発火しない
                        this.buffer = []
                    }
                }
            } 
        })

        this.moqTrack = props.moqTrack
        this.type = props.type
        this.kind = props.kind
        this.seqId = 0
        this.moqt = props.moqt
        this.buffer = []
        this.onData = props.onData
        this.onMetrics = props.onMetrics

        this.sampleCounter = 0
        this.lastSampleCount = 0
        this.byteCounter = 0
        this.lastByteCount = 0

        setInterval(() => {
            const bps = ( this.byteCounter - this.lastByteCount ) * 8
            const sampleRate = this.sampleCounter - this.lastSampleCount

            if( this.onMetrics && typeof this.onMetrics === 'function') {
                this.onMetrics( { bps, sampleRate } )
            }

            this.lastSampleCount = this.sampleCounter
            this.lastByteCount = this.byteCounter
        }, 1_000)
    }
}