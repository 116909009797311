import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Select } from 'antd'

type Props = {
    kind: string,  // 'videoinput' | 'videooutput | 'audioinput' | 'audiooutput'
    onChangeDeviceId?: Function
}

const kinds = [
    'videoinput',
    'audioinput',
    'audiooutput'
]

export default function DeviceSelector(props:Props) {
    const { kind, onChangeDeviceId } = props

    const [_devices, setDevices] = useState<any[]>([])
    const [_selected, setSelected] = useState<string>('default')

    const _loaded = useRef<boolean>(false)

    const _storageKey = useMemo(() => (
        `device-id-${kind}`
    ), [ kind ])

    const _errMessage = useMemo(() => (
        kinds.includes(kind) ? undefined : `Invalid kind:${kind}`
    ), [ kind ])

    useEffect(() => {
        if( _loaded.current ) return
        _loaded.current = true

        navigator.mediaDevices.enumerateDevices().then( (devices) => {
            const _devices = devices.filter( (d:MediaDeviceInfo) => (
                d.kind === kind
            ))
            console.log( _devices )
            const prev = localStorage.getItem(_storageKey)
            const deviceId = prev && _devices.find( d => d.deviceId === prev ) ? prev : _devices[0]?.deviceId
            setSelected( deviceId )
            setDevices(_devices)
            if( onChangeDeviceId && typeof onChangeDeviceId === 'function' ) {
                onChangeDeviceId(deviceId)
            }
        })
    }, [kind, _storageKey, onChangeDeviceId])

    const _onChange = useCallback((value:string) => {
        localStorage.setItem(_storageKey, value)
        setSelected(value)
        if( onChangeDeviceId && typeof onChangeDeviceId === 'function' ) {
            onChangeDeviceId(value)
        }
    }, [ _storageKey, onChangeDeviceId ])

    return (
        <div className="DeviceSelector">
            <strong>{kind}</strong><br/>
            {_errMessage && (
                <Alert message={_errMessage} type="error" />
            )}
            <Select
                value={_selected}
                style={{ width: 300 }}
                onChange={_onChange}
                options={ _devices.map( (d:MediaDeviceInfo) => (
                    { label: d.label, value: d.deviceId }
                ))}
            />
        </div>
    )
}