import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Row, Slider } from 'antd'
import Moqt from '../libs/moqt'
//import SyncJitterBuffer from '../libs/sync-jitter-buffer'

import MoqtSrc from '../libs/stream-apis/moqt-src'
import JitterbufferElem from '../libs/stream-apis/jitterbuffer-elem'
import DanteSink from '../libs/stream-apis/dante-sink'
import DantePlayerSink from '../libs/stream-apis/dante-player-sink'
import VideoDecoderElem from '../libs/stream-apis/video-decoder-elem'
import VideoRendererSink from '../libs/stream-apis/video-renderer-sink'
import DeviceSelector from './device-selector'
//import AudioDecoderElem from '../libs/stream-apis/audio-decoder-elem'
//import AudioPlayerSink from '../libs/stream-apis/audio-player-sink'
import LogSink from '../libs/stream-apis/log-sink'

import { MoqtTracks, MessageData } from '../types/moqt'

import './dante-receiver.css'

type VideoResolution = {
    width:number,
    height:number
}

interface Props  {
    endpoint:string,
    namespace: string,
    wsUrl:string,
    minJitterBufferMs?:number,
    numChannels:number,
    onData?:Function
}

const moqTracksTmpl: MoqtTracks = {
    data: {
        id: -1, // will be set after SUBSCRIBE procedure
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        mediaType: "data",
        name: "dante",
        packagerType: 'raw',
        authInfo: "secret"
    },
    video: {
        id: -1,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        name: "video",
        packagerType: 'loc',
        mediaType: "video",
        authInfo: "secret"
    },
    rawaudio: {
        id: -1,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        name: "audio",
        mediaType: "data",
        packagerType: 'raw',
        authInfo: "secret"
    },
}

export default function DanteReceiver(props:Props) {
    const { endpoint, namespace, wsUrl, onData, numChannels, minJitterBufferMs } = props

    const [ _connected, setConnected ] = useState<boolean>( false )
    const [ _moqTracks, setMoqTracks ] = useState<MoqtTracks>( moqTracksTmpl )
    const [ _minJitterBufferMs, setMinJitterBufferMs ] = useState<number>( -1 )
    const [ _errMessage, setErrorMessage ] = useState<string>('')
    const [ _videoResolution, setVideoResolution ] = useState<VideoResolution>({ width: 0, height: 0})

    const [ _audioSinkId, setAudioSinkId ] = useState<string>('')

    const [ _videoMetrics, setVideoMetrics ] = useState<{ bps:number, frameRate:number}>({
        bps: 0, frameRate: 0
    })
    const [ _audioMetrics, setAudioMetrics ] = useState<{ bps:number, sampleRate:number}>({
        bps: 0, sampleRate: 0
    })
    const [ _danteMetrics, setDanteMetrics ] = useState<{ bps:number, sampleRate:number}>({
        bps: 0, sampleRate: 0
    })

    const [ _jbVideoMetrics, setJbVideoMetrics ] = useState<{ lostNumSeqs:number}>({
        lostNumSeqs: 0
    })
    const [ _jbAudioMetrics, setJbAudioMetrics ] = useState<{ lostNumSeqs:number}>({
        lostNumSeqs: 0
    })
    const [ _jbDanteMetrics, setJbDanteMetrics ] = useState<{ lostNumSeqs:number}>({
        lostNumSeqs: 0
    })

    const [ _lossRate, setLossRate ] = useState<number>(0.0)

    const _moqt = useRef<Moqt>()
    const _audioMoqtSrc = useRef<MoqtSrc>()
    const _videoMoqtSrc = useRef<MoqtSrc>()
    const _danteMoqtSrc = useRef<MoqtSrc>()
    const _danteJitterBuffer = useRef<JitterbufferElem>()
    const _videoJitterBuffer = useRef<JitterbufferElem>()
    const _audioJitterBuffer = useRef<JitterbufferElem>()
    const _reqId = useRef<number>()
    const _canvas = useRef<HTMLCanvasElement|null>(null)


    useEffect(() => {
        setMoqTracks(
            Object.entries(moqTracksTmpl).map( ( [ name, moqTrack ] ) => (
                [ name, { ...moqTrack, namespace}]
            )).reduce( (acc, [ name, moqTrack ]) => (
                //@ts-ignore
                { ...acc, [name]: moqTrack }
            ), {})
        )

        return function() {
            if( _moqt.current ) {
                _moqt.current.disconnect()
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }
    }, [ namespace ])

    // スライダーの値が変更されたら、JitterBufferの値を変更する
    useEffect(() => {
        if( _minJitterBufferMs > 0 ) {
            if( _danteJitterBuffer.current ) {
                _danteJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs
            }
            if( _videoJitterBuffer.current ) {
                _videoJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs
            }
            if( _audioJitterBuffer.current ) {
                _audioJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs
            }
        }
    }, [ _minJitterBufferMs ])

    // [エミュレーション] データロス率が変更されたら、値を変更する
    useEffect(() => {
        if( _audioMoqtSrc.current ) {
            _audioMoqtSrc.current._lossRate = _lossRate
        }
        if( _videoMoqtSrc.current ) {
            _videoMoqtSrc.current._lossRate = _lossRate
        }
        if( _danteMoqtSrc.current ) {
            _danteMoqtSrc.current._lossRate = _lossRate
        }
    }, [ _lossRate ])

    const _startDantePipeline = useCallback( () => {
        const moqtSrc = new MoqtSrc({
            moqt: _moqt.current,
            type: 'data',
            kind: 'dante',
            onData: onData,
            onMetrics: setDanteMetrics
        })
        _danteMoqtSrc.current = moqtSrc

        _danteJitterBuffer.current = new JitterbufferElem({ isVideo: false, onMetricsReport: setJbDanteMetrics })

        const initMinJitterBufferMs = minJitterBufferMs || 250
        _danteJitterBuffer.current.minJitterBufferMs = initMinJitterBufferMs
        setMinJitterBufferMs( initMinJitterBufferMs )

        const danteSink = new DanteSink({
            wsUrl: wsUrl
        })

        const dantePlayerSink = new DantePlayerSink({
            numChannels,
            channels:[]
            //channels:[ 1, 2 ]
        })

        // const logSink = new LogSink({name: 'receiver-dante'})

        const tee = moqtSrc.pipeThrough(_danteJitterBuffer.current).tee()
        tee[0].pipeTo(danteSink)
        if( false ) tee[1].pipeTo(dantePlayerSink)
    }, [ wsUrl, onData, numChannels, minJitterBufferMs ])

    const _startVideoPipeline = useCallback( () => {
        if( !_canvas.current ) return

        const moqtSrc = new MoqtSrc({
            moqt: _moqt.current,
            type: 'videochunk',
            onMetrics: setVideoMetrics
        })
        _videoMoqtSrc.current = moqtSrc

        const onResolutionChanged = ( videoResolution:VideoResolution ) => {
            setVideoResolution( videoResolution )
        }

        _videoJitterBuffer.current = new JitterbufferElem({ isVideo: true, onMetricsReport: setJbVideoMetrics })

        const initMinJitterBufferMs = minJitterBufferMs || 250
        _videoJitterBuffer.current.minJitterBufferMs = initMinJitterBufferMs
        setMinJitterBufferMs( initMinJitterBufferMs )

        const videoDecoder = new VideoDecoderElem()
        const videoRenderer = new VideoRendererSink({ canvas: _canvas.current, onResolutionChanged })

        moqtSrc
            .pipeThrough(_videoJitterBuffer.current)
            .pipeThrough(videoDecoder)
            .pipeTo(videoRenderer)
    }, [ minJitterBufferMs])

    const _startAudioPipeline = useCallback( () => {
        if( !_canvas.current ) return

        const moqtSrc = new MoqtSrc({
            moqt: _moqt.current,
            type: 'data',
            kind: 'audio',
            onMetrics: setAudioMetrics
        })
        _audioMoqtSrc.current = moqtSrc

        const dantePlayerSink = new DantePlayerSink({
            numChannels: 2,
            channels:[0, 1],
            sinkId: _audioSinkId
            //channels:[ 1, 2 ]
        })

        _audioJitterBuffer.current = new JitterbufferElem({ isVideo: false, onMetricsReport: setJbAudioMetrics })

        const initMinJitterBufferMs = minJitterBufferMs || 250
        _audioJitterBuffer.current.minJitterBufferMs = initMinJitterBufferMs
        setMinJitterBufferMs( initMinJitterBufferMs )

        const logSink = new LogSink({ name: 'receiver-audio' })

        const tee = moqtSrc
            .pipeThrough(_audioJitterBuffer.current)
            .tee()

        tee[0].pipeTo( dantePlayerSink)
        tee[1].pipeTo(logSink)
    }, [ _audioSinkId, minJitterBufferMs ])


    const _connect = useCallback( () => {
        if(_moqt.current ) return

        setErrorMessage('')

        _moqt.current = new Moqt()
        _moqt.current.connect({ endpoint })
            .then( async () => {
                // to avoid LINT error.
                if( !_moqt.current ) return

                console.log( 'connected to MOQT:%o', _moqTracks )

                const ret = await _moqt.current.createSubscriber( _moqTracks )
                console.log('succeeded to create subscriber:%o', ret)

                _startDantePipeline()
                _startVideoPipeline()
                if( false )_startAudioPipeline()

                setConnected( true )
            })
            .catch( (err:Error) => {
                setErrorMessage( err.message )
                setConnected( false )
            })

        _moqt.current.addListener('latencyMs', ( mesg:MessageData ) => {
            /* noop */
        })

        _moqt.current.addListener('error', ( mesg:string ) => {
            setErrorMessage( mesg )
        })

        _moqt.current.addListener('closed', () => {
            setConnected( false )
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        })
    }, [ endpoint, _moqTracks, _startDantePipeline, _startVideoPipeline, _startAudioPipeline ])

    const _disconnect = useCallback( async () => {
        if( _reqId.current ) {
            cancelAnimationFrame( _reqId.current )
            _reqId.current = undefined
        }
        if( _moqt.current ) {
            await _moqt.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }

        setConnected( false )
    }, [])

    return (
        <div className="DanteReceiver">
            <h3>Receiver</h3>
            <Row gutter={16}>
                <Col span={12}>
                    <div className='container'>
                        <div className='wrapper'>
                            <canvas ref={_canvas} />
                        </div>
                        <div className='wrapper'>
                            <span className='billboard'>
                                {_videoResolution.width} x {_videoResolution.height}
                            </span>
                            <br/>
                            <span className='billboard'>
                                Video : traffic: {Math.floor(_videoMetrics.bps / 10_000) / 100} Mbps, frame rate: {_videoMetrics.frameRate}
                            </span>
                            <br />
                            <span className='billboard'>
                                Audio : traffic: {Math.floor(_audioMetrics.bps / 10_000) / 100} Mbps, frame rate: {_audioMetrics.sampleRate}
                            </span>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    { !_connected && false && (
                        <DeviceSelector kind='audiooutput' onChangeDeviceId={setAudioSinkId} />
                    )}
                    <Row gutter={16}>
                        <Col span={8}>
                            <Button type='primary'
                                onClick={ !_connected ? _connect : _disconnect }
                                danger={ _connected }
                            >
                                { !_connected ? 'connect' : 'disconnect' }
                            </Button>
                            <div>
                                state: {_connected ? 'connected' : 'disconnected'}<br />
                            </div>
                            {_connected && _minJitterBufferMs > 0 && (
                                <div style={{ width: '90%', padding: '0 1em' }}>
                                    <div>
                                        JitterBuffer: {_minJitterBufferMs} [msec]
                                    </div>
                                    <Slider value={_minJitterBufferMs} min={1} max={1000} onChange={setMinJitterBufferMs} />
                                    <div>
                                        [Emulation] moqt loss rate: {Math.floor(_lossRate * 100)} %
                                    </div>
                                    <Slider value={_lossRate} min={0} max={1} step={0.01} onChange={setLossRate} />
                                </div>
                            )}
                        </Col>
                        <Col span={8}>
                            <h4>Dante metrics</h4>
                            <div>
                                <div>traffic: { Math.floor(_danteMetrics.bps / 10_000) / 100 } Mbps</div>
                                <div>sample rate: {_danteMetrics.sampleRate}</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <h4>JitterBuffer metrics</h4>
                            <div>video lost: {_jbVideoMetrics.lostNumSeqs}</div>
                            <div>audio lost: {_jbAudioMetrics.lostNumSeqs}</div>
                            <div>dante lost: {_jbDanteMetrics.lostNumSeqs}</div>
                        </Col>
                    </Row>
                    <Card title="Error Log">
                        Log: {!!_errMessage ? `Error::${_errMessage}` : ''}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}