import { useState, useEffect, useRef } from 'react';

import SyncVideoReceiver from '../components/sync-video-receiver';
import { endpoint } from '../config';

import '../App.css';

export default function Receiver() {
  const [ _namespace, setNamespace ] = useState<string>('')

  const _video = useRef<HTMLVideoElement>(null)
  const _loaded = useRef<boolean>(false)

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const ns = search.get('ns')
    if (ns) {
      setNamespace(ns)
    }
  }, [])

  useEffect(() => {
    (async () => {
    if( _loaded.current ) return;
    _loaded.current = true;

      if( _video.current ) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { width: 1920, height: 1080, facingMode: 'environment' }, audio: false });
        _video.current.srcObject = stream;

        _video.current.onloadedmetadata = () => {
          if( _video.current ) {
            _video.current.play();
          }
        }
      }
    })();
  }, [])



  return (
    <div>
          <div style={{ position: 'absolute', left: 0, top: 0, width: '100vw', height: '100svh'}}>
            <div style={{position: 'absolute', left: 0, top: 0, width: '100vw', height: '100svh'}}>
              <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} ref={_video} playsInline muted></video>
            </div>
              <SyncVideoReceiver xrmode={true} endpoint={endpoint} namespace={_namespace} videoTrackName="video" audioTrackName="audio" />
          </div>
    </div>
  );
}
