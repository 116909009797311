import EventEmitter from "events"

declare var VideoFrame:any

export default class VideoCapture extends EventEmitter {
    _reqId: number|null
    _canvas: HTMLCanvasElement
    _ctx:CanvasRenderingContext2D|null
    _counter: number
    _numCapturedFrames: number
    _frameRate: number
    _lastCaptured: number

    constructor() {
        super()
        this._reqId = null
        this._frameRate = 30
        this._lastCaptured = 0

        this._counter = 0
        this._numCapturedFrames = 0

        this._canvas = document.createElement('canvas')
        this._ctx = this._canvas.getContext('2d')
    }

    get counter() {
        return this._counter
    }
    
    get numCapturedFrames() {
        return this._numCapturedFrames
    }

    start( videoEl:HTMLVideoElement  ) {
        const width = videoEl.videoWidth
        const height = videoEl.videoHeight

        this._canvas.width  = width
        this._canvas.height = height

        // frameRate から得られる duration より 5msec 短くする
        const targetDuration = ( 1_000 / this._frameRate ) - 5

        const cropW = width
        const cropH = height 

        const loop = () => {
            const now = Date.now()

            if( this._ctx && ( now - this._lastCaptured ) > targetDuration ) {
                this._ctx.drawImage( videoEl, 0, 0, cropW, cropH, 0, 0, cropW, cropH )
                const vFrame = new VideoFrame( this._canvas, { timestamp: Date.now()} )
                this._numCapturedFrames++
                this._lastCaptured = now
                this.emit( 'vFrame', vFrame )

                // to clean up VideoFrame
                setTimeout(() => {
                    if( !!vFrame.format ) {
                        vFrame.close()
                    }
                }, 1_000 )
            }
            this._reqId = requestAnimationFrame( loop )

        }
        this._lastCaptured = Date.now()
        loop()
    }

    stop() {
        if( this._reqId ) {
            cancelAnimationFrame( this._reqId )
            this._reqId = null
        }
    }
}