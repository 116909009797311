import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, Col, Row, Slider, Switch } from 'antd'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import Moqt from '../libs/moqt'

import { TypeMetrics, postMetrics } from '../libs/utils/utils'

import MoqtSrc from '../libs/stream-apis/moqt-src'
import JitterbufferElem from '../libs/stream-apis/jitterbuffer-elem'
import DanteSink from '../libs/stream-apis/dante-sink'
import VideoDecoderElem from '../libs/stream-apis/video-decoder-elem'
import VideoRendererSink from '../libs/stream-apis/video-renderer-sink'

import { MoqtTracks, MessageData } from '../types/moqt'

import './main-receiver.css'

type VideoResolution = {
    width:number,
    height:number
}

interface Props  {
    endpoint:string,
    namespace: string,
    wsUrl:string,
    onData?:Function
}

const moqVideoTracksTmpl: MoqtTracks = {
    video: {
        id: -1,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        name: "video",
        packagerType: 'loc',
        mediaType: "video",
        authInfo: "secret"
    }
}
const moqDanteTracksTmpl: MoqtTracks = {
    data: {
        id: -1, // will be set after SUBSCRIBE procedure
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        mediaType: "data",
        name: "dante",
        packagerType: 'raw',
        authInfo: "secret"
    },
}



export default function MainReceiver(props:Props) {
    const { endpoint, namespace, wsUrl, onData } = props

    const [ _connected, setConnected ] = useState<boolean>( false )
    const [ _moqVideoTracks, setMoqVideoTracks ] = useState<MoqtTracks>( moqVideoTracksTmpl )
    const [ _moqDanteTracks, setMoqDanteTracks ] = useState<MoqtTracks>( moqDanteTracksTmpl )
    const [ _minJitterBufferMs, setMinJitterBufferMs ] = useState<number>( 250 )
    const [ _errMessage, setErrorMessage ] = useState<string>('')
    const [ _videoResolution, setVideoResolution ] = useState<VideoResolution>({ width: 0, height: 0})
    const [ _enableAutoFlushBuffer, setEnableAutoFlushBuffer ] = useState<boolean>( false )
    const [ _disableConcealment, setDisableConcealment ] = useState<boolean>( true )

    const [ _videoMetrics, setVideoMetrics ] = useState<{ bps:number, frameRate:number}>({
        bps: 0, frameRate: 0
    })
    const [ _danteMetrics, setDanteMetrics ] = useState<{ bps:number, sampleRate:number}>({
        bps: 0, sampleRate: 0
    })

    const [ _jbVideoMetrics, setJbVideoMetrics ] = useState<{ lostNumSeqs:number}>({
        lostNumSeqs: 0
    })
    const [ _jbDanteMetrics, setJbDanteMetrics ] = useState<{ lostNumSeqs:number}>({
        lostNumSeqs: 0
    })

    const [ _lossRate, setLossRate ] = useState<number>(0.0)
    const [ _isFullScreen, setIsFullScreen ] = useState<boolean>( false )

    const _moqtVideo = useRef<Moqt>()
    const _moqtDante = useRef<Moqt>()
    const _videoMoqtSrc = useRef<MoqtSrc>()
    const _danteMoqtSrc = useRef<MoqtSrc>()
    const _danteJitterBuffer = useRef<JitterbufferElem>()
    const _videoJitterBuffer = useRef<JitterbufferElem>()
    const _danteSink = useRef<DanteSink>()
    const _reqId = useRef<number>()
    const _canvas = useRef<HTMLCanvasElement|null>(null)
    const _receiverViewer = useRef<HTMLDivElement|null>(null)
    const _autoFlushTimer = useRef<any>()

    const _metrics = useRef<TypeMetrics>()
    const _moqtConnected = useRef<boolean>( false )

    // jitter buffer msec の値を url parameter で制御
    useEffect(() => {
        const url = new URL(window.location.href)
        const minJitterBufferMs = url.searchParams.get('delay')
        if( minJitterBufferMs ) {
            setMinJitterBufferMs( parseInt(minJitterBufferMs) )
        }

        const disableConcealment = url.searchParams.get('disableConcealment')
        if( disableConcealment === 'false' ) {
            setDisableConcealment( false )
        }
    }, [])
 

    // metrics の初期化
    useEffect(() => {
        _metrics.current = {
            type: 'main-receiver',
            video: { w: 0, h: 0, bps: 0, fps: 0, lost: 0 },
            audio: { bps: 0, fps: 0, lost: 0 },
            dante: { bps: 0, fps: 0, lost: 0 }
        }
    }, [])

    useEffect(() => {
        if( _metrics.current ) {
            _metrics.current.video.w = _videoResolution.width
            _metrics.current.video.h = _videoResolution.height
            _metrics.current.video.bps = _videoMetrics.bps
            _metrics.current.video.fps = _videoMetrics.frameRate
            _metrics.current.video.lost = _jbVideoMetrics.lostNumSeqs
            _metrics.current.dante.bps = _danteMetrics.bps
            _metrics.current.dante.fps = _danteMetrics.sampleRate
            _metrics.current.dante.lost = _jbDanteMetrics.lostNumSeqs
        }
        _moqtConnected.current = _connected
    }, [ 
        _videoResolution.width, 
        _videoResolution.height, 
        _videoMetrics.bps, 
        _videoMetrics.frameRate, 
        _danteMetrics.bps,
        _danteMetrics.sampleRate,
        _jbVideoMetrics.lostNumSeqs, 
        _jbDanteMetrics.lostNumSeqs,
        _connected
    ])

    useEffect(() => {
        let timer:any

        timer = setInterval(() => {
            if( _metrics.current && _moqtConnected.current ) {
                postMetrics(_metrics.current)
            }
        }, 25_000 )

        document.onfullscreenchange = () => {
            setIsFullScreen( !!document.fullscreenElement )
        }

        return function clean() {
            if( timer ) {
                clearInterval( timer )
                timer = undefined
            }
        }
    }, [])

    useEffect(() => {
        setMoqVideoTracks(
            Object.entries(moqVideoTracksTmpl).map( ( [ name, moqTrack ] ) => (
                [ name, { ...moqTrack, namespace: `${namespace}-video` } ]
            )).reduce( (acc, [ name, moqTrack ]) => (
                //@ts-ignore
                { ...acc, [name]: moqTrack }
            ), {})
        )
        setMoqDanteTracks(
            Object.entries(moqDanteTracksTmpl).map( ( [ name, moqTrack ] ) => (
                [ name, { ...moqTrack, namespace: `${namespace}-dante` } ]
            )).reduce( (acc, [ name, moqTrack ]) => (
                //@ts-ignore
                { ...acc, [name]: moqTrack }
            ), {})
        )


        return function() {
            if( _moqtVideo.current ) {
                _moqtVideo.current.disconnect()
                _moqtVideo.current.destroy()
                _moqtVideo.current = undefined
            }

            if( _moqtDante.current ) {
                _moqtDante.current.disconnect()
                _moqtDante.current.destroy()
                _moqtDante.current = undefined
            }
        }
    }, [ namespace ])

    // スライダーの値が変更されたら、JitterBufferの値を変更する
    useEffect(() => {
        if( _minJitterBufferMs > 0 ) {
            if( _danteJitterBuffer.current ) {
                _danteJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs
            }
            if( _videoJitterBuffer.current ) {
                _videoJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs
            }
        }
    }, [ _minJitterBufferMs ])

    // [エミュレーション] データロス率が変更されたら、値を変更する
    useEffect(() => {
        if( _videoMoqtSrc.current ) {
            _videoMoqtSrc.current._lossRate = _lossRate
        }
        if( _danteMoqtSrc.current ) {
            _danteMoqtSrc.current._lossRate = _lossRate
        }
    }, [ _lossRate ])

    const _startDantePipeline = useCallback( () => {
        const moqtSrc = new MoqtSrc({
            moqt: _moqtDante.current,
            type: 'data',
            kind: 'dante',
            onData: onData,
            onMetrics: setDanteMetrics
        })
        _danteMoqtSrc.current = moqtSrc

        _danteJitterBuffer.current = new JitterbufferElem({ concealment:!_disableConcealment, isVideo: false, onMetricsReport: setJbDanteMetrics })
        _danteJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs

        const danteSink = new DanteSink({
            wsUrl: wsUrl
        })

        const tee = moqtSrc.pipeThrough(_danteJitterBuffer.current).tee()
        tee[0].pipeTo(danteSink)

        // `f` キーが押されたら、 _danteJitterBuffer の flush を実行し、合わせて　danteSink の reconnectWs を実行する
        window.addEventListener('keydown', (ev:KeyboardEvent) => {
            const key = ev.key
            if( key.toLowerCase() === 'f' ) {
                if( _danteJitterBuffer.current ) _danteJitterBuffer.current.flush()
                danteSink.reconnectWs()
            }
        }) 

        _danteSink.current = danteSink
    }, [ wsUrl, onData, _minJitterBufferMs, _disableConcealment ])

    const _startVideoPipeline = useCallback( () => {
        if( !_canvas.current ) return

        const moqtSrc = new MoqtSrc({
            moqt: _moqtVideo.current,
            type: 'videochunk',
            onMetrics: setVideoMetrics
        })
        _videoMoqtSrc.current = moqtSrc

        const onResolutionChanged = ( videoResolution:VideoResolution ) => {
            setVideoResolution( videoResolution )
        }

        _videoJitterBuffer.current = new JitterbufferElem({ isVideo: true, onMetricsReport: setJbVideoMetrics })
        _videoJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs

        const videoDecoder = new VideoDecoderElem()
        const videoRenderer = new VideoRendererSink({ canvas: _canvas.current, onResolutionChanged })

        moqtSrc
            .pipeThrough(_videoJitterBuffer.current)
            .pipeThrough(videoDecoder)
            .pipeTo(videoRenderer)
    }, [ _minJitterBufferMs])

    const _connect = useCallback( () => {
        if(_moqtVideo.current || _moqtDante.current ) return

        setErrorMessage('')

        _moqtVideo.current = new Moqt()
        _moqtVideo.current.connect({ endpoint })
            .then( async () => {
                // to avoid LINT error.
                if( !_moqtVideo.current ) return

                console.log( 'connected to moqtVideo' )

                const ret = await _moqtVideo.current.createSubscriber( _moqVideoTracks )
                console.log('succeeded to create subscriber:%o', ret)

                _startVideoPipeline()

                setConnected( true )
            })
            .catch( (err:Error) => {
                setErrorMessage( err.message )
                setConnected( false )
            })

        _moqtVideo.current.addListener('latencyMs', ( mesg:MessageData ) => {
            /* noop */
        })

        _moqtVideo.current.addListener('error', ( mesg:string ) => {
            setErrorMessage( mesg )
        })

        _moqtVideo.current.addListener('closed', () => {
            setConnected( false )
            if( _moqtVideo.current ) {
                _moqtVideo.current.destroy()
                _moqtVideo.current = undefined
            }
        })

        _moqtDante.current = new Moqt()
        _moqtDante.current.connect({ endpoint })
            .then( async () => {
                // to avoid LINT error.
                if( !_moqtDante.current ) return

                console.log( 'connected to moqtVideo' )

                const ret = await _moqtDante.current.createSubscriber( _moqDanteTracks )
                console.log('succeeded to create subscriber:%o', ret)

                _startDantePipeline()

                setConnected( true )
            })
            .catch( (err:Error) => {
                setErrorMessage( err.message )
                setConnected( false )
            })

        _moqtDante.current.addListener('latencyMs', ( mesg:MessageData ) => {
            /* noop */
        })

        _moqtDante.current.addListener('error', ( mesg:string ) => {
            setErrorMessage( mesg )
        })

        _moqtDante.current.addListener('closed', () => {
            setConnected( false )
            if( _moqtDante.current ) {
                _moqtDante.current.destroy()
                _moqtDante.current = undefined
            }
        })
 
    }, [ endpoint, _moqVideoTracks, _moqDanteTracks, _startDantePipeline, _startVideoPipeline ])

    const _disconnect = useCallback( async () => {
        if( _reqId.current ) {
            cancelAnimationFrame( _reqId.current )
            _reqId.current = undefined
        }
        if( _moqtVideo.current ) {
            await _moqtVideo.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqtVideo.current ) {
                _moqtVideo.current.destroy()
                _moqtVideo.current = undefined
            }
        }
        if( _moqtDante.current ) {
            await _moqtDante.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqtDante.current ) {
                _moqtDante.current.destroy()
                _moqtDante.current = undefined
            }
        }


        setConnected( false )
    }, [])


    useEffect(() => {
        if( _autoFlushTimer.current ) {
            clearTimeout( _autoFlushTimer.current )
            _autoFlushTimer.current = undefined
        }
        if( _enableAutoFlushBuffer ) {
            // 60秒ごとに、DanteJitterBufferをflushし、DanteSinkを再接続する
            _autoFlushTimer.current = setInterval(() => {
                if( _danteJitterBuffer.current ) _danteJitterBuffer.current.flush()
                if( _danteSink.current ) _danteSink.current.reconnectWs()
            }, 60_000)
        }
    }, [ _enableAutoFlushBuffer ])

    return (
        <div className="MainReceiver">
            <h3>Main Receiver</h3>
            <Row gutter={16}>
                <Col span={12}>
                    <div className='container' ref={_receiverViewer}>
                        <div className='wrapper'>
                            <canvas ref={_canvas} />
                        </div>
                        <div className='wrapper'>
                            <span className='billboard'>
                                {_videoResolution.width} x {_videoResolution.height}
                            </span>
                            <br/>
                            <span className='billboard'>
                                Video : traffic: {Math.floor(_videoMetrics.bps / 10_000) / 100} Mbps, frame rate: {_videoMetrics.frameRate}
                            </span>
                            <br />
                            <div className="fullscreen-btn">
                                <Button 
                                    onClick={() => {
                                        if( !document.fullscreenElement ) {
                                            _receiverViewer.current?.requestFullscreen()
                                        } else {
                                            document.exitFullscreen()
                                        }
                                    }}
                                    type="text"
                                    size="large"
                                >{ _isFullScreen ? <FullscreenExitOutlined style={{color:'white'}} /> : <FullscreenOutlined style={{color:'white'}} /> }</Button>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    {_connected && (
                        <div>
                            auto flush dante buffer every 1 minutes : <Switch value={_enableAutoFlushBuffer} onChange={ (e) => {
                                setEnableAutoFlushBuffer( e)
                            }} />
                            <Alert showIcon message={<span>Press '<strong>f</strong>' key to flush Dante buffer</span>} type="info" />
                            <div>minJitterBufferMs: {_minJitterBufferMs}</div>
                        </div>
                    )}
                    <Row gutter={16}>
                        <Col span={8}>
                            <Button type='primary'
                                onClick={ !_connected ? _connect : _disconnect }
                                danger={ _connected }
                                disabled={ _connected}
                            >
                                { !_connected ? 'connect' : 'disconnect' }
                            </Button>
                            <div>
                                state: {_connected ? 'connected' : 'disconnected'}<br />
                            </div>
                            {false && _connected && _minJitterBufferMs > 0 && (
                                <div style={{ width: '90%', padding: '0 1em' }}>
                                    <div>
                                        JitterBuffer: {_minJitterBufferMs} [msec]
                                    </div>
                                    <Slider value={_minJitterBufferMs} min={1} max={1000} onChange={setMinJitterBufferMs} />
                                    <div>
                                        [Emulation] moqt loss rate: {Math.floor(_lossRate * 100)} %
                                    </div>
                                    <Slider value={_lossRate} min={0} max={1} step={0.01} onChange={setLossRate} />
                                </div>
                            )}
                        </Col>
                        <Col span={8}>
                            <h4>Dante metrics</h4>
                            <div>
                                <div>traffic: { Math.floor(_danteMetrics.bps / 10_000) / 100 } Mbps</div>
                                <div>sample rate: {_danteMetrics.sampleRate}</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <h4>JitterBuffer metrics</h4>
                            <div>video lost: {_jbVideoMetrics.lostNumSeqs}</div>
                            <div>dante lost: {_jbDanteMetrics.lostNumSeqs}</div>
                        </Col>
                    </Row>
                    <Card title="Error Log">
                        Log: {!!_errMessage ? `Error::${_errMessage}` : ''}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}