import EventEmitter from "events"

export default class VDecoder extends EventEmitter {
    _worker: Worker
    _lastSeqId: number = -1

    constructor() {
        super()

        this._worker = new Worker( new URL('./worker', import.meta.url))
        this._addListeners()
    }

    destroy() {
        this._worker.terminate()
    }

    stop() {
        this._worker.postMessage({
                type: 'stop'
        })
    }

    decode( vChunk:any ) {
        const seqId = vChunk.metadata.seqId
        const isDisco = this._lastSeqId !== -1 && seqId !== this._lastSeqId + 1
        this._lastSeqId = seqId

        this._worker.postMessage({
            type: 'decode',
            metadata: vChunk.metadata,
            payload: vChunk.payload,
            isDisco
        })
    }
    _addListeners = () => {
        this._worker.addEventListener('message', ({ data }:{data:any}) => {
            switch( data?.type ) {
            case 'vFrame':
                if( data?.payload && data?.metadata ) {
                    this.emit(`vFrame`, { frameData: data.payload, metadata: data.metadatao } )
                }
                break
            case 'error':
                console.error('error:%o', data )
                this.emit('error', JSON.stringify( data ) || '' )

                break
            default:
                /* noop */
                break
            }
        })
    }
}