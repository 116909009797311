import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { getRadians } from '../libs/utils/utils';

//import { FaceLandmarker } from "@mediapipe/tasks-vision";
//import { patterns } from './face-landmarks-detector';

//@ts-ignore
import * as THREE from 'three/webgpu';
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
//@ts-ignore
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
//@ts-ignore
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { VRMLoaderPlugin, MToonMaterialLoaderPlugin, VRMUtils } from '@pixiv/three-vrm';
//@ts-ignore
import { MToonNodeMaterial } from '@pixiv/three-vrm/nodes';

import './vrm-renderer.css'

type Props = {
    xrmode?: boolean
}

const VrmRenderer = forwardRef(function (props: Props, ref) {
    const { xrmode } = props

    const _wrapperEl = useRef<HTMLDivElement>(null)
    const _videoEl = useRef<HTMLVideoElement>(null)

    const _currentVrm = useRef(null)
    const _lipsYDelta = useRef<number>(0)
    const _lipsXDelta = useRef<number>(0)
    const _eyeRightDelta = useRef<number>(0)
    const _eyeLeftDelta = useRef<number>(0)

    const _headXinclination = useRef<number>(0)
    const _headYinclination = useRef<number>(0)
    const _headZinclination = useRef<number>(0)

    const _leftShoulderZ = useRef<number>(0)
    const _leftShoulderY = useRef<number>(0)
    const _rightShoulderZ = useRef<number>(0)
    const _rightShoulderY = useRef<number>(0)
    const _leftUpArmInclinationZ = useRef<number>(0)
    const _leftDownArmInclinationZ = useRef<number>(0)
    const _rightUpArmInclinationZ = useRef<number>(0)
    const _rightDownArmInclinationZ = useRef<number>(0)

    //口の開け方の閾値
    /*
    const _lipsXCriMax = useRef<number>(0)  //Cri=criteria
    const _lipsXCriMin = useRef<number>(0)
    */

    const _loaded = useRef(false) // development 時に、useEffect が複数回呼ばれるのを防ぐためのフラグ

    // const _pattern = useMemo(() => {
    //     return patterns.find(p => p.kind === FaceLandmarker.FACE_LANDMARKS_LIPS)
    // }, [])


    useEffect(() => {
        if (!_wrapperEl.current) return
        if (_loaded.current) return

        _loaded.current = true

        const wrapper = _wrapperEl.current
        const w = wrapper.clientWidth, h = wrapper.clientHeight

        // renderer
        const renderer = new THREE.WebGPURenderer();
        renderer.setSize(w, h);
        renderer.setPixelRatio(window.devicePixelRatio);

        wrapper.appendChild(renderer.domElement);

        // camera
        const camera = new THREE.PerspectiveCamera(30.0, w / h, 0.1, 20.0);
        camera.position.set(0.0, 1.4, 1.0);
        //camera.position.set(0.0, 0.0, 0.0);

        window.addEventListener('resize', () => {
            const w = wrapper.clientWidth, h = wrapper.clientHeight
            camera.aspect = w / h;
			camera.updateProjectionMatrix();

			renderer.setSize( w, h );
        }, false)
 

        // camera controls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.screenSpacePanning = true;
        controls.target.set(0.0, 1.4, 0.0);
        //controls.target.set(0.0, 0.0, 0.0);
        controls.update();

        // scene
        const scene = new THREE.Scene();

        // light
        const light = new THREE.DirectionalLight(0xffffff, Math.PI);
        light.position.set(1.0, 1.0, 1.0).normalize();
        scene.add(light);

        // gltf and vrm
        const loader = new GLTFLoader();
        loader.crossOrigin = 'anonymous';

        loader.register((parser: any) => {
            // create a WebGPU compatible MToonMaterialLoaderPlugin
            const mtoonMaterialPlugin = new MToonMaterialLoaderPlugin(parser, {
                // set the material type to MToonNodeMaterial
                materialType: MToonNodeMaterial,
            });

            return new VRMLoaderPlugin(parser, {
                mtoonMaterialPlugin,
            });
        });

        function load(url: string) {
            loader.load(
                url,
                (gltf: any) => {
                    const vrm = gltf.userData.vrm;

                    // calling these functions greatly improves the performance
                    VRMUtils.removeUnnecessaryVertices(gltf.scene);
                    //@ts-ignore
                    VRMUtils.removeUnnecessaryJoints(gltf.scene, { sameBoneCounts: true });

                    if (_currentVrm.current) {
                        //@ts-ignore
                        scene.remove(_currentVrm.current.scene);
                        //@ts-ignore
                        VRMUtils.deepDispose(_currentVrm.current.scene);
                    }

                    // Disable frustum culling
                    vrm.scene.traverse((obj: object) => {
                        //@ts-ignore
                        obj.frustumCulled = false;
                    });

                    _currentVrm.current = vrm;
                    scene.add(vrm.scene);

                    // rotate if the VRM is VRM0.0
                    VRMUtils.rotateVRM0(vrm);
                    console.log(vrm);
                },

                //@ts-ignore
                (progress: object) => console.log('Loading model...', 100.0 * (progress.loaded / progress.total), '%'),

                (error: Error) => console.error(error)
            );
        }

        load('/models/Ailis_to_VRM_2.0.vrm');

        // // helpers
        const gridHelper = new THREE.GridHelper(10, 10);
        scene.add(gridHelper);

        const axesHelper = new THREE.AxesHelper(5);
        scene.add(axesHelper);

        if( false ) {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment', width: 1920, height: 1080 }, audio: false})
                .then( stream => {
                    if( !_videoEl.current ) return
                    const video = _videoEl.current
                    video.srcObject = stream
                    video.onloadedmetadata = () => {
                        video.play()
                        const texture = new THREE.VideoTexture( video );
                        texture.colorSpace = THREE.SRGBColorSpace;

                        const geometry = new THREE.PlaneGeometry(16, 9);
                        geometry.scale(0.5, 0.5, 0.5);

                        const material = new THREE.MeshBasicMaterial({ map: texture })
                        const mesh = new THREE.Mesh(geometry, material)
                        mesh.position.set(0, 0, -8.0)
                        scene.add(mesh)
                    }
                })
        }

        // // animate
        const clock = new THREE.Clock();
        clock.start();

        // ここから重要！！
        function animate() {
            if (_currentVrm.current) {
                // VRMExpressionManager の setValue で、表情を変更できる
                // 'aa' だと口をあーの形に、 'oh' だと口をおーの形に変更できる
                // 目は 'blink' でまばたきを表現できる　（左目だけであれば `blinkLeft' とか）
                // 詳細は、以下を確認
                // https://github.com/pixiv/three-vrm/blob/dev/packages/three-vrm-core/src/expressions/VRMExpressionManager.ts
                //
                // VRM の動かし方は、以下を参照
                // https://pixiv.github.io/three-vrm/packages/three-vrm/docs/classes/VRMCore.html
                // 身体を動かす場合は、humanoid プロパティを操作する（多分 Pose を変化させる感じ）
                //
                // VRM について type を load していないので、ts エラーはとりあえず @tts-ignore で無視している


                // まばたき
                //
                // とりあえず、sin波でまばたきを表現するときは、以下をコメントアウト
                //@ts-ignore
                //_currentVrm.current.expressionManager.setValue("blink", Math.sin(clock.getElapsedTime()));

                // 口の動き（あー）
                /*
                if(_lipsXDelta.current < 0.19){
                    //@ts-ignore
                    _currentVrm.current.expressionManager.setValue("oh", Math.min(_lipsYDelta.current * 10, 1) );
                }
                */
                /* 口の動き */
                if (_lipsXDelta.current > 0.25) {
                    //@ts-ignore
                    _currentVrm.current.expressionManager.setValue("ee", Math.min(_lipsYDelta.current * 10, 1));
                }
                else {
                    //@ts-ignore
                    _currentVrm.current.expressionManager.setValue("aa", Math.min(_lipsYDelta.current * 10, 0.9));
                }

                // /* 瞬き */
                //@ts-ignore
                _currentVrm.current.expressionManager.setValue("blinkLeft", 2 * Math.min(0.5 - _eyeLeftDelta.current * 10, 1));
                //@ts-ignore
                _currentVrm.current.expressionManager.setValue("blinkRight", 2 * Math.min(0.5 - _eyeRightDelta.current * 10, 1));

                // /* 視線(動作せず) */
                // //@ts-ignore
                // _currentVrm.current.expressionManager.setValue(' lookLeft ', 0);

                /* 顔の表情も変化できる(口の動きとの噛み合わせが若干悪いかもしれない)*/
                //@ts-ignore
                _currentVrm.current.expressionManager.setValue('normal', 1);

                
                /* 姿勢推定までいかなかったため、腕位置固定 */
                // pose detection の結果より、腕を動かす
                // todo - 手については、まずは簡単に、それぞれ 90 度回転
                // todo - pose detection　の z 軸を用い、x, y 軸方向の回転も本来は必要
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'rightShoulder' ).rotation.z 
                    = _rightShoulderZ.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'rightShoulder' ).rotation.y 
                    = _rightShoulderY.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'leftShoulder' ).rotation.z 
                    = _leftShoulderZ.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'leftShoulder' ).rotation.y 
                    = _leftShoulderY.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'leftUpperArm' ).rotation.z 
                    = _leftUpArmInclinationZ.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'leftLowerArm' ).rotation.z 
                    = _leftDownArmInclinationZ.current
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'leftHand' ).rotation.x
                    = Math.PI / 2
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'rightUpperArm' ).rotation.z 
                    = _rightUpArmInclinationZ.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'rightLowerArm' ).rotation.z 
                    = _rightDownArmInclinationZ.current
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'rightHand' ).rotation.x
                    = -Math.PI / 2

                /**
                 * 脊髄・胴・首に関する方向
                 * (x: 前後, y: ひねり, z: 左右)
                 */

                /* 脊髄動作の制御 */
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'spine' ).rotation.x = 0.2 * _headXinclination.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'spine' ).rotation.y = 0.4 * _headYinclination.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'spine' ).rotation.z = 0.2 * _headZinclination.current;

                /* 胴の動き制御 */
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'upperChest' ).rotation.y = 0.4 * _headYinclination.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'upperChest' ).rotation.x = 0.4 * _headXinclination.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'upperChest' ).rotation.z = 0.4 * _headZinclination.current;

                /* 首の動き制御 */
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'neck' ).rotation.z =  _headZinclination.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'neck' ).rotation.x =  _headXinclination.current;
                //@ts-ignore
                _currentVrm.current.humanoid.getNormalizedBoneNode( 'neck' ).rotation.y =  _headYinclination.current;

                // 描画を更新する
                //@ts-ignore
                _currentVrm.current.update(clock.getDelta());
            }

            renderer.render(scene, camera);
        }

        renderer.setAnimationLoop(animate);

        if( false ) {
            renderer.xr.enabled = true;
            document.body.appendChild(VRButton.createButton(renderer));
        }
    }, [ xrmode ])

    useImperativeHandle(ref, () => {
        return {
            updateFaceLandmarks: (landmarks: Array<Array<{ x: number, y: number, z: number, visibility: number }>>) => {
                if (landmarks.length === 0) return

                //@ts-ignore
                //const lipLandmarks = _pattern?.kind.map( ({ start, end }) => landmarks[0][start])   //startだけでいい

                //const ymin = lipLandmarks!.reduce( ( prev, curr ) => Math.min( prev, curr.y ), 1 )  //LIP情報のy最小値
                //const ymax = lipLandmarks!.reduce( ( prev, curr ) => Math.max( prev, curr.y ), 0 )  //LIP情報のy最大値

                /* 口に関する値 */
                //yのデルタ値を算出
                const ymax = landmarks[0][14]?.y
                const ymin = landmarks[0][13]?.y

                //xのデルタ値を算出
                const xmax = landmarks[0][409]?.x
                const xmin = landmarks[0][61]?.x

                /* 目に関する値 */
                //RIGHT EYE
                const lefteyeMax = landmarks[0][374]?.y
                const lefteyeMin = landmarks[0][386]?.y

                //LEFT EYE
                const righteyeMax = landmarks[0][144]?.y
                const righteyeMin = landmarks[0][159]?.y

                /* 頭の頂点と顎の点 */
                const headtopX = landmarks[0][10].x
                const headtopY = landmarks[0][10].y
                const headtopZ = landmarks[0][10].z 
                const headbuttomX = landmarks[0][152].x
                const headbuttomY = landmarks[0][152].y
                const headbuttomZ = landmarks[0][152].z

                /* 頭の左右端の点 */
                const headRightX = landmarks[0][323].x
                const headRightZ = landmarks[0][323].z
                const headLeftX = landmarks[0][93].x
                const headLeftZ = landmarks[0][93].z

                //デルタ値を取得
                if (ymax && ymin) {
                    _lipsYDelta.current = Math.max(ymax - ymin, 0) * 2
                }
                if (xmax && xmin) {
                    _lipsXDelta.current = Math.max(xmax - xmin, 0) * 2
                }
                if (lefteyeMax && righteyeMax) {
                    _eyeLeftDelta.current = Math.max(lefteyeMax - lefteyeMin, 0) * 2
                }
                if (righteyeMax && righteyeMin) {
                    _eyeRightDelta.current = Math.max(righteyeMax - righteyeMin, 0) * 2
                }

                //頭の傾き
                if (headtopX && headtopY && headbuttomX && headbuttomY) {
                    const _headXDelta = headtopX - headbuttomX
                    const _headYDelta = - (headtopY - headbuttomY)
                    const _headZDelta = headtopZ - headbuttomZ  

                    // 首の扇方向の動き
                    _headZinclination.current = _headXDelta / Math.sqrt(_headXDelta ** 2 + _headYDelta ** 2) //cosの値を取ってみたらうまくいったみたい（人間の可動域が-1<θ<1のため）

                    // 首の前後動作
                    _headXinclination.current = _headZDelta / _headYDelta //tanの値を取ってあげた
                }

                // 首の左右動作
                if (headRightX && headRightZ && headLeftX && headLeftZ) {
                    const _sideXDelta = headRightX - headLeftX
                    const _sideZDelta = headRightZ - headLeftZ

                    _headYinclination.current = _sideZDelta / _sideXDelta
                }
            },
            // moq-surveyではpose-detectionは未実装
            updatePoseLandmarks: (landmarks: Array<Array<{ x: number, y: number, z: number, visibility: number }>>) => {
                if (landmarks.length === 0) return
                /**
                 * poseのlandmarkが更新された時にする処理
                 */

                // pose detection の結果は以下のような構造になっている
                // https://ai.google.dev/edge/mediapipe/solutions/vision/pose_landmarker?hl=ja

                const theta11 = getRadians(landmarks[0], 11, 13)
                const theta13 = getRadians(landmarks[0], 13, 15)
                const theta12_11 = getRadians(landmarks[0], 12, 11)

                _leftShoulderZ.current = theta12_11.z
                _leftShoulderY.current = theta12_11.y
                _leftUpArmInclinationZ.current = theta11.z - theta12_11.z
                _leftDownArmInclinationZ.current = ( theta13.z - theta11.z ) > 0 ? 0 : theta13.z - theta11.z   // - theta12_11.z

                const theta12 = getRadians(landmarks[0], 12, 14)
                const theta14 = getRadians(landmarks[0], 14, 16)
                const theta11_12 = getRadians(landmarks[0], 11, 12)

                _rightShoulderZ.current = theta11_12.z + Math.PI
                _rightShoulderY.current = theta11_12.y + Math.PI
                _rightUpArmInclinationZ.current = theta12.z - theta11_12.z
                //_rightDownArmInclinationZ.current = theta14.z - theta12.z - theta11_12.z + Math.PI
                _rightDownArmInclinationZ.current = theta14.z - theta12.z
                //_rightDownArmInclinationZ.current = ( theta14.z - theta12.z ) > ? 0 : theta14.z - theta12.z
            }
        }
    }, [])


    return (
        <div className='VRM'>
            <div className={ xrmode ? "xr-wrapper" : "wrapper"} ref={_wrapperEl}></div>
            { xrmode ? (
                <div className="xr-telop">
                    <h4>Fan creation of Ailis by IZUMO.com</h4>
                </div>
            ):(
            <h4>Fan creation of Ailis by IZUMO.com</h4>
            )}
            <video ref={_videoEl} style={{position: 'absolute', visibility: 'hidden', width: 0, height: 0, overflow: 'hidden'}} />
        </div>
    )
})

export default VrmRenderer