import { useCallback, useEffect, useRef, useState } from 'react'
import Moqt from '../libs/moqt'
import VDecoder from '../libs/video-decoder'
import ADecoder from '../libs/wav-decoder'
import VideoRenderer from './video-renderer'
import AudioPlayer from '../libs/audio-player'
import SyncJitterBuffer from '../libs/sync-jitter-buffer'
import Synthesizer from '../libs/synthesizer'
import VrmRenderer from './vrm-renderer'
import MidiEffector from './midi-effector'
// import { noteToNum } from '../libs/midi-notes'

import FaceLandmarksDetector from './face-landmarks-detector'

import { MoqtTracks, MessageData } from '../types/moqt'

import './sync-video-receiver.css'

interface Props  {
    endpoint:String,
    namespace: String,
    videoTrackName:String,
    audioTrackName:String,
    xrmode?:boolean,
    wsReceiver?:string
}

const moqTracks: MoqtTracks = {
    video: {
        id: -1,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "vc",
        name: "",
        packagerType: 'loc',
        mediaType: "video",
        authInfo: "secret"
    },
    audio: {
        id: -1,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "vc",
        name: "",
        packagerType: 'loc',
        mediaType: "audio",
        authInfo: "secret"
    },
    data: {
        id: -1, // will be set after SUBSCRIBE procedure
        subscribeId: -1,
        trackAlias: -1,
        namespace: "vc",
        mediaType: "data",
        name: "foo",
        packagerType: 'raw',
        authInfo: "secret"
    }
}

class CaptureClkmsManager {
    _map: Map<number, number>

    constructor() {
        this._map = new Map()
    }

    setCaptureClkms( chunkTimestamp:number, captureClkms:number ) {
        this._map.set( chunkTimestamp, captureClkms )
    }

    getCaptureClkms( chunkTimestamp:number, flagRemove:boolean = true ) {
        const ret = this._map.get( chunkTimestamp )

        if( ret && flagRemove ) {
            this._map.delete( chunkTimestamp )
        }
        return ret
    }
}

type typeData = {
    timestamp:number,
    payload: any,
    delay?: number
}
class DataSyncManager {
    _buffer: Array<typeData>

    constructor() {
        this._buffer = []
    }

    setData( data:typeData ) {
        this._buffer.push( data )
    }

    getData( timestamp:number, duration:number ):Array<typeData> {
        const ret = this._buffer.filter( ( item:typeData ) => (
            item.timestamp >= timestamp && item.timestamp < ( timestamp + duration )
        )).map( item => ({ ...item, delay: item.timestamp - timestamp }))
        this._buffer = this._buffer.filter( ( item:typeData ) => (
            item.timestamp >= timestamp
        ))

        return ret
    }
}


export default function SyncVideoReceiver(props:Props) {
    const { endpoint, namespace, videoTrackName, audioTrackName, xrmode, wsReceiver } = props

    const [ _connected, setConnected ] = useState<boolean>( false )
    const [ _minJitterBufferMs, setMinJitterBufferMs ] = useState<number>( -1 )
    const [ _drawFaceLandmarks, setDrawFaceLandmarks ] = useState<boolean>( false )
    const [ _drawAvatar, setDrawAvatar ] = useState<boolean>( false )
    const [ _drawMidi, setDrawMidi ] = useState<boolean>( false )
    const [ _errMessage, setErrorMessage ] = useState<string>('')

    const [ _recvDatas, setRecvDatas ] = useState<Array<string>>([])

    const [ _g2gDelay, setG2GDelay ] = useState<number>( 0 )
    const [ _audioG2gDelay, setAudioG2GDelay ] = useState<number>( 0 )

    const _moqt = useRef<Moqt>()
    const _vDecoder = useRef<VDecoder>()
    const _aDecoder = useRef<ADecoder>()
    const _renderer = useRef<typeof VideoRenderer>(null)
    const _audioPlayer = useRef<AudioPlayer>()
    const _videoJitterBuffer = useRef<SyncJitterBuffer>()
    const _audioJitterBuffer = useRef<SyncJitterBuffer>()
    const _synthesizer = useRef<Synthesizer>()

    const _vrmRenderer = useRef<typeof VrmRenderer>()
    const _midiEffector = useRef<typeof MidiEffector>()

    const _captureClkmsManager = useRef<CaptureClkmsManager>()
    const _audioCaptureClkmsManager = useRef<CaptureClkmsManager>()
    const _midiSyncManager = useRef<DataSyncManager>()
    const _faceLandmarksSyncManager = useRef<DataSyncManager>()
    const _poseLandmarksSyncManager = useRef<DataSyncManager>()
    const _reqId = useRef<number>()
    const _loaded = useRef<boolean>( false )
    const _faceLandmarksDetector = useRef<typeof FaceLandmarksDetector>()
    const _syncMode = useRef<string>('video')
    const [ _syncModeValue, setSyncModeValue ] = useState<string>('video')

    const _setupRefs = useCallback(() => {
        _captureClkmsManager.current = new CaptureClkmsManager()
        _audioCaptureClkmsManager.current = new CaptureClkmsManager()
        _audioPlayer.current = new AudioPlayer({})
        _audioPlayer.current.start()

        _midiSyncManager.current = new DataSyncManager()
        _faceLandmarksSyncManager.current = new DataSyncManager()
        _poseLandmarksSyncManager.current = new DataSyncManager()
    }, [])

    useEffect(() => {
        if( !!xrmode) {
            setDrawAvatar( true )
            setDrawFaceLandmarks( false )
            setDrawMidi( false )
        }
    }, [xrmode])

    useEffect(() => {
        if( _loaded.current ) return

        _loaded.current = true
        _videoJitterBuffer.current = new SyncJitterBuffer()
        _audioJitterBuffer.current = new SyncJitterBuffer()
        _videoJitterBuffer.current.minJitterBufferMs = 1;
        setMinJitterBufferMs( _videoJitterBuffer.current.minJitterBufferMs )

        _vDecoder.current = new VDecoder()
        _aDecoder.current = new ADecoder({ wsReceiver })

        return function() {
            if( _moqt.current ) {
                _moqt.current.disconnect()
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }
    }, [ wsReceiver ])

    useEffect(() => {
        if( !_videoJitterBuffer.current || !_audioJitterBuffer.current ) return

        // jitter buffer の値を変更する
        _videoJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs
        _audioJitterBuffer.current.minJitterBufferMs = _minJitterBufferMs

        // それぞれのjitterbuffer 内の既存データを消去する
        _videoJitterBuffer.current.flush()
        _audioJitterBuffer.current.flush()
    }, [ _minJitterBufferMs ])

    const _startSynthesizer = useCallback(() => {
        if( !_synthesizer.current ) {
            _synthesizer.current = new Synthesizer()
            _synthesizer.current.setFrequency( 440 )
            _synthesizer.current.start()
        }
    }, [])

    const _stopSynthesizer = useCallback(() => {
        if( _synthesizer.current ) {
            _synthesizer.current.stop()
            _synthesizer.current.stop()
        }
    }, [])

    const _connect = useCallback( (moqTracks:MoqtTracks) => {
        if(_moqt.current || !_videoJitterBuffer.current || !_audioJitterBuffer.current || !_vDecoder.current ) return

        setErrorMessage('')

        _moqt.current = new Moqt()
        _moqt.current.connect({ endpoint })
            .then( async () => {
                // to avoid LINT error.
                if( !_moqt.current ) return

                // @ts-ignore
                moqTracks.video.name = videoTrackName
                // @ts-ignore
                moqTracks.audio.name = audioTrackName

                // @ts-ignore
                moqTracks.video.namespace = namespace
                // @ts-ignore
                moqTracks.audio.namespace = namespace
                // @ts-ignore
                moqTracks.data.namespace = namespace

                const ret = await _moqt.current.createSubscriber( moqTracks )
                console.log('succeeded to create subscriber:%o', ret)

                _setupRefs()

                // to avoid LINT error.
                if( _vDecoder.current ) {
                    _vDecoder.current.addListener( 'vFrame', ( data:{frameData:any} ) => {
                        const { frameData } = data

                        // BEGIN:: 同期処理 ( 映像表示状態だと、 faceLandmarks の描画がずれるため、faceLandmarks についてはここで同期処理 )
                        if( _captureClkmsManager.current ) {
                            // キャプチャ時の　clkms を取得
                            const captureClkms = _captureClkmsManager.current.getCaptureClkms( frameData.timestamp )

                            if( captureClkms && _midiSyncManager.current && _faceLandmarksSyncManager.current && _poseLandmarksSyncManager.current ) {
                                // 以下の　Glass to Glass delay は、sender と　receiver が同一端末の時のみ有効 
                                setG2GDelay( Date.now() - captureClkms  )

                                if( _syncMode.current === 'video' ) {
                                    // 映像 と sync した(captureClkms から　duration までの間)データを取得する
                                    const faceLandmarksData = _faceLandmarksSyncManager
                                        .current.getData( captureClkms, frameData.duration )
                                    if( faceLandmarksData.length > 0 && _faceLandmarksDetector.current ) {
                                        //@ts-ignore
                                        _faceLandmarksDetector.current.drawFace( faceLandmarksData[0].payload ) //書き込んでいるだけ、faceLandmarksData[0].payload = 点群データ
                                        //@ts-ignore
                                        _vrmRenderer.current.updateFaceLandmarks(faceLandmarksData[0].payload)
                                    }

                                    const poseLandmarksData = _poseLandmarksSyncManager
                                        .current.getData( captureClkms, frameData.duration )
                                    if( poseLandmarksData.length > 0 && _faceLandmarksDetector.current ) {
                                        //@ts-ignore
                                        _faceLandmarksDetector.current.drawPose( poseLandmarksData[0].payload ) //書き込んでいるだけ、faceLandmarksData[0].payload = 点群データ
                                        //@ts-ignore
                                        _vrmRenderer.current.updatePoseLandmarks(poseLandmarksData[0].payload)
                                    }
 
                                    // @ts-ignore
                                    //const midiData = _midiSyncManager.current.getData( captureClkms, 100 ) // data.metadata.metadata.duration )

                                    //if( midiData.length > 0 ) {
                                    //    if( !_midiEffector.current ) return
                                    //    console.log( 'midiData:%o', midiData )
                                    //    for( const data of midiData ) {
                                    //        if( data.payload && data.payload.length === 3 && data.payload[0] === 144 ) {
                                    //            //@ts-ignore
                                    //            _midiEffector.current.addEffect( data.payload[1], data.delay )
                                    //        }
                                    //    }
                                    //}
                                }
                            }
                        }
                        // FINISH:: 同期処理

                        if( _renderer.current ) {
                            //@ts-ignore
                            _renderer.current.drawFrame( frameData )
                        }
                        setTimeout( () => { frameData.close() }, 500 )
                    })

                    _vDecoder.current.addListener( 'error', ( mesg:string ) => {
                        setErrorMessage( mesg )
                    })
                }
                if( _aDecoder.current ) {
                    _aDecoder.current.addListener( 'aFrame', ( data:{frameData:ArrayBuffer, metadata:any } ) => {
                        if( _audioPlayer.current ) {
                            const audioData = wsReceiver ? new Int16Array( data.frameData ) : new Float32Array( data.frameData )

                            //@ts-ignore
                            _audioPlayer.current.add( audioData, data.metadata.format )
                            // BEGIN:: 同期処理 ( midi-data についてはここで同期処理. faceLandmarks については、映像表示とずれるため、video と同期している )
                            if( _audioCaptureClkmsManager.current ) {
                                // キャプチャ時の　clkms を取得
                                // @ts-ignore
                                const captureClkms = data.metadata.metadata.captureClkms // _audioCaptureClkmsManager.current.getCaptureClkms( data.metadata.timestamp )

                                if( captureClkms && _midiSyncManager.current && _faceLandmarksSyncManager.current && _poseLandmarksSyncManager.current ) {
                                    // 以下の　Glass to Glass delay は、sender と　receiver が同一端末の時のみ有効 
                                    setAudioG2GDelay( Date.now() - captureClkms  )
                                    // console.log( 'audio captureClkms:%d, duration:%d', captureClkms, data.metadata.metadata.duration )

                                    if( _syncMode.current === 'audio' ) {
                                        // 映像 と sync した(captureClkms から　duration までの間)データを取得する
                                        // @ts-ignore
                                        const faceLandmarksData = _faceLandmarksSyncManager.current.getData( captureClkms, 16 ) // data.metadata.metadata.duration )
                                        if( faceLandmarksData.length > 0 && _faceLandmarksDetector.current ) {
                                            //@ts-ignore
                                            _faceLandmarksDetector.current.drawFace( faceLandmarksData[0].payload ) //書き込んでいるだけ、faceLandmarksData[0].payload = 点群データ
                                            //@ts-ignore
                                            _vrmRenderer.current.updateFaceLandmarks(faceLandmarksData[0].payload)
                                        }
                                    }
 
                                        // @ts-ignore
                                        const midiData = _midiSyncManager.current.getData( captureClkms, 16 ) // data.metadata.metadata.duration )

                                        if( midiData.length > 0 ) {
                                            if( !_midiEffector.current ) return
                                            console.log( 'midiData:%o', midiData )
                                            for( const data of midiData ) {
                                                if( data.payload && data.payload.length === 3 && data.payload[0] === 144 ) {
                                                    //@ts-ignore
                                                    _midiEffector.current.addEffect( data.payload[1], data.delay )
                                                }
                                            }
                                        }
                                }
                            }
                            // FINISH:: 同期処理


                            // console.log( 'audioData:%o, metadata: %o', audioData, data.metadata.metadata )
                        }
                    })
                }

                const startDecoding = () => {
                    if( _videoJitterBuffer.current && _captureClkmsManager.current && _vDecoder.current ) {
                        const datas = _videoJitterBuffer.current.getItem()
                        if( datas instanceof Array && datas.length > 0 ) {
                            for( const data of datas ) {
                                //@ts-ignore
                                _captureClkmsManager.current.setCaptureClkms( data.payload.timestamp, data.metadata.captureClkms )
                                _vDecoder.current.decode( data )
                            }
                        }
                    }
                    if( _audioJitterBuffer.current && _audioCaptureClkmsManager.current && _aDecoder.current ) {
                        const datas = _audioJitterBuffer.current.getItem()
                        if( datas instanceof Array && datas.length > 0 ) {
                            for( const data of datas ) {
                                //@ts-ignore
                                _audioCaptureClkmsManager.current.setCaptureClkms( data.payload.timestamp, data.metadata.captureClkms )
                                //@ts-ignore
                                data.metadata.timestamp = data.payload.timestamp
                                //@ts-ignore
                                data.metadata.duration = data.payload.duration
                                _aDecoder.current.decode( data )
                            }
                        }
                    }
                    _reqId.current = requestAnimationFrame( startDecoding )
                }

                _reqId.current = requestAnimationFrame( startDecoding )

                _startSynthesizer()

                setConnected( true )
            })
            .catch( (err:Error) => {
                setErrorMessage( err.message )
                setConnected( false )
            })
        _moqt.current.addListener('data', ( data:MessageData ) => {
            if( data.type === 'videochunk' ) {
                //@ts-ignore
                if( _videoJitterBuffer.current ) _videoJitterBuffer.current.addItem( data )
            } else if ( data.type === 'audiochunk') {
                //@ts-ignore
                if( _audioJitterBuffer.current ) _audioJitterBuffer.current.addItem( data )
            } else if ( data.type === "data" ) {
                const ts = new Date().toLocaleString()

                if( data.metadata?.kind && data.metadata?.timestamp && data.payload ) {
                    if( data.metadata.kind === 'midi-message') {
                        if( _midiSyncManager.current ) {
                            // decode midi message
                            const midiData: Array<number> = [ 0, 0, 0 ]
                            const view = new DataView(data.payload.buffer)

                            for( let i = 0; i < midiData.length; i++ ) {
                                midiData[i] = view.getUint8(i)
                            }

                            _midiSyncManager.current.setData( { 
                                timestamp: data.metadata.timestamp, 
                                payload: midiData
                            } )
                            console.log( 'midiData:%o', midiData )
                            setRecvDatas(datas => (
                                [`${ts} - ${midiData}`, ...datas.slice( 0, 4 )]
                            ))
                        }
                    } else if ( data.metadata.kind === 'face-landmarks' ) {
                        if( _faceLandmarksSyncManager.current ) {
                            // decode landmarks
                            const _landmarks: Array<Array<{ x: number, y: number, z: number, visibility: number }>> = [[]]
                            {
                                const view = new DataView(data.payload.buffer)
                                let offset = 0
                                while (offset < view.byteLength) {
                                    const x = view.getUint16(offset, true) / 65535
                                    const y = view.getUint16(offset + 2, true) / 65535
                                    const z = view.getInt8(offset + 4) / 128
                                    const visibility = view.getInt8(offset + 5)
                                    _landmarks[0].push({ x, y, z, visibility })
                                    offset += 6
                                }
                            }


                            _faceLandmarksSyncManager.current.setData( { 
                                timestamp: data.metadata.timestamp, 
                                payload: _landmarks
                            } )
                        }
                    } else if ( data.metadata.kind === 'pose-landmarks' ) {
                        if( _poseLandmarksSyncManager.current ) {
                            // decode landmarks
                            const _landmarks: Array<Array<{ x: number, y: number, z: number, visibility: number }>> = [[]]
                            {
                                const view = new DataView(data.payload.buffer)
                                let offset = 0
                                while (offset < view.byteLength) {
                                    const x = view.getUint16(offset, true) / 65535
                                    const y = view.getUint16(offset + 2, true) / 65535
                                    const z = view.getInt8(offset + 4) / 128
                                    const visibility = view.getInt8(offset + 5)
                                    _landmarks[0].push({ x, y, z, visibility })
                                    offset += 6
                                }
                            }


                            _poseLandmarksSyncManager.current.setData( { 
                                timestamp: data.metadata.timestamp, 
                                payload: _landmarks
                            } )
                        }
                    } else {
                        console.log( data )
                        setRecvDatas(datas => (
                            [`${ts} - ${data.payload}`, ...datas.slice( 0, 4 )]
                        ))
                    }
                }
            }
        })

        _moqt.current.addListener('latencyMs', ( mesg:MessageData ) => {
            /* noop */
        })

        _moqt.current.addListener('error', ( mesg:string ) => {
            setErrorMessage( mesg )
        })

        _moqt.current.addListener('closed', () => {
            setConnected( false )
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        })
    }, [ endpoint, wsReceiver, namespace, videoTrackName, audioTrackName, _setupRefs, _startSynthesizer ])

    const _disconnect = useCallback( async () => {
        if( _reqId.current ) {
            cancelAnimationFrame( _reqId.current )
            _reqId.current = undefined
        }
        if( _moqt.current ) {
            await _moqt.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }
        _stopSynthesizer()

        setConnected( false )
    }, [ _stopSynthesizer ])

    return (
        <div className="VideoReceiver">
            { xrmode && (
                <div>
                    <div style={{padding: 0, margin: 0, top: 0, left: 0, width: '100vw', height: '100vh', position: 'absolute'}}>
                        <VrmRenderer ref={_vrmRenderer} xrmode={true}/>
                    </div>
                    { !_connected && (
                        <div style={{
                            position:'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            background: 'rgba(0,0,0,0.5)',
                            top: 0, left: 0,
                            width: '100vw', height: '100vh'
                        }}>
                            <button onClick={() => {
                                if( _connected ) {
                                    _disconnect()
                                } else {
                                    _connect( moqTracks )
                                }
                            }}>{_connected ? 'disconnect' : 'connect' }</button>
                        </div>
                    )}
                </div>
            )}
            <div style={{visibility: xrmode ? 'hidden' : 'visible'}}>
                <h3>Video Receiver</h3>
                <div>
                    state: {_connected ? 'connected' : 'disconnected'}
                </div>
                <div>
                    video track: {videoTrackName}, audio track: {audioTrackName}<br/>
                    { _minJitterBufferMs >= 0 && (
                        <div>
                            minJitterBufferMs: 
                            <input 
                                type="number" 
                                min={0}
                                step={15}
                                max={1250}
                                value={_minJitterBufferMs} 
                                onChange={ (e) => {
                                    //@ts-ignore
                                    const val = Number(e.target.value)
                                    const num = val === 0 ? 1 : val
                                    setMinJitterBufferMs( num )
                                }} 
                            />
                        </div>
                    )}
                    <button onClick={() => {
                        if( _connected ) {
                            _disconnect()
                        } else {
                            _connect( moqTracks )
                        }
                    }}>{_connected ? 'disconnect' : 'connect' }</button>
                </div>
                { _connected && (
                <div>
                    <hr />
                        glass to glass delay(ms) : {_g2gDelay}<br/>
                        audio glass to glass delay(ms) : {_audioG2gDelay }<br/>
                        <label>
                            Draw face landmarks from sender : 
                            <input 
                                type="checkbox" 
                                checked={_drawFaceLandmarks} 
                                onChange={(e) => setDrawFaceLandmarks( e.target.checked )} 
                            />
                        </label>
                        <br />
                        <label>
                            Draw avatar : 
                            <input 
                                type="checkbox" 
                                checked={_drawAvatar} 
                                onChange={(e) => setDrawAvatar( e.target.checked )} 
                            />
                        </label>
                        <br />
                        <label>
                            Draw MIDI : 
                            <input 
                                type="checkbox" 
                                checked={_drawMidi} 
                                onChange={(e) => setDrawMidi( e.target.checked )} 
                            />
                        </label>
                        <br />
                        <div>
                            <fieldset>
                                <strong>Sync mode:</strong>
                                <input type="radio" name="syncMode" value="video" checked={_syncModeValue === 'video'} onChange={(e) => {
                                    setSyncModeValue( e.target.value )
                                    _syncMode.current = e.target.value
                                }} />
                                <label htmlFor="video">video</label>
                                <input type="radio" name="syncMode" value="audio" checked={_syncModeValue === 'audio'} onChange={(e) => {
                                    setSyncModeValue( e.target.value )
                                    _syncMode.current = e.target.value
                                }} />
                                <label htmlFor="audio">audio</label>
                            </fieldset>
                        </div>
                        <label>


                        </label>
                        <div style={{width: `${Math.floor(_g2gDelay / 4)}px`, height: '12px', background: '#f00'}}>
                        </div>
                    <hr />
                    <div className='video-wrapper'>
                        { !xrmode && (
                        <div>
                            <div className='wrappered-element'>
                                <VideoRenderer ref={_renderer} />
                            </div>
                        </div>
                        )}
                        <div className='wrappered-element'>
                            <FaceLandmarksDetector ref={_faceLandmarksDetector} hidden={!_drawFaceLandmarks} />
                        </div>
                        <div className='wrappered-element' style={{visibility: _drawMidi ? 'visible': 'hidden'}}>
                            <MidiEffector ref={_midiEffector} />
                        </div>
                        { !xrmode && (
                        <div className='wrappered-element' style={{visibility: _drawAvatar ? 'visible': 'hidden'}}>
                            <VrmRenderer ref={_vrmRenderer}/>
                        </div>
                        )}
                    </div>
                    <hr/>
                    <div>
                        <div className='recv-messages'>
                        <h4>Received messages</h4>
                            <ul>
                            { _recvDatas.map( ( mesg, idx ) => (
                                <li key={idx}>{mesg}</li>
                            ))}
                            </ul>
                        </div>
                    </div>
                </div>
                )}
                <div>
                    {!!_errMessage ? `Error::${_errMessage}` : '' }
                </div>
            </div>
        </div>
    )
}