import { useState, useEffect } from 'react';

import SyncVideoReceiver from '../components/sync-video-receiver';
import { endpoint } from '../config';

import '../App.css';

export default function Receiver() {
  const [ _namespace, setNamespace ] = useState<string>('')

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const ns = search.get('ns')
    if (ns) {
      setNamespace(ns)
    }
  }, [])



  return (
    <div className="App">
      <main>
        <div className='container'>
          <h2>video stream w/ Sync</h2>
          <div className='vertical-wrapper'>
            <div>
                <p>Namespace <input type="text" value={_namespace} onChange={( e ) => setNamespace( e.target.value )} /></p>
            </div>
            <div className='component'>
              <SyncVideoReceiver endpoint={endpoint} namespace={_namespace} videoTrackName="video" audioTrackName="audio" />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
