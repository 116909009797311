import EventEmitter from "events"

type Props = {
    sampleRate?: number,    // default 48000
    sinkId?: string
}

export default class AudioPlayer extends EventEmitter {
    _ctx:AudioContext|null
    _counter: number
    _chunkPlayer: AudioWorkletNode|null

    constructor( props:Props ) {
        super()

        const sampleRate = props.sampleRate || 48000
        const sinkId = props.sinkId || ''

        const options = { sampleRate, sinkId }

        this._counter = 0

        this._ctx = new AudioContext( options )
        this._chunkPlayer = null
    }

    start() {
        if (!this._ctx) return

        console.log('attempt to add module: chunk-player.js')
        this._ctx.audioWorklet.addModule(new URL('./chunk-player.js', import.meta.url))
            .then(() => {
                if (!this._ctx) return
                this._chunkPlayer = new AudioWorkletNode(this._ctx, 'chunk-player')
                this._chunkPlayer.connect(this._ctx.destination)
            })
            .catch(err => {
                console.error(err)
            })
    }

    /**
     * add
     * 
     * @param idx {number} - channel index
     * @param f32Array {Float32Array} - audio data in Float32Array
     */
    add( idx:number, f32Array:Float32Array ) {
        if( this._chunkPlayer ) {
            //this._chunkPlayer.port.postMessage( f32Array, [f32Array.buffer] )
            this._chunkPlayer.port.postMessage({ idx, f32Array } )
        }
    }

    stop() {
        if( !this._ctx ) return

        this._ctx.close()
    }
}