import { useCallback, useMemo, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Row } from 'antd'
import Moqt from '../libs/moqt'
import DanteSrc from '../libs/stream-apis/dante-src'
import MoqtDataSink from '../libs/stream-apis/moqt-data-sink'
import MoqtMediaSink from '../libs/stream-apis/moqt-media-sink'
import VideoViewer from './video-viewer'
import VideoCaptureSrc from '../libs/stream-apis/video-capture-src'
import VideoEncoderElem from '../libs/stream-apis/video-encoder-elem'
import MidiSrc from '../libs/stream-apis/midi-src'
import AudioCaptureSrc from '../libs/stream-apis/audio-capture-src'
import LogSink from '../libs/stream-apis/log-sink'

import { TimeBufferChecker } from '../libs/utils/time_buffer_checker'

import { MoqtTracks } from '../types/moqt'

const moqTracksTmpl: MoqtTracks = {
    data: {
        id: 0,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        mediaType: "data",
        name: "dante",
        packagerType: 'raw',
        maxInFlightRequests: 200,
        isHipri: true,
        authInfo: "secret"
    },
    video: {
        id: 1,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        name: "video",
        mediaType: "video",
        packagerType: 'loc',
        maxInFlightRequests: 200,
        isHipri: false,
        authInfo: "secret"
    },
    rawaudio: {
        id: 2,
        subscribeId: -1,
        trackAlias: -1,
        namespace: "",
        name: "audio",
        mediaType: "data",
        packagerType: 'raw',
        maxInFlightRequests: 200,
        isHipri: true,
        authInfo: "secret"
    },
}

const videoEncoderConfig = {
    encoderConfig: {
        // codec: 'avc1.42001e', // Baseline = 66, level 30 (see: https://en.wikipedia.org/wiki/Advanced_Video_Coding)
        //codec: 'avc1.640028',  // High profile, level 40
        codec: 'av01.0.12M.08',  // Main Profile, level 5.0, 8bits
                                 // see https://cconcolato.github.io/media-mime-support/
                                 //  https://en.wikipedia.org/wiki/AV1
        width: 1920,
        height: 1080,
        framerate: 30,
        bitrateMode: 'constant', // 'constant', 'variable' or 'quantizer'
        //bitrateMode: 'quantizer', // 'constant', 'variable' or 'quantizer'
                                  // see https://zenn.dev/tetter/articles/webcodecs-qp-encoding#webcodecs-%E3%81%AE%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%81%94%E3%81%A8%E3%81%AE-qp-%E8%A8%AD%E5%AE%9A%E6%A9%9F%E8%83%BD
        //bitrate: 5000000, // 5Mbps
        latencyMode: 'realtime', // Sends 1 chunk per frame
    },
    encoderMaxQueueSize: 64,
    keyframeEvery: 30, // every 1 sec in 30fps video
};


interface Props {
    endpoint:string,
    namespace:string,
    wsUrl:string,
    bitrate?:number,
    onData?:Function
}

export default function DanteSender(props:Props) {
    const { endpoint, namespace, bitrate, wsUrl, onData } = props

    const [ _connected, setConnected ] = useState<boolean>( false )
    const [ _moqTracks, setMoqTracks ] = useState<MoqtTracks>( moqTracksTmpl )
    const [ _errMessage, setErrorMessage ] = useState<string>('')

    const [ _videoMetrics, setVideoMetrics ] = useState<{ bps:number, frameRate: number}>({
        bps: 0, frameRate: 0
    })
    const [ _audioMetrics, setAudioMetrics ] = useState<{ bps:number, sampleRate: number}>({
        bps: 0, sampleRate: 0
    })
    const [ _danteMetrics, setDanteMetrics ] = useState<{ bps:number, sampleRate: number}>({
        bps: 0, sampleRate: 0
    })

    const _moqt = useRef<Moqt>()
    const _video = useRef<HTMLVideoElement|null>(null)

    const _bitrate = useMemo( () => {
        return bitrate || 5_000_000
    }, [ bitrate ])

    useEffect(() => {
        setMoqTracks(
            Object.entries(moqTracksTmpl).map( ( [ name, moqTrack ] ) => (
                [ name, { ...moqTrack, namespace}]
            )).reduce( (acc, [ name, moqTrack ]) => (
                //@ts-ignore
                { ...acc, [name]: moqTrack }
            ), {})
        )

        return function() {
            if( _moqt.current ) {
                _moqt.current.disconnect()
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }
    }, [ namespace ])

    const _onVideoStarted = useCallback( ( video:HTMLVideoElement ) => {
        _video.current = video
        console.log('video started')
    }, [])

    //////////////////////////////////////////
    // start media pipeline for Dante
    //////////////////////////////////////////
    const _startDantePipeline = useCallback( async () => {
        const danteSrc = new DanteSrc({
            wsUrl: wsUrl
        })
        const moqtDataSink = new MoqtDataSink({
            type: 'data',
            kind: 'dante',
            moqTrack: _moqTracks.data,
            moqt: _moqt.current,
            onData: onData,
            onMetrics: setDanteMetrics
        })
        danteSrc.pipeTo(moqtDataSink)
    }, [ wsUrl, _moqTracks.data, onData ])

    //////////////////////////////////////////
    // start video pipeline
    //////////////////////////////////////////
    const _startVideoPipeline = useCallback( async () => {
        if( !_video.current ) {
            alert('video is not started')
        } else {
            const vTimeBufferChecker = new TimeBufferChecker("video")
            const config = { ...videoEncoderConfig, encoderConfig: { 
                ...videoEncoderConfig.encoderConfig, 
                width: _video.current.videoWidth, 
                height: _video.current.videoHeight,
                bitrate: _bitrate
            } }

            // @ts-ignore
            const result = await VideoEncoder.isConfigSupported( config.encoderConfig )

            if( result.supported ) {
                const videoCaptureSrc = new VideoCaptureSrc({ video: _video.current })
                const videoEncoderElem = new VideoEncoderElem({ config, timeBufferChecker: vTimeBufferChecker })
                const logSink = new LogSink({ name: 'startVideoPipeline'})

                const moqtSink = new MoqtMediaSink({
                    type: 'video',
                    moqTrack: _moqTracks.video,
                    moqt: _moqt.current,
                    timeBufferChecker: vTimeBufferChecker,
                    onMetrics: setVideoMetrics
                })
                const tee = videoCaptureSrc.pipeThrough( videoEncoderElem ).tee()
                
                tee[0].pipeTo( moqtSink )
                if( false ) { tee[1].pipeTo( logSink ) }
            }
        }
    }, [ _moqTracks.video, _bitrate ])

    /////////////////////////////////////////////////////////////////////
    // start MIDI pipeline
    /////////////////////////////////////////////////////////////////////
    const _startMidiPipeline = useCallback( async () => {
        const midiSrc = new MidiSrc()
        const logSink = new LogSink({ name: 'sender-midi'})

        midiSrc.pipeTo( logSink )
    }, [])


    /////////////////////////////////////////////////////////////////////
    // start Audio Capture pipeline
    /////////////////////////////////////////////////////////////////////
    const _startAudioCapturePipeline = useCallback( async () => {
        if( !_video.current || !_video.current.srcObject ) return;

        //@ts-ignore
        const audioCaptureSrc = new AudioCaptureSrc({ stream: _video.current.srcObject, isRawAudio: true })    
        const moqtDataSink = new MoqtDataSink({
            type: 'data',
            kind: 'audio',
            moqTrack: _moqTracks.rawaudio,
            moqt: _moqt.current,
            onData: () => {},
            onMetrics: setAudioMetrics
        })
 

        const logSink = new LogSink({ name: 'sender-audio'})

        const tee = audioCaptureSrc.tee()
        tee[0].pipeTo( moqtDataSink)
        tee[1].pipeTo( logSink)
    }, [ _moqTracks.rawaudio ])

    /**
     * MOQT に接続する
     */
    const _connect = useCallback( () => {
        if( !_video.current ) {
            alert('video is not started')
            return
        }
        if(_moqt.current) return

        setErrorMessage('')

        console.log( 'moqTracks:%o', _moqTracks )

        _moqt.current = new Moqt()
        _moqt.current.connect({ endpoint })
            .then( async mesg => {
                // to avoid LINT error.
                if( !_moqt.current ) return

                console.log('[dante-moqt] connected to %s: %o', endpoint, mesg)

                const ret = await _moqt.current.createPublisher( _moqTracks )
                console.log( 'createPublisher response:%o', ret )

                setConnected( true )
                _startVideoPipeline()
                _startDantePipeline()
                if( false ) _startAudioCapturePipeline()
                if( false ) _startMidiPipeline()
            })
            .catch( (err:Error) => {
                console.error( err )
                setErrorMessage( err.message )
                setConnected( false )
            })

        _moqt.current.addListener('error', (mesg:string) => {
            setErrorMessage( mesg )
        })

        _moqt.current.addListener('closed', () => {
            setConnected( false )
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        })
    }, [ endpoint, _moqTracks, _startMidiPipeline, _startDantePipeline, _startVideoPipeline, _startAudioCapturePipeline ])




    const _disconnect = useCallback( async () => {
        if( _moqt.current ) {
            await _moqt.current.disconnect()
                .catch( err => setErrorMessage( err.message ))
            if( _moqt.current ) {
                _moqt.current.destroy()
                _moqt.current = undefined
            }
        }
        setConnected( false )
    }, [])

    return (
        <div className="DanteSender">
            <div>
                NODE_ENV: {process.env.NODE_ENV}, 
                state: {_connected ? 'connected' : 'disconnected'}
            </div>
            <Row gutter={16}>
                <Col span={12}>
                    <div>
                        <h3>Sender</h3>
                        <VideoViewer 
                            idealWidth={videoEncoderConfig.encoderConfig.width} 
                            idealHeight={videoEncoderConfig.encoderConfig.height} 
                            disableAudioInput={true}
                            onStarted={_onVideoStarted} 
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <h3>Dante sender</h3>
                        <Button 
                            type='primary'
                            onClick={ !_connected ? _connect : _disconnect }
                            danger={ _connected}
                        >
                            { !_connected ? 'connect' : 'disconnect' }
                        </Button>
                        { _connected && (
                        <Card title="Metrics" style={{width: "100%"}}>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <h5>Video</h5>
                                    <div>traffic: {Math.floor(_videoMetrics.bps / 10_000) / 100} Mbps </div>
                                    <div>frame rate: {_videoMetrics.frameRate} fps</div>
                                </Col>
                                <Col span={8}>
                                    <h5>Audio</h5>
                                    <div>traffic: {Math.floor(_audioMetrics.bps / 10_000) / 100} Mbps </div>
                                    <div>chunk rate: {_audioMetrics.sampleRate} cps</div>
                                </Col>
                                <Col span={8}>
                                    <h5>Dante</h5>
                                    <div>traffic: {Math.floor(_danteMetrics.bps / 10_000) / 100} Mbps </div>
                                    <div>sample rate: {_danteMetrics.sampleRate}</div>
                                </Col>
                            </Row>
                        </Card>
                        )}
                        <Card title="Log" style={{width: "100%"}}>
                            <div>
                                {!!_errMessage ? `Error::${_errMessage}` : ''}
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    )
}