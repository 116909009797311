import { useRef, useImperativeHandle, useEffect, forwardRef  } from 'react'
import { paletones } from '../libs/tone'
import './midi-effector.css'

class Effect {
    note: number
    x: number
    y: number
    startMs: number
    r: number = 250
    finished: boolean = false
    ctx: CanvasRenderingContext2D | null = null
    duration: number = 1_000
    color: string
    lineWidth: number = 2

    constructor(ctx: CanvasRenderingContext2D, note: number, x: number, y: number, delay: number) {
        this.note = note
        this.x = x
        this.y = y  
        this.startMs = Date.now() + ( delay * 10 )
        this.color = paletones[ Math.min( Math.floor( Math.random() * paletones.length ), paletones.length - 1 ) ]
        this.lineWidth = Math.floor( Math.random() * 5 ) + 1
        this.ctx = ctx
    }

    draw() {
        const delta = Date.now() - this.startMs
        if( delta > this.duration ) {
            this.finished = true
            return
        }
        const r = Math.floor( this.r * (1 - delta / this.duration) )

        if( this.ctx ) {
            this.ctx.beginPath()
            this.ctx.strokeStyle = `${this.color}`
            this.ctx.lineWidth = this.lineWidth
            this.ctx.arc(this.x, this.y, r, 0, Math.PI * 2, true)
            this.ctx.stroke()
        }
    }
}

const MidiEffector = forwardRef(function(props, ref) {
    const _canvasEl = useRef<HTMLCanvasElement>(null)
    const _ctx = useRef<CanvasRenderingContext2D | null>(null)
    const _wrapperEl = useRef<HTMLDivElement>(null)
    const _loaded = useRef<boolean>(false)
    const _reqId = useRef<number>(0)

    const _effects = useRef<Array<Effect>>([])

    useEffect(() => {
        if (_loaded.current) return
        _loaded.current = true

        if( _wrapperEl.current ) {
            const canvas = document.createElement('canvas')
            //@ts-ignore
            _canvasEl.current = canvas
            canvas.width = _wrapperEl.current.clientWidth
            canvas.height = _wrapperEl.current.clientHeight
            _wrapperEl.current.appendChild(canvas)

            _ctx.current = canvas.getContext('2d')
            const render = () => {
                if (_ctx.current) {
                    _ctx.current.clearRect(0, 0, canvas.width, canvas.height)
                    _ctx.current.fillStyle = 'rgba(0, 0, 0, 0.25)'
                    _ctx.current.fillRect(0, 0, canvas.width, canvas.height)

                    for( const effect of _effects.current ) {
                        effect.draw()
                    }

                    _effects.current = _effects.current.filter(effect => !effect.finished)
                }
                _reqId.current = requestAnimationFrame(render)
            }
            _reqId.current = requestAnimationFrame(render)
        }
    }, [])

    useImperativeHandle(ref, () => {
        return {
            addEffect: (note:number, delay: number) => {
                if( _canvasEl.current && _ctx.current ) {
                   //console.log('updateEffect:%d, %d, %d, delay:%d', note, w, h, delay)
                    const n = note % 12;
                    const x = Math.floor(_canvasEl.current.width * (n / 12))
                    const y = Math.floor(_canvasEl.current.height * 0.3) + ( note % 3 * 50 )

                    const effect = new Effect(_ctx.current, note, x, y, delay)
                    _effects.current.push(effect)
                }
            }
        }
    }, [])
    return (
        <div className="MidiEffector" ref={_wrapperEl}>
        </div>
    )
})

export default MidiEffector;