import { TrackData } from "../../types/moqt"
import { TimeBufferChecker } from "../utils/time_buffer_checker"

type Props = {
    type:string,
    moqTrack:TrackData,
    //@ts-ignore
    moqt:Moqt,
    onData?:Function|undefined
    onMetrics?:Function|undefined
    timeBufferChecker:TimeBufferChecker
}

export default class MoqtMediaSink extends WritableStream {
    count:number
    moqTrack:TrackData
    type:string
    //@ts-ignore
    moqt:Moqt
    onData:Function|undefined
    onMetrics:Function|undefined

    timeBufferChecker:TimeBufferChecker

    frameCounter: number
    lastFrameCount: number
    byteCounter: number
    lastByteCount: number

    constructor( props:Props ) {
        super({
            write: ( {seqId, metadata, chunk}:{ seqId:number, metadata:any, chunk:object} ) => {
                //@ts-ignore
                const itemTsClk = this.timeBufferChecker.GetItemByTs( chunk.timestamp )

                const type = this.type
                const mediaType = this.moqTrack.mediaType

                const data = {
                    type,
                    mediaType,
                    firstFrameClkms: itemTsClk?.ts,
                    compensatedTs: itemTsClk?.compensatedTs,
                    estimatedDuration: itemTsClk?.estimatedDuration,
                    seqId,
                    metadata,
                    chunk
                }

                this.moqt.send( data )

                this.frameCounter++
                //@ts-ignore
                if( chunk && chunk.byteLength ) {
                    //@ts-ignore
                    this.byteCounter += chunk.byteLength
                }

                // callback のインターバルが短すぎると落ちてしまうため、4回に1回のみコールバックする
                if (typeof this.onData === 'function' && seqId % 4 === 0) {
                    //@ts-ignore
                    this.onData({ seqId, timestamp: chunk.timestamp, data: chunk })
                }
            } 
        })

        this.count = 0
        this.moqTrack = props.moqTrack
        this.type = props.type
        this.moqt = props.moqt

        this.onData = props.onData
        this.onMetrics = props.onMetrics

        this.timeBufferChecker = props.timeBufferChecker

        this.frameCounter = 0
        this.lastFrameCount = 0
        this.byteCounter = 0
        this.lastByteCount = 0

        setInterval(() => {
            const frameRate = this.frameCounter - this.lastFrameCount
            const bps = ( this.byteCounter - this.lastByteCount ) * 8

            if( this.onMetrics && typeof this.onMetrics === 'function') {
                this.onMetrics({ bps, frameRate })
            }

            this.lastFrameCount = this.frameCounter
            this.lastByteCount = this.byteCounter
        }, 1_000)
    }
}