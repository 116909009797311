import AudioCapture from '../audio-capture'

type Props = {
    stream: MediaStream,
    isRawAudio?: boolean
}

export default class AudioCaptureSrc extends ReadableStream<object> {
    _audioCapture: AudioCapture
    _stream: MediaStream
    _isRawAudio: boolean

    constructor( props: Props) {
        super({
            start: (controller) => {
                setTimeout( () => {
                    this._audioCapture.on('aFrame', ( data:object ) => {
                        controller.enqueue(data)
                    })
                    this._audioCapture.start( props.stream )
                }, 1)
            }
        })

        this._stream = props.stream
        this._isRawAudio = props.isRawAudio || false
        this._audioCapture = new AudioCapture({ sampleRate: 48000, isRawAudio: this._isRawAudio })
    }
}