//import { useState } from 'react';

import { Alert } from 'antd';

//import SyncVideoSender from './components/sync-video-sender';
//import SyncVideoReceiver from './components/sync-video-receiver';
//
//import { endpoint } from './config';

import './App.css';


function App() {
  //const [ _syncVideoTrackName, setSyncVideoTrackName ] = useState<string>('video')
  //const [ _syncAudioTrackName, setSyncAudioTrackName ] = useState<string>('audio')
  //const [ _namespace, setNamespace ] = useState<string>('')
  //const moqtEp = endpoint


  return (
    <div className="App">
      <main>
        <h1>MOQT survey</h1>

        <Alert message={<span>This is page does not work right now. use <a href="/dante">/dante</a></span>} type="error" showIcon />

        {/*
        <div className='container'>
          <h2>video stream w/ Sync</h2>
          <div className='wrapper'>
            <div className='component'>
              <SyncVideoSender 
                endpoint={moqtEp} 
                namespace={_namespace} setNamespace={setNamespace}
                videoTrackName={_syncVideoTrackName} setVideoTrackName={setSyncVideoTrackName} 
                audioTrackName={_syncAudioTrackName} setAudioTrackName={setSyncAudioTrackName} 
              />
            </div>
            <div className='component'>
              <SyncVideoReceiver 
                endpoint={moqtEp} 
                namespace={_namespace} 
                videoTrackName={_syncVideoTrackName} 
                audioTrackName={_syncAudioTrackName} 
              />
            </div>
          </div>
        </div>
        */}
      </main>
    </div>
  );
}

export default App;
