export default class LogSink extends WritableStream {
    _name:string
    count:number

    constructor(props:{name:string}) {
        super({
            write: ( chunk ) => {
                if( this.count++ < 10 ) {
                    console.log( '[%s]LogSink:%o', this._name, chunk )
                }
            }
        })
        this._name = props.name || 'noname'
        this.count = 0
    }
}